import { Role, type RoleString } from '@/types/auth'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSuperAdminStore = defineStore(
  'superadmin',
  () => {
    const syntheticRole = ref<Role | undefined>()
    const syntheticOrganizationId = ref<string | undefined>()
    const setSyntheticRole = (newRole: RoleString) => (syntheticRole.value = Role[newRole])
    const setSyntheticOrganizationId = (newOrganizationId: string) =>
      (syntheticOrganizationId.value = newOrganizationId)

    return {
      syntheticRole,
      syntheticOrganizationId,
      setSyntheticRole,
      setSyntheticOrganizationId
    }
  },
  { persist: true }
)
