<script lang="ts">
import { useAuthStore } from '@/stores/auth'
export default {
  beforeRouteEnter() {
    const authStore = useAuthStore()
    if (authStore.isAtLeastInstitutionAdminUser) {
      return { name: 'Modern Cohorts - Cohort Administration' }
    } else if (authStore.isAtLeastEducatorUser) {
      return { name: 'Modern Cohorts - My Cohorts List' }
    }
  }
}
</script>

<script setup lang="ts">
definePage({
  name: 'Modern Cohorts',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})
</script>
