<script setup lang="ts">
definePage({
  name: 'Management Feature Flags',
  meta: {
    permissionLevel: 'SuperAdmin'
  }
})
</script>

<template>
  <div class="flex flex-col gap-8">
    <div>
      <h2 class="mb-3 text-xl">Feature Flags</h2>
      <p>
        Disable or enable feature flags using the PostHog toolbar:
        <a href="https://eu.posthog.com/project/12445/toolbar">open the PostHog dashboard</a> and
        click "Launch" on the appropriate url to launch a new window with the PostHog toolbar.
      </p>
    </div>
  </div>
</template>
