<script setup lang="ts">
import { ref } from 'vue'
import { SCHEDULED_DOWNTIME } from '@/constants'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useHandledInterval from '@/composables/useHandledInterval'

dayjs.extend(relativeTime)

const router = useRouter()

const downtime = ref<string | null>(null)

const downtimeSoon = dayjs(SCHEDULED_DOWNTIME).subtract(4, 'hour')
const downtimeNow = dayjs(SCHEDULED_DOWNTIME)
const downtimeOver = dayjs(SCHEDULED_DOWNTIME).add(10, 'minute')

useHandledInterval().set(() => {
  const now = dayjs()
  if (now.isBefore(downtimeSoon)) {
    downtime.value = null
  } else if (now.isBefore(downtimeNow)) {
    downtime.value = downtimeNow.fromNow()
  } else if (now.isBefore(downtimeOver)) {
    router.push('/downtime.html')
  }
}, 1000)
</script>

<template>
  <RouterLink
    v-if="downtime"
    to="/downtime.html"
    class="absolute right-4 top-2 text-sm font-bold text-sc-orange-400 hover:underline"
  >
    <p title="SimConverse will be down for maintenance for approximately 10 minutes.">
      Maintenance {{ downtime }}
    </p>
  </RouterLink>
</template>
