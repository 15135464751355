<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import type { OrganizationAssignment } from '@/open-api/generated'
import useGetOrSearchOrganisationAssignments from '@/composables/api/queries/useGetOrSearchOrganisationAssignments'
import useAssignToCohort from '@/composables/api/mutations/useAssignToCohort'
import type { Table } from '@tanstack/vue-table'
import {
  DataTable,
  DataTableServerPagination,
  getFilterValue
} from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/cohort-assigns'

definePage({
  name: 'Modern Cohorts - Assign Activity',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Assign Activity to Cohort')

const route = useRoute('Modern Cohorts - Assign Activity')
const cohortId = computed(() => route.params.cohortId)

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const assignToCohort = useAssignToCohort({ notificationStore })

const table = useTemplateRef<Table<OrganizationAssignment>>('table')
const query = computed(() => getFilterValue(table.value))
const { data, isLoading, pagination, showServerPagination } = useGetOrSearchOrganisationAssignments(
  { query, authStore, notificationStore }
)
const cohortAssigns = useColumns({ cohortId, assignToCohort })
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Activities List', params: { cohortId } }"
        name="cohort"
      />
    </template>
  </TopLine>
  <DataTable ref="table" :data="data" :columns="cohortAssigns" :loading="isLoading" />
  <DataTableServerPagination v-show="showServerPagination" v-bind="pagination" />
</template>
