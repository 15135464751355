<script setup lang="ts">
import { useAssignmentStore } from '@/stores/assignment'
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  EllipsisHorizontalCircleIcon,
  XCircleIcon
} from '@heroicons/vue/24/outline'
import { computed, reactive, ref } from 'vue'
import { onBeforeMount } from 'vue'
import { type MyTask, type MyPastConversationTaskAttemptSummary } from '@/open-api/generated'
import type { MyConversationTaskMeta } from '@/types/api'
import Api from '@/open-api'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import dayjs from 'dayjs'
import CustomButton from '@/components/utils/CustomButton.vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import HeaderList, { type HeaderField } from '@/components/my-assignments/HeaderList.vue'
import { useRouter, useRoute } from 'vue-router'
import { INFINITE_ATTEMPTS } from '@/constants'

definePage({
  name: 'MyAssignments Task History',
  meta: {
    permissionLevel: 'Student'
  }
})

// ==================================================
// Init
// ==================================================
const router = useRouter()
const route = useRoute('MyAssignments Task History')
const assignmentStore = useAssignmentStore()
const notificationStore = useNotificationStore()

const formatDate = (val: string) => {
  return dayjs(val).format('D MMMM, YYYY')
}

// ==================================================
// task logic
// ==================================================

const currentTask = computed((): MyTask | undefined => {
  if (!assignmentStore?.currentAssignment?.tasks) {
    return undefined
  }
  return assignmentStore.currentAssignment?.tasks.find(
    (task) => task.task_id === route.params.taskId
  )
})

const taskHistory = reactive({
  loading: false,
  list: [] as MyPastConversationTaskAttemptSummary[]
})

onBeforeMount(() => {
  taskHistory.loading = true
  Api.MyAssignments.getMyPastConversationTaskAttemptsEndpoint(route.params.taskId as string)
    .then((res) => {
      taskHistory.list = res.conversation_task_attempts
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      taskHistory.loading = false
    })
})

const taskAction = (attempt: MyPastConversationTaskAttemptSummary) => {
  if (attempt.conversation_ended_at) {
    // Completed conversation
    router.push({
      name: 'History Transcript',
      params: { conversationId: attempt.conversation_id },
      query: { taskId: currentTask.value?.task_id }
    })
  } else {
    // Incomplete conversation task attempt
    router.push({
      name: 'MyAssignments Conversation',
      params: {
        taskAttemptId: attempt.conversation_task_attempt_id,
        conversationId: attempt.conversation_id
      }
    })
  }
}

const taskWithStyles = computed(() => {
  return taskHistory.list.map((task) => {
    let buttonColor = 'sc-grey'
    let icon: any = EllipsisHorizontalCircleIcon
    let buttonText = 'Grading'

    if (task.satisfactory) {
      buttonColor = 'green'
      icon = CheckCircleIcon
      buttonText = 'Passed'
    } else if (task.satisfactory === false) {
      buttonColor = 'red'
      icon = XCircleIcon
      buttonText = "Didn't Pass"
    } else if (!task.conversation_ended_at) {
      icon = undefined
      buttonText = 'Continue'
      buttonColor = ''
    }

    const taskStyles = {
      iconStyle: buttonColor ? `h-5 w-5 text-${buttonColor}-500` : 'h-5 w-5',
      buttonStyle: buttonColor
        ? `border bg-${buttonColor}-100 border-${buttonColor}-500 text-sc-grey-700`
        : '',
      icon,
      buttonText
    }

    return {
      ...task,
      ...taskStyles
    }
  })
})

// ==================================================
// Header details
// ==================================================

const headerFields = ref<HeaderField[]>([
  {
    title: 'DUE DATE',
    value: formatDate(assignmentStore.currentAssignment?.due_date || '')
  },
  {
    title: 'TASK TYPE',
    value: currentTask?.value?.task_type.split(/(?=[A-Z])/).join(' ') || ''
  },
  {
    title: 'ATTEMPTS',
    value:
      (currentTask?.value?.meta as MyConversationTaskMeta)?.ConversationTask?.max_attempts !==
      INFINITE_ATTEMPTS
        ? `${
            (currentTask?.value?.meta as MyConversationTaskMeta).ConversationTask.max_attempts -
            (currentTask?.value?.meta as MyConversationTaskMeta).ConversationTask.number_of_attempts
          }/${
            (currentTask?.value?.meta as MyConversationTaskMeta).ConversationTask.max_attempts
          } attempts remaining`
        : '∞ attempts remaining',
    mobileVisible: true
  }
])
</script>

<template>
  <div class="sticky -top-3 z-50 flex flex-col gap-5 bg-white pt-3">
    <div
      class="flex cursor-pointer items-center gap-2 text-sc-grey-700"
      @click="
        router.push({
          name: 'MyAssignments Task',
          params: { taskId: route.params.taskId, assignmentId: route.params.assignmentId }
        })
      "
    >
      <ChevronLeftIcon class="h-4 w-4" />
      <h4 class="font-normal text-sc-grey-700">Back to Task</h4>
    </div>

    <div class="flex flex-col">
      <small class="text-sc-grey-700">Task attempts</small>
      <h2 class="text-xl">{{ currentTask?.name }}</h2>
    </div>

    <HeaderList :fields="headerFields" />
  </div>

  <h3 class="my-4 text-lg font-medium">Task Attempts</h3>

  <template v-if="!taskHistory.loading">
    <div class="relative my-4 flex flex-col gap-3">
      <div
        v-for="attempt in taskWithStyles"
        :key="attempt.conversation_id"
        :class="[
          'flex cursor-pointer items-center justify-between gap-5 rounded-[10px] border border-sc-grey-300 p-3 hover:bg-sc-grey-100',
          { 'pointer-events-none': attempt.task_scene_id }
        ]"
        @click="taskAction(attempt)"
      >
        <div class="flex flex-col truncate">
          <p class="truncate font-medium">
            {{ attempt.task_name }}
          </p>
          <small class="text-sc-grey-600">{{
            formatDate(attempt.conversation_ended_at || attempt.created_at)
          }}</small>
        </div>

        <template v-if="!attempt.task_scene_id">
          <CustomButton
            class="task-status"
            :custom-style="attempt.buttonStyle"
            :icon-size="attempt.iconStyle"
            :start-icon="attempt.icon"
            button-size="md"
          >
            {{ attempt.buttonText }}
          </CustomButton>
        </template>
      </div>
    </div>
  </template>

  <AppLoadingSpinner v-else loading class="pt-20" />
</template>

<style scoped>
.task-status {
  /* width 141px is the width of the larges button */
  @apply w-[141px];
}
</style>
