<script setup lang="ts">
import AppNavigationSidebar from '@/components/modern/app-navigation-sidebar'
import NotificationList from '@/components/NotificationList.vue'
import AppPageHeading from '@/components/modern/AppPageHeading.vue'
import ScrollArea from '@/components/modern/ui/scroll-area/ScrollArea.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
</script>

<template>
  <NotificationList />
  <div class="flex h-full max-h-full w-full max-w-full bg-muted px-2 pb-2">
    <AppNavigationSidebar class="w-56 flex-none pr-2" />
    <div class="flex h-full min-w-0 flex-1 flex-col">
      <AppPageHeading class="h-fit flex-none" />
      <ScrollArea
        id="main-content-container"
        class="h-full rounded border bg-background contain-content"
      >
        <main class="flex h-full flex-1 flex-col pb-16" :class="{ 'p-2': !route.meta.isModern }">
          <router-view />
        </main>
      </ScrollArea>
    </div>
  </div>
</template>
