import type { Mimetype, MimetypeMapping } from '@/types/filetypes.d'

export const mimeExts: MimetypeMapping = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'audio/mpeg': ['.mp3']
}
export const SUPPORTED_IMAGE_MIMETYPES: Mimetype[] = ['image/png', 'image/jpeg']
export const SUPPORTED_AUDIO_MIMETYPES: Mimetype[] = ['audio/mpeg']
export const SUPPORTED_IMAGE_AND_AUDIO_MIMETYPES: Mimetype[] =
  SUPPORTED_IMAGE_MIMETYPES.concat(SUPPORTED_AUDIO_MIMETYPES)

// Defining .xls/.xlsx content types: https://stackoverflow.com/a/11834872
// Can add any formats supported by SheetJS: https://docs.sheetjs.com/docs/miscellany/formats
export const SUPPORTED_SPREADSHEET_FORMATS =
  '.xls, application/vnd.ms-excel, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, text/csv'
