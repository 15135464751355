<script setup lang="ts">
import { computed, type Ref } from 'vue'
import { ScrollArea } from '@/components/modern/ui/scroll-area'
import CollapsibleNav from './CollapsibleNav.vue'
import type { NavItemRoute } from './NavItem.vue'
import {
  Cog8ToothIcon,
  UsersIcon,
  LightBulbIcon,
  ClipboardDocumentListIcon,
  AcademicCapIcon,
  BriefcaseIcon,
  BookOpenIcon,
  PencilSquareIcon,
  BuildingOffice2Icon,
  SpeakerWaveIcon
} from '@heroicons/vue/24/outline'

import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useGetMyCohorts from '@/composables/api/queries/useGetMyCohorts'
import type { GetMyCohortsCohort } from '@/open-api/generated'
import InfoMenu from './InfoMenu.vue'
import NavItem from './NavItem.vue'
import OrganizationLabel from '@/components/modern/app-navigation-sidebar/OrganizationLabel.vue'

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

let cohorts: Ref<GetMyCohortsCohort[]>
if (authStore.isAtLeastEducatorUser) {
  cohorts = useGetMyCohorts({ notificationStore }).cohorts
}

const cohortRoutes = computed((): NavItemRoute[] => [
  ...(cohorts?.value
    ?.filter((c) => c.cohort_role === 'Educator')
    .map((cohort) => ({
      label: cohort.name,
      to: {
        name: 'Modern Cohorts - Cohort Activities List' as const,
        params: { cohortId: cohort.id }
      },
      icon: AcademicCapIcon
    })) || [])
])

const authoringRoutes = computed((): NavItemRoute[] => [
  ...(authStore.isSuperAdminUser
    ? [{ label: 'Voices', to: { name: 'Voices List Index' as const }, icon: SpeakerWaveIcon }]
    : []),
  { label: 'Characters', to: { name: 'Characters' }, icon: UsersIcon },
  { label: 'Rubrics', to: { name: 'Rubrics' }, icon: LightBulbIcon },
  { label: 'Sections', to: { name: 'Sections' }, icon: ClipboardDocumentListIcon },
  ...(authStore.flagModernEnabled || authStore.isSuperAdminUser
    ? [
        {
          label: 'Activities',
          to: { name: 'Modern Activities - All Activities List' as const },
          icon: PencilSquareIcon
        }
      ]
    : [])
])

const assignRoutes: NavItemRoute[] = [
  {
    label: 'Activities',
    to: { name: 'Modern Activities - All Activities List' as const },
    icon: PencilSquareIcon
  }
]

const administrationRoutes = computed((): NavItemRoute[] => [
  { label: 'Settings', to: { name: 'Management' }, icon: Cog8ToothIcon },
  ...(authStore.flagModernEnabled || authStore.isSuperAdminUser
    ? [
        {
          label: 'Cohorts',
          to: { name: 'Modern Cohorts - Cohort Administration' as const },
          icon: AcademicCapIcon
        }
      ]
    : [])
])

const myActivitiesRoute: NavItemRoute = {
  label: 'My Activities',
  to: { name: 'Modern - My Activities' },
  icon: BriefcaseIcon
}

const myCohortsRoute: NavItemRoute = {
  label: 'My Cohorts',
  to: { name: 'Modern Cohorts - My Cohorts List' },
  icon: AcademicCapIcon
}

const legacyRoutes = computed((): NavItemRoute[] => [
  ...(authStore.flagModernEnabled && !authStore.isSuperAdminUser
    ? []
    : [
        { label: 'My Assignments', to: { name: 'MyAssignments' as const }, icon: BriefcaseIcon },
        { label: 'My History', to: { name: 'History' as const }, icon: BookOpenIcon }
      ]),
  ...(authStore.isAtLeastEducatorUser
    ? [
        { label: 'Cohorts', to: { name: 'Cohorts' as const }, icon: AcademicCapIcon },
        {
          label: 'Organization History',
          to: { name: 'Organization History' as const },
          icon: BuildingOffice2Icon
        }
      ]
    : [])
])
</script>

<template>
  <aside class="flex h-full flex-col items-start justify-start gap-3 text-sm">
    <OrganizationLabel />
    <ScrollArea class="w-full flex-grow">
      <div class="flex h-full flex-col gap-2">
        <div class="flex flex-col gap-[1px]">
          <NavItem
            v-if="authStore.flagModernEnabled || authStore.isSuperAdminUser"
            :route="myActivitiesRoute"
          />
          <NavItem
            v-if="
              (authStore.flagModernEnabled && authStore.isAtLeastEducatorUser) ||
              authStore.isSuperAdminUser
            "
            :route="myCohortsRoute"
          />
        </div>
        <!-- We only want to show legacy routes to students if the org only has legacy and not modern -->
        <!-- We want to show to educators if legacy is still enabled -->
        <CollapsibleNav
          v-if="
            !authStore.flagModernEnabled ||
            (authStore.flagLegacyEnabled && authStore.isAtLeastEducatorUser) ||
            authStore.isSuperAdminUser
          "
          :deprecated="true"
          label="Legacy"
          :routes="legacyRoutes"
          :default-open="!authStore.flagModernEnabled"
        />
        <CollapsibleNav
          v-if="
            (authStore.flagAuthoringEnabled && authStore.isAtLeastEducatorUser) ||
            authStore.isSuperAdminUser
          "
          label="Author"
          :routes="authoringRoutes"
          :default-open="true"
        />
        <CollapsibleNav
          v-else-if="
            authStore.flagModernEnabled &&
            !authStore.flagAuthoringEnabled &&
            authStore.isAtLeastEducatorUser
          "
          label="Assign"
          :routes="assignRoutes"
          :default-open="true"
        />
        <CollapsibleNav
          v-if="authStore.isAtLeastInstitutionAdminUser"
          label="Administer"
          :routes="administrationRoutes"
          :default-open="true"
        />
        <CollapsibleNav
          v-if="
            ((authStore.flagModernEnabled && authStore.isAtLeastEducatorUser) ||
              authStore.isSuperAdminUser) &&
            cohortRoutes.length > 0
          "
          label="Educate"
          :routes="cohortRoutes"
          :default-open="true"
        />
      </div>
    </ScrollArea>

    <InfoMenu class="size-8" />
  </aside>
</template>
