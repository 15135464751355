<script setup lang="ts">
import type {
  OrganizationIdAndName,
  Rubric,
  RubricOutput,
  SyncRubricToRegionsInput
} from '@/open-api/generated'
import { ref, reactive, computed, onBeforeMount } from 'vue'
import { useAuthStore } from '@/stores/auth'
import CustomButton from '@/components/utils/CustomButton.vue'
import Api from '@/open-api'
import DraggableTemplate from '@/components/utils/DraggableTemplate.vue'
import { useContentStore } from '@/stores/content'
import { useNotificationStore } from '@/stores/notifications'
import CustomInput from '@/components/utils/CustomInput.vue'
import { NotificationStatus } from '@/types/notification'
import { PlusIcon } from '@heroicons/vue/24/outline'
import { deepCopy } from '@/lib/utils'

import { EMPTY_STRING_SEARCH_ALL, IS_PRODUCTION } from '@/constants'

definePage({
  name: 'Rubric Access Controls',
  meta: {
    permissionLevel: 'InstitutionAdmin',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const authStore = useAuthStore()
const notificationStore = useNotificationStore()
const contentStore = useContentStore()
const tableDraggingId = ref('')

// ==================================================
// Organizations
// ==================================================
const currentEditingRubric: Partial<RubricOutput> = reactive({})

onBeforeMount(() => {
  Object.assign(currentEditingRubric, deepCopy(contentStore.editingRubric))
})

const cantSave = computed(() => {
  const storeJSON = JSON.stringify(contentStore.editingRubric.allowlist)
  const currentJSON = JSON.stringify(currentEditingRubric.allowlist)

  return storeJSON === currentJSON
})

const saveLoading = ref(false)

const changeOrganization = ($event: any) => {
  if ($event.added && currentEditingRubric.allowlist) {
    currentEditingRubric.allowlist.push($event.added.element)
  }
}

const saveOrganizations = () => {
  saveLoading.value = true

  const allowListUpdate: string[] =
    currentEditingRubric.allowlist?.map((org) => org.organization_id) || []

  const update = { ...contentStore.editingRubric }

  update.allowlist = allowListUpdate
  ;(update as Rubric).owning_organization_id = currentEditingRubric.owning_organization_id

  Api.Content.updateRubricEndpoint(update as Rubric)
    .then((res) => {
      contentStore.setEditingRubric(res)
      Object.assign(currentEditingRubric, res)

      notificationStore.addNotification({
        subtitle: 'Rubric organizations access successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

const deleteOrganization = (index: number) => {
  if (!currentEditingRubric.allowlist) {
    return
  }
  currentEditingRubric.allowlist.splice(index, 1)
}

// ==================================================
// Organizations Library
// ==================================================
const organizationShown = ref(false)
const organizationLibraryLoading = ref(false)
let organizationsLibrary = ref<OrganizationIdAndName[]>([])

onBeforeMount(() => {
  setOrganizationsList()
})

const setOrganizationsList = async (search = EMPTY_STRING_SEARCH_ALL) => {
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }
  organizationLibraryLoading.value = true
  try {
    const orgs = await Api.Management.listAllOrganizationsEndpoint(search)
    organizationsLibrary.value = orgs.organizations
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    organizationLibraryLoading.value = false
  }
}

const shownLibraryOrganizations = computed(() => {
  const uniqueValues = new Set()
  const result: any[] = []

  if (!currentEditingRubric?.allowlist?.length) {
    return organizationsLibrary.value
  }

  const combinedArray = [...organizationsLibrary.value, ...currentEditingRubric.allowlist]

  combinedArray.forEach((item) => {
    if (!uniqueValues.has(item?.organization_id)) {
      uniqueValues.add(item.organization_id)
      result.push(item)
    } else {
      result.forEach((existingItem, index) => {
        if (existingItem?.organization_id === item.organization_id) {
          result.splice(index, 1)
        }
      })
    }
  })

  return result
})

// ==================================================
// Global content.
// ==================================================
const syncLoading = ref<boolean>(false)

const syncRubricToRegions = async () => {
  if (!currentEditingRubric.rubric_id) {
    return
  }

  const input: SyncRubricToRegionsInput = {
    rubric_id: currentEditingRubric.rubric_id
  }

  syncLoading.value = true
  try {
    await Api.Content.syncRubricToRegionsEndpoint(input)
    notificationStore.addSUCCESS('Rubric successfully synchronized with other regions.')
  } catch (err: any) {
    notificationStore.addDANGER(err?.body?.message)
  } finally {
    syncLoading.value = false
  }
}
</script>

<template>
  <div class="flex h-full flex-col gap-10">
    <div class="flex flex-col gap-5">
      <h2 class="text-xl">Access control</h2>

      <div v-if="authStore.isAtLeastStaffUser" class="flex flex-col gap-5">
        <CustomInput
          v-model="currentEditingRubric.owning_organization_id"
          class="!mb-0 !w-[300px]"
          input-type="select"
          placeholder="Select an organization"
          label="Owner Organization"
          :options="
            organizationsLibrary.map((org) => {
              return {
                name: org.name,
                value: org.organization_id
              }
            })
          "
        />
        <CustomButton
          button-type="admin-primary"
          :loading="saveLoading"
          :disabled="saveLoading"
          @click="saveOrganizations"
        >
          Save Changes
        </CustomButton>
      </div>

      <h3 class="text-lg">Organizations</h3>
      <p v-if="!currentEditingRubric?.allowlist?.length" class="text-sc-grey-600">
        You have no organizations added to this section
      </p>
      <DraggableTemplate
        class="w-[400px]"
        unique-id="selected_organizations"
        :library-view="false"
        :other-table-dragging="tableDraggingId"
        :can-delete="true"
        :draggable-items="currentEditingRubric.allowlist || []"
        @dragging="(draggingId) => (tableDraggingId = draggingId)"
        @on-delete="deleteOrganization"
        @change-draggable-items="changeOrganization"
      />
      <div class="flex gap-3">
        <CustomButton
          button-type="admin-secondary"
          :start-icon="PlusIcon"
          @click="organizationShown = true"
        >
          Add Organization
        </CustomButton>
        <CustomButton
          button-type="admin-primary"
          :loading="saveLoading"
          :disabled="cantSave"
          @click="saveOrganizations"
        >
          Save Changes
        </CustomButton>
      </div>
    </div>
    <div v-if="organizationShown" class="w-[400px]">
      <DraggableTemplate
        title="Organizations"
        unique-id="organizations_library"
        :other-table-dragging="tableDraggingId"
        :loading="organizationLibraryLoading"
        :can-add="false"
        :draggable-items="shownLibraryOrganizations"
        @on-search="(search: string) => setOrganizationsList(search)"
        @on-close="organizationShown = false"
        @dragging="(draggingId) => (tableDraggingId = draggingId)"
      />
    </div>

    <div v-if="authStore.isSuperAdminUser && IS_PRODUCTION" class="flex flex-col gap-5">
      <hr />

      <div class="flex flex-col gap-5">
        <h2 class="text-xl">Global Content</h2>
        <p>
          <strong>Note:</strong> this feature is available in production and for SuperAdmin users
          only.
        </p>

        <CustomButton :loading="syncLoading" @click="syncRubricToRegions"
          >Synchronize to other regions</CustomButton
        >
      </div>
    </div>
  </div>
</template>
