import { createAuth0 } from '@auth0/auth0-vue'
import { AUTH0_AUDIENCE } from '@/constants'
import { useAuthStore } from '@/stores/auth'
import { useSimulationStore } from '@/stores/simulation'
import { clearTelemetry } from '@/telemetry'

export const auth0 = createAuth0(
  {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENTID,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    authorizationParams: {
      redirect_uri: window.location.origin + '/authorize',
      audience: AUTH0_AUDIENCE,
      scope: 'openid profile email offline_access'
    }
  },
  // TODO: Change to a error path?
  { errorPath: '/login' }
)

export function getAccessTokenSilentlyOutsideComponent(): Promise<string | void> {
  return auth0.getAccessTokenSilently().catch((err) => {
    console.error(err)
    logout()
  })
}

export function getIsAuthenticatedOutsideComponent() {
  return auth0.isAuthenticated
}

export function getUserOutsideComponent() {
  return auth0.user
}

export async function checkSessionOutsideComponent() {
  await auth0.checkSession()
}

export function logout() {
  const authStore = useAuthStore()
  const simulationStore = useSimulationStore()

  authStore.clearStore()
  simulationStore.clearStore()
  clearTelemetry()
  auth0.logout({
    openUrl() {
      window.location.replace(`${window.location.origin}/login`)
    }
  })
}
