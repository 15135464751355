import type { CorrectCharacterAnswerMessageInput } from '../models/CorrectCharacterAnswerMessageInput';
import type { CorrectCharacterAnswerMessageOutput } from '../models/CorrectCharacterAnswerMessageOutput';
import type { CorrectGradedRubricItemInput } from '../models/CorrectGradedRubricItemInput';
import type { CorrectGradedRubricItemOutput } from '../models/CorrectGradedRubricItemOutput';
import type { GetMessageCorrectionsOutput } from '../models/GetMessageCorrectionsOutput';
import type { GetRubricItemCorrectionsOutput } from '../models/GetRubricItemCorrectionsOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';



export class DataScienceService {

	/**
	 * CorrectCharacterAnswerMessage
	 * Creates a correction of a character answer message, containing an improved
 * LLM competion.
	 * @param requestBody 
	 * @returns CorrectCharacterAnswerMessageOutput Correction created successfully.
	 * @throws ApiError
	 */
	public static correctCharacterAnswerMessageEndpoint(
requestBody: CorrectCharacterAnswerMessageInput,
): CancelablePromise<CorrectCharacterAnswerMessageOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/data-science/CorrectCharacterAnswerMessage',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to create corrections.`,
			},
		});
	}

	/**
	 * CorrectGradedRubricItem.
	 * Creates a correction of a graded rubric item, containing a satisfactory
 * designation and feedback.
	 * @param requestBody 
	 * @returns CorrectGradedRubricItemOutput Correction created successfully.
	 * @throws ApiError
	 */
	public static correctGradedRubricItemEndpoint(
requestBody: CorrectGradedRubricItemInput,
): CancelablePromise<CorrectGradedRubricItemOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/data-science/CorrectGradedRubricItem',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to create corrections.`,
			},
		});
	}

	/**
	 * GetMessageCorrections
	 * Retrieve message corrections for the given conversation id.
	 * @param conversationId The ID of the conversation.
	 * @returns GetMessageCorrectionsOutput Message corrections retrieved successfully.
	 * @throws ApiError
	 */
	public static getMessageCorrectionsEndpoint(
conversationId: string,
): CancelablePromise<GetMessageCorrectionsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/data-science/GetMessageCorrections',
			query: {
				conversation_id: conversationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query message corrections.`,
			},
		});
	}

	/**
	 * GetRubricItemCorrections
	 * Retrieve rubric item corrections for the given graded rubric id.
	 * @param gradedRubricId The ID of the graded rubric.
	 * @returns GetRubricItemCorrectionsOutput Rubric item corrections retrieved successfully.
	 * @throws ApiError
	 */
	public static getRubricItemCorrectionsEndpoint(
gradedRubricId: string,
): CancelablePromise<GetRubricItemCorrectionsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/data-science/GetRubricItemCorrections',
			query: {
				graded_rubric_id: gradedRubricId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query rubric item corrections.`,
			},
		});
	}

}