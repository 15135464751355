<script setup lang="ts">
import { ref } from 'vue'
import generateResourceId from '@/utils/generateResourceId'
import { useRouter } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useCreateCohortV2 from '@/composables/api/mutations/useCreateCohortV2'
import { schema, type Schema } from '@/composables/api/mutations/schema/useCreateCohortV2'
import { CommandState } from '@/composables/api/mutations/types'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { AutoForm, type Config } from '@/components/modern/ui/auto-form'
import { Button } from '@/components/modern/ui/button'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import {
  LABEL_COHORT_NAME,
  LABEL_COHORT_ISDEFAULT,
  DESCRIPTION_COHORT_NAME,
  DESCRIPTION_COHORT_ISDEFAULT
} from '@/constants/forms'
import { useAuthStore } from '@/stores/auth'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - New Cohort',
  meta: {
    permissionLevel: 'InstitutionAdmin',
    isModern: true
  }
})

const router = useRouter()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('New Cohort')

const cohortId = ref<string>(generateResourceId('cohort_id'))

const { state, execute, reset } = useCreateCohortV2({ cohortId, notificationStore, authStore })

const form = useForm({ validationSchema: toTypedSchema(schema) })

const fieldConfig: Config<Schema> = {
  name: {
    label: LABEL_COHORT_NAME,
    description: DESCRIPTION_COHORT_NAME
  },
  isDefault: {
    label: LABEL_COHORT_ISDEFAULT,
    description: DESCRIPTION_COHORT_ISDEFAULT,
    hideRequired: true
  }
}

const submit = form.handleSubmit(async (values: Schema) => {
  await execute(values)
  if (state.value === CommandState.FAILURE) {
    reset()
    cohortId.value = generateResourceId('cohort_id')
  }
  if (state.value === CommandState.SUCCESS) {
    reset()
    router.push({
      name: 'Modern Cohorts - Cohort Learners List',
      params: { cohortId: cohortId.value }
    })
  }
})
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Administration' }"
        name="cohort administration"
      />
    </template>
  </TopLine>
  <div class="mt-4 flex w-full flex-row justify-center lg:mt-6">
    <div class="mx-4 mb-8 max-w-4xl flex-1 grow space-y-2 lg:mx-6">
      <AutoForm :schema="schema" :form="form" :field-config="fieldConfig">
        <Button
          variant="default"
          size="xs"
          :disabled="state === CommandState.IN_PROGRESS"
          @click="submit"
        >
          <PlusIcon class="mr-2 size-4" />
          <span>Create Cohort</span>
        </Button>
      </AutoForm>
    </div>
  </div>
</template>
