<script setup lang="ts">
import { usePageHeadingStore } from '@/stores/pageHeading'
import OrganizationLabel from '@/components/modern/app-navigation-sidebar/OrganizationLabel.vue'
import ScheduledDowntimeWarning from '@/components/modern/ScheduledDowntimeWarning.vue'

const pageHeadingStore = usePageHeadingStore()
</script>

<template>
  <div>
    <OrganizationLabel class="hidden print:absolute print:flex" />
    <h1 class="py-2 text-center text-sm font-medium">
      {{ pageHeadingStore.pageHeading }}
    </h1>
    <ScheduledDowntimeWarning />
  </div>
</template>
