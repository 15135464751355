import { useAuthStore } from '@/stores/auth'
import Api from '@/open-api'
import { registerTelemetry, registerUnauthedTelemetry } from '@/telemetry'
import { useAuth0 } from '@auth0/auth0-vue'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { useTokenStore } from '@/stores/token'
import { getAccessTokenSilentlyOutsideComponent } from '@/utils/auth0'

export const onStartup = () => {
  const router = useRouter()
  const authStore = useAuthStore()
  const tokenStore = useTokenStore()
  const { isLoading: isAuth0Loading, isAuthenticated: isAuth0Authenticated } = useAuth0()

  const { stop } = watch(
    isAuth0Loading,
    () => {
      // TODO: optimize by checking if the path is '/authorize'
      // TODO: optimize by persistently storing the authsource
      if (!isAuth0Loading.value) {
        if (isAuth0Authenticated.value) {
          // This starts the background refresh of tokens & sets a global token getter function
          // This way the rest of the app can deal with abstractions instead of directly /auth0
          // 245s chosen because 300s lifetime and any call within 60s fetches a new token
          tokenStore.setRefreshTimeout(getAccessTokenSilentlyOutsideComponent, 245 * 1000)
        }

        Api.User.whoAmIEndpoint()
          .then((res) => {
            authStore.setWhoAmI(res)
            registerTelemetry(res)
          })
          .catch((err) => {
            if (err?.body?.message === "Sorry, but you can't log in from your current location.") {
              authStore.setGeoError(true)
            }
            registerUnauthedTelemetry(err)
            router.push('/login')
          })
          .finally(() => {
            authStore.setLoaded()
            // need to stop here because this is loaded in a component and so this watcher lives for the duration of the component
            // we only want this to run once on startup
            // can't use 'once' variants because we don't definitively know initial value of loading will be true
            stop()
          })
      }
    },
    { immediate: true }
  )
}
