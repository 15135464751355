<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { onMounted } from 'vue'

definePage({
  name: 'Unsubscribe',
  meta: { permissionLevel: 'Student' }
})

const authStore = useAuthStore()

onMounted(() => {
  authStore.setNotificationIncompleteAssignments(false)
})
</script>

<template>
  <p class="mt-4 text-center">
    You've been unsubscribed! If you don't see a message saying that your notifications have been
    updated, please refresh the page.
  </p>
</template>
