import { defineStore } from 'pinia'
import type { AuthSourceApi, WhoAmIOutput } from '@/open-api/generated'
import { Role } from '@/types/auth'
import { ref, computed } from 'vue'
import { useNotificationStore } from '@/stores/notifications'
import Api from '@/open-api'
import { useSuperAdminStore } from '@/stores/superadmin'

export const useAuthStore = defineStore('auth', () => {
  const notificationStore = useNotificationStore()
  const superAdminStore = useSuperAdminStore()
  const loading = ref<boolean>(true)
  const whoAmI = ref<WhoAmIOutput | undefined>()
  const geoError = ref<boolean>(false)

  const trueRole = computed<Role>(() =>
    whoAmI.value?.user_role ? Role[whoAmI.value.user_role] : Role.ANY
  )
  const isTrueSuperAdmin = computed<boolean>(() => trueRole.value === Role.SuperAdmin)
  const role = computed<Role>(() => {
    if (isTrueSuperAdmin.value) {
      return superAdminStore.syntheticRole || trueRole.value
    }
    return trueRole.value
  })
  const isSuperAdminUser = computed<boolean>(() => role.value === Role.SuperAdmin)
  const isAtLeastStaffUser = computed<boolean>(() => role.value >= Role.Staff)
  const isAtLeastInstitutionAdminUser = computed<boolean>(() => role.value >= Role.InstitutionAdmin)
  const isAtLeastEducatorUser = computed<boolean>(() => role.value >= Role.Educator)
  const isAtLeastStudentUser = computed<boolean>(() => role.value >= Role.Student)
  const trueOrganizationId = computed<string | undefined>(() => whoAmI.value?.organization_id)
  const organizationId = computed<string | undefined>(() => {
    if (isTrueSuperAdmin.value) {
      return superAdminStore.syntheticOrganizationId || trueOrganizationId.value
    }
    return trueOrganizationId.value
  })

  const isAuthenticated = computed<boolean>(() => !!whoAmI.value)

  const flagAuthoringEnabled = computed<boolean>(
    () => whoAmI.value?.flag_authoring_enabled || false
  )
  const flagLegacyEnabled = computed<boolean>(
    () => whoAmI.value?.flag_legacy_features_enabled || false
  )
  const flagModernEnabled = computed<boolean>(
    () => whoAmI.value?.flag_modern_features_enabled || false
  )
  const orgColor = computed<string>(
    () => (whoAmI.value?.flag_powered_by_enabled && whoAmI.value?.accent_color) || '#FC894B'
  )
  const orgLogo = computed<string>(
    () => (whoAmI.value?.flag_powered_by_enabled && whoAmI.value?.logo_url) || ''
  )
  const notificationIncompleteAssignments = computed<boolean>(
    () => whoAmI.value?.notification_incomplete_assignments ?? true
  )

  const authSource = computed<AuthSourceApi | undefined>(() => whoAmI.value?.auth_source)

  const setLoaded = () => (loading.value = false)
  const setWhoAmI = (newWhoAmI: WhoAmIOutput | undefined) => (whoAmI.value = newWhoAmI)
  const updateWhoAmI = (whoAmIUpdate: Partial<WhoAmIOutput>) =>
    (whoAmI.value = { ...whoAmI.value!, ...whoAmIUpdate })
  const setGeoError = (newGeoError: boolean) => (geoError.value = newGeoError)
  const clearStore = () => {
    whoAmI.value = undefined
    geoError.value = false
  }

  const setNotificationIncompleteAssignments = (allowNotifications: boolean) => {
    const onSetNotificationSuccess = () => {
      notificationStore.addSUCCESS('Updated notifications')
      updateWhoAmI({ notification_incomplete_assignments: allowNotifications })
    }
    const onSetNotificationFailure = () => {
      notificationStore.addDANGER('Unable to update notifications')
    }
    if (whoAmI.value) {
      Api.Management.updateUserEndpoint({
        user_id: whoAmI.value.user_id,
        user_role: whoAmI.value.user_role,
        notification_incomplete_assignments: allowNotifications
      }).then(onSetNotificationSuccess, onSetNotificationFailure)
    } else {
      onSetNotificationFailure()
    }
  }

  return {
    whoAmI,
    loading,
    geoError,
    role,
    isAuthenticated,
    isTrueSuperAdmin,
    isSuperAdminUser,
    authSource,
    isAtLeastStaffUser,
    isAtLeastInstitutionAdminUser,
    isAtLeastEducatorUser,
    isAtLeastStudentUser,
    organizationId,
    flagAuthoringEnabled,
    flagLegacyEnabled,
    flagModernEnabled,
    orgColor,
    orgLogo,
    notificationIncompleteAssignments,
    setLoaded,
    setWhoAmI,
    setGeoError,
    clearStore,
    setNotificationIncompleteAssignments
  }
})
