import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { v4 as uuidv4 } from 'uuid'

// cn merges tailwind classes
// defined for api compatibility with shadcn react library
// used when modifying styles on tailwind styled components
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// Return a deep copy of the input.
// Using json parse hack as it is more permissive than struturedClone API
// typecasting to T is valid so long as input type is accurate
export function deepCopy<T>(value: T): T {
  return JSON.parse(JSON.stringify(value)) as T
}

export const makeValidIdFrom = (initial: string): string => {
  const uniqueString = `id_${initial}_${uuidv4()}`
  return uniqueString.replace(/\W/g, '_')
}
