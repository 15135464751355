<!-- eslint-disable -->
<script setup lang="ts">
import type { CharacterActionSummary } from '@/open-api/generated'
import {
  VrSettingType,
  VrCharacterType,
  type VrConversationOutput,
  type Position
} from '@/vr/types/vr.d'
import type { Scene } from '@/vr/types/aframe'
import { ref, computed, type Component, provide, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useSimulationStore } from '@/stores/simulation'
import { getVrCharacterConversation, getVrSettingCharacter } from '@/vr/utils/getVrCharacter'
import {
  getVrPosition,
  getVrCharacterPosition,
  getVrCharacterMenuPosition
} from '@/vr/utils/getVrPositioning'
import { useVrConversation } from '@/vr/composables/useVrSimulation'
import useVrSceneAssets from '@/vr/composables/useVrSceneAssets'
import useImageLoading from '@/vr/composables/useImageLoading'
import useVrNotifications from '@/vr/composables/useVrNotifications'
import useAframeLazily from '@/vr/composables/useAframeLazily'
import VrScene from '@/vr/components/VrScene.vue'
import VrRig from '@/vr/components/VrRig.vue'
import VrER from '@/vr/components/VrER.vue'
import VrHospitalRoom from '@/vr/components/VrHospitalRoom.vue'
import VrConferenceRoom from '@/vr/components/VrConferenceRoom.vue'
import VrHowTo from '@/vr/components/VrHowTo.vue'
import VrCharacter from '@/vr/components/VrCharacter.vue'
import VrEndConversation from '@/vr/components/VrEndConversation.vue'
import { investigationStaticKey, currentInvestigationKey } from '@/vr/utils/injectionKeys'

definePage({
  name: 'VR Scene',
  meta: {
    permissionLevel: 'Student'
  }
})

const simulationStore = useSimulationStore()
const conversations = simulationStore.vrConversation!

const route = useRoute<'VR Scene'>()
// `sceneId` is the master key, all the other information hangs off this id
const sceneId = route.params.sceneId

// gather assets and characters
// `setting` is the second key, it's how we get all the right adjustments
const { setting, vrAssets, vrCharacters } = useVrSceneAssets(sceneId)

// get positioning for scene elements using `setting`
const { Rig, HowTo, EndConversation } = getVrPosition(setting)

// Use `setting` to pick the right VR room component
const Setting = computed<Component>(() => {
  switch (setting) {
    case VrSettingType.ER:
      return VrER
    case VrSettingType.HospitalRoom:
      return VrHospitalRoom
    case VrSettingType.ConferenceRoom:
      return VrConferenceRoom
    default:
      throw new Error('no VR room type specified')
  }
})

// Use setting (and character type) to pair up characters, conversations, and positionings
const characters = computed<[VrConversationOutput, VrCharacterType, Position, Position][]>(() =>
  vrCharacters.map(({ type, cantTalk }) => [
    getVrCharacterConversation(conversations, type),
    getVrSettingCharacter(setting, type, cantTalk),
    getVrCharacterPosition(setting, type),
    getVrCharacterMenuPosition(setting, type)
  ])
)

const {
  startSpeaking,
  stopSpeaking,
  micHolding,
  robotResponding,
  robotSpeaking,
  hoverMenu,
  optionsMenu,
  actionMenu,
  investigationMenu,
  selectedCharacter,
  hoveringCharacter,
  currentInvestigation,
  selectCharacter,
  closeOptions,
  toggleInvestigations,
  performInvestigation,
  toggleActions,
  endConversation: tryEndConversation,
  performAction: performActionInner
} = useVrConversation()

// wrap some actions for VR specific extra behaviour
const hasNarcan = ref(false)
const performAction = (action: CharacterActionSummary) => {
  if (action.public_label.includes('Narcan')) {
    hasNarcan.value = true
  }
  return performActionInner(action)
}

// Tell the device to exit VR when user clicks End Conversation
// `exitVR()` is located on the top-level <a-scene>
const sceneElement = ref<Scene | null>(null)
const assignSceneElementRef = (el: Scene) => {
  sceneElement.value = el
}

const endConversation = (): void => {
  const returnValue = tryEndConversation()
  if (returnValue) {
    if (sceneElement.value !== null) {
      sceneElement.value.exitVR()
    }
  }
}

// Load image assets and convert to base64
const allImages = useImageLoading()
// Lazy-load AFRAME
const aframeLoaded = useAframeLazily()
// Helper to display app notifications in VR
const { notification } = useVrNotifications()

// Loading screen that disappears on one full loop of the (very short) character animation
// This is the best indicator that all the various asset fetching, loading, and GPU work
// has completed and it's safe to show the scene
// This is here and not buried in VrScene etc because it needs to listen to VrCharacter
// as that is where the best indicator of "scene is ready" comes from
const sceneHasBegun = ref<boolean>(false)
const beginScene = () => {
  if (!sceneHasBegun.value) {
    sceneHasBegun.value = true
  }
}

// We want to know if the user is in a VR headset or on desktop
// The way we show investigations changes depending on this:
// on desktop the best experience is "stick to screen"
// on VR the best experience is a static panel in the simulation
const investigationViewIsStatic = ref<boolean>(false)
watch([aframeLoaded, sceneHasBegun], () => {
  // ensure we have AFRAME available
  // update on sceneHasBegun as well, as it's the last point
  // we can reasonably change our display method
  if ('AFRAME' in window) {
    console.log('found aframe')
    investigationViewIsStatic.value = AFRAME.utils.device.checkHeadsetConnected()
  }
})
// We don't know where the investigation view might be
// Rather than prop drill into both VrCharacter and VrRig
// just so each of them can provide the props to VrInvestigationView
// Instead provide it from here
provide(currentInvestigationKey, currentInvestigation)
provide(investigationStaticKey, investigationViewIsStatic)
</script>

<template>
  <VrScene
    :aframe-loaded="aframeLoaded"
    :scene-has-begun="sceneHasBegun"
    :assets="vrAssets"
    :all-images="allImages"
    :assign-ref="assignSceneElementRef"
  >
    <VrRig :notification="notification" v-bind="Rig" />
    <component :is="Setting" />
    <VrHowTo v-bind="HowTo" />
    <VrEndConversation v-bind="EndConversation" :end-conversation="endConversation" />
    <VrCharacter
      v-for="[character, characterAssetId, characterPosition, menuPosition] in characters"
      :key="character.type"
      :character-id="characterAssetId"
      :character="character"
      v-bind="characterPosition"
      :menu-place="menuPosition"
      :has-narcan="hasNarcan"
      :setting="setting"
      :hover-menu="
        (hoveringCharacter === character.type && hoverMenu) ||
        (selectedCharacter?.type === character.type && (optionsMenu || robotResponding)) ||
        (micHolding && selectedCharacter?.type === character.type)
      "
      :robot-responding="robotResponding"
      :mic-holding="micHolding"
      :robot-speaking="robotSpeaking"
      :options-menu="optionsMenu"
      :investigation-menu="investigationMenu"
      :action-menu="actionMenu"
      @begin-scene="beginScene"
      @select-character="selectCharacter(character)"
      @mouse-enter-character="
        () => {
          hoveringCharacter = character.type
          hoverMenu = true
        }
      "
      @mouse-down-character="
        () => {
          selectCharacter(character)
          startSpeaking()
          actionMenu = false
          investigationMenu = false
          optionsMenu = false
        }
      "
      @toggle-investigations="toggleInvestigations"
      @perform-investigation="performInvestigation"
      @close-investigations-menu="investigationMenu = false"
      @toggle-actions="toggleActions"
      @perform-action="performAction"
      @close-actions-menu="actionMenu = false"
      @close-options="closeOptions"
      @mouse-up-character="stopSpeaking"
      @mouse-leave-character="() => (hoverMenu = false)"
    />
  </VrScene>
</template>
