<script lang="ts" setup>
import { computed } from 'vue'
import useAllOrganizations from '@/composables/api/useAllOrganizations'
import { useAuthStore } from '@/stores/auth'
import AppBrainIcon from '@/components/AppBrainIcon.vue'

const authStore = useAuthStore()
const { organizations } = useAllOrganizations()

const currentOrganization = computed(() =>
  organizations.value.find((org) => org.organization_id === authStore.organizationId)
)
</script>

<template>
  <div class="flex w-full items-center gap-1 px-1 py-2 text-sm font-medium">
    <AppBrainIcon class="size-4 flex-none" />

    <div class="truncate" :title="currentOrganization?.name">{{ currentOrganization?.name }}</div>
  </div>
</template>
