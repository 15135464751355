<script setup lang="ts">
import { computed } from 'vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

const props = withDefaults(
  defineProps<{
    loading: boolean
    color?: string
    centerScreen?: boolean
  }>(),
  {
    loading: false,
    color: '#D1D5DB',
    centerScreen: false
  }
)

const calculateCenterClasses = computed(() =>
  props.centerScreen ? 'h-full absolute inset-0 flex flex-wrap justify-center items-center' : ''
)
</script>

<template>
  <div :class="['flex items-center justify-center', calculateCenterClasses]">
    <pulse-loader class="flex" :loading="loading" :color="color" />
  </div>
</template>
