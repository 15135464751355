<script setup lang="ts">
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetMyCohorts from '@/composables/api/queries/useGetMyCohorts'
import { DataTable } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/educator-cohorts'
import { TopLine } from '@/components/modern/page-navigation'

definePage({
  name: 'Modern Cohorts - My Cohorts List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('My Cohorts')

const notificationStore = useNotificationStore()

const { isLoading, cohorts } = useGetMyCohorts({ notificationStore })

const educatorCohorts = useColumns()
</script>

<template>
  <TopLine>
    <template #left>
      <span>My Cohorts</span>
    </template>
  </TopLine>
  <DataTable :data="cohorts" :columns="educatorCohorts" :loading="isLoading" />
</template>
