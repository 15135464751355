<script setup lang="ts">
import { usePageHeadingStore } from '@/stores/pageHeading'
import { ref, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useCombineQueries from '@/composables/api/useCombineQueries'
import useGetOrganizationCohorts from '@/composables/api/queries/useGetOrganizationCohorts'
import useArchiveCohort from '@/composables/api/mutations/useArchiveCohort'
import useUnarchiveCohort from '@/composables/api/mutations/useUnarchiveCohort'
import { CommandState } from '@/composables/api/mutations/types'
import type { GetOrganizationOrganizationCohort } from '@/open-api/generated'
import { TopLine } from '@/components/modern/page-navigation'
import { MultiTable } from '@/components/modern/ui/data-table'
import { useColumns, useGroups } from '@/components/modern/tables/organization-cohorts'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Cohort Administration',
  meta: {
    permissionLevel: 'InstitutionAdmin',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('All Cohorts')

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const { track, isLoading, refetch } = useCombineQueries()
const { cohorts: activeCohorts } = track(
  useGetOrganizationCohorts({ authStore, notificationStore })
)
const isActiveCohort = computed((): Set<string> => new Set(activeCohorts.value.map((c) => c.id)))
const { cohorts } = track(
  useGetOrganizationCohorts({ authStore, notificationStore }, { includeArchived: true })
)

const archiveCohortId = ref<string | null>(null)
const archiveCohort = useArchiveCohort({ cohortId: archiveCohortId, notificationStore })
const requestArchiveCohort = (cohort: GetOrganizationOrganizationCohort) =>
  (archiveCohortId.value = cohort.id)
const confirmArchiveCohort = async () => {
  await archiveCohort.execute()
  const success = archiveCohort.state.value === CommandState.SUCCESS
  refetch()
  archiveCohort.reset()
  return success
}

const unarchiveCohortId = ref<string | null>(null)
const unarchiveCohort = useUnarchiveCohort({ cohortId: unarchiveCohortId, notificationStore })
const requestUnarchiveCohort = (cohort: GetOrganizationOrganizationCohort) =>
  (unarchiveCohortId.value = cohort.id)
const confirmUnarchiveCohort = async () => {
  await unarchiveCohort.execute()
  const success = unarchiveCohort.state.value === CommandState.SUCCESS
  refetch()
  unarchiveCohort.reset()
  return success
}

const columns = useColumns({
  isActiveCohort,
  requestArchiveCohort,
  confirmArchiveCohort,
  requestUnarchiveCohort,
  confirmUnarchiveCohort
})

const groups = useGroups({ isActiveCohort })
</script>

<template>
  <TopLine>
    <template #left>
      <span>All Cohorts</span>
    </template>
    <template #right>
      <Button variant="default" size="xs" as-child>
        <RouterLink :to="{ name: 'Modern Cohorts - New Cohort' }">
          <PlusIcon class="mr-2 size-4" />
          <p>Create Cohort</p>
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <MultiTable :data="cohorts" :columns="columns" :groups="groups" :loading="isLoading" />
</template>
