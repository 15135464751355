<script setup lang="ts">
import { useAssignmentStore } from '@/stores/assignment'
import { computed, ref } from 'vue'
import { onBeforeMount } from 'vue'
import type { MyTask } from '@/open-api/generated'
import type { MyPreLearningTaskMeta } from '@/types/api'
import { reactive, nextTick } from 'vue'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import CustomButton from '@/components/utils/CustomButton.vue'
import AppBrainIcon from '@/components/AppBrainIcon.vue'
import { RichTextReader } from '@/components/rich-text'
import Api from '@/open-api'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useAuthStore } from '@/stores/auth'
import { useRouter, useRoute } from 'vue-router'

definePage({
  name: 'MyAssignments Prelearning Task',
  meta: {
    permissionLevel: 'Student'
  }
})

// ==================================================
// Init
// ==================================================
const router = useRouter()
const route = useRoute('MyAssignments Prelearning Task')
const assignmentStore = useAssignmentStore()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()

const assignmentTaskLoading = ref(false)

const isPreview = computed(() => {
  return route.query.preview === 'preview' && authStore.isAtLeastEducatorUser
})

onBeforeMount(() => {
  if (!currentTask.value && !isPreview.value) {
    assignmentTaskLoading.value = true
    Api.MyAssignments.getMyAssignmentEndpoint(route.params.assignmentId as string)
      .then(async (res) => {
        assignmentStore.setCurrentAssignment(res.assignment)

        nextTick(() => {
          if (!currentTask.value) {
            router.push({ name: 'MyAssignments' })
            notificationStore.addNotification({
              subtitle: 'Task not found',
              status: NotificationStatus.DANGER
            })
          }
        })
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        assignmentTaskLoading.value = false
      })
  }
})

// ==================================================
// task logic
// ==================================================

const currentTask = computed((): MyTask | undefined => {
  if (isPreview.value) {
    const task = assignmentStore.previewTasks.find((task) => task.task_id === route.params.taskId)
    return {
      task_id: task?.task_id,
      task_type: task?.task_type,
      name: task?.name,
      mandatory: task?.mandatory,
      description: task?.description,
      meta: task?.meta
    } as MyTask
  }
  if (!assignmentStore?.currentAssignment?.tasks) {
    return undefined
  }
  return assignmentStore.currentAssignment?.tasks.find(
    (task) => task.task_id === route.params.taskId
  )
})

// ==================================================
// prelearning task
// ==================================================
const preLearningTask = reactive({
  completeLoading: false,
  startLoading: false,
  attemptId: ''
})

onBeforeMount(() => {
  if (currentTask.value?.task_id && !route.query.preview) {
    preLearningTask.startLoading = true

    Api.MyAssignments.startPrelearningTaskEndpoint(currentTask.value.task_id)
      .then((res) => {
        preLearningTask.attemptId = res.prelearning_task_attempt_id
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        preLearningTask.startLoading = false
      })
  }
})

const completeTask = () => {
  if (!preLearningTask.attemptId) {
    return
  }

  preLearningTask.completeLoading = true

  Api.MyAssignments.completePrelearningTaskEndpoint(preLearningTask.attemptId)
    .then(() => {
      router.push({
        name: 'MyAssignments Details',
        params: { assignmentId: route.params.assignmentId }
      })
      notificationStore.addNotification({
        subtitle: 'Task completed',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      preLearningTask.completeLoading = false
    })
}
</script>

<template>
  <template v-if="!assignmentTaskLoading">
    <div class="sticky -top-3 z-50 mb-5 flex flex-col gap-5 bg-white pt-3">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <h1 class="text-2xl font-medium">{{ currentTask?.name }}</h1>
        </div>
        <div v-if="!route.query.preview" class="hidden md:flex">
          <CustomButton
            v-if="!(currentTask?.meta as MyPreLearningTaskMeta)?.PreLearningTask?.completed"
            :loading="preLearningTask.completeLoading"
            :disabled="!preLearningTask.attemptId"
            @click="completeTask"
          >
            Complete task
          </CustomButton>
          <CustomButton
            v-else
            button-type="admin-primary"
            @click="
              () => {
                router.push({
                  name: 'MyAssignments Details',
                  params: { assignmentId: route.params.assignmentId }
                })
              }
            "
          >
            Back to Assignments
          </CustomButton>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-10">
      <div
        v-for="(section, sectionIndex) in (currentTask?.meta as MyPreLearningTaskMeta)
          ?.PreLearningTask?.sections"
        :key="sectionIndex"
        class="flex flex-col gap-10"
      >
        <RichTextReader :content="section" />

        <div
          v-if="
            sectionIndex !==
            (currentTask?.meta as MyPreLearningTaskMeta).PreLearningTask.sections.length - 1
          "
          class="relative h-px items-center justify-center bg-sc-grey-400"
        >
          <AppBrainIcon
            class="absolute left-1/2 top-1/2 h-[35px] -translate-x-1/2 -translate-y-1/2 bg-white px-2"
          />
        </div>
      </div>

      <div v-if="!route.query.preview" class="mb-5 flex md:hidden">
        <CustomButton
          v-if="!(currentTask?.meta as MyPreLearningTaskMeta)?.PreLearningTask?.completed"
          class="w-full"
          :loading="preLearningTask.completeLoading"
          @click="completeTask"
        >
          Complete task
        </CustomButton>
        <CustomButton
          v-else
          class="w-full"
          @click="
            router.push({
              name: 'MyAssignments Details',
              params: { assignmentId: route.params.assignmentId }
            })
          "
        >
          Back to Assignments
        </CustomButton>
      </div>
    </div>
  </template>
  <AppLoadingSpinner v-else center-screen loading />
</template>
