<script lang="ts" setup>
import { watch } from 'vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useRouter } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'
import { useAuthStore } from '@/stores/auth'

definePage({
  name: 'Authorize from Auth0',
  meta: {
    permissionLevel: 'ANY'
  }
})

const router = useRouter()
const { isAuthenticated, isLoading } = useAuth0()
const authStore = useAuthStore()

async function handleCallback() {
  if (!isLoading.value) {
    if (isAuthenticated.value) {
      if (authStore.flagModernEnabled) {
        router.push({ name: 'Modern - My Activities' })
      } else {
        router.push({ name: 'MyAssignments' })
      }
    } else {
      router.push({ name: 'Login' })
    }
  }
}

// This page is tightly coupled to auth0 so it's okay to directly access
watch([isLoading, isAuthenticated], handleCallback, { immediate: true })
</script>

<template>
  <AppLoadingSpinner loading center-screen />
</template>
