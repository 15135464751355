import type { RouteLocationNormalized } from 'vue-router'
import { Role } from '@/types/auth'
import { useAuthStore } from '@/stores/auth'
import { getUserOutsideComponent } from '@/utils/auth0'

// For role guards, we want Typescript to to ensure we specifically return a boolean.
// This is the boolean which indicates permitted/not permitted,
// And we don't want to allow returning e.g. other routes, as it is not the
// roleGuard's job to handle redirects - only deciding on permissions.
// type RoleGuardType = (to: RouteLocationNormalized) => boolean

export const roleGuard = (to: RouteLocationNormalized): boolean => {
  const authStore = useAuthStore()
  // This was present in the role getter in the old authStore
  // Unclear what it mutates under the hood
  // Leaving for safety
  // TODO: Investigate if actually needed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = getUserOutsideComponent()

  const routeRole = Role[to.meta.permissionLevel]
  const hasPermission = authStore.role >= routeRole
  if (!hasPermission) {
    return false
  }

  const routeRequiresAuthoring: boolean = to.meta.requiresAuthoring || false
  const organizationHasAuthoring: boolean = authStore.flagAuthoringEnabled

  if (routeRequiresAuthoring && !organizationHasAuthoring) {
    return false
  }
  return true
}
