import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { roleGuard } from '@/router/guard/roleGuard'
import { Role } from '@/types/auth'
import { IS_LOCAL } from '@/constants'
import { useAuthStore } from '@/stores/auth'

export const globalBeforeEachGuard = async (
  to: RouteLocationNormalized
): Promise<boolean | RouteLocationRaw> => {
  if (!to.meta) {
    // route is not configured correctly
    if (IS_LOCAL) {
      console.warn('Route not correctly configured with meta properties: ', to)
    }
    return false
  }
  const routeRole = Role[to.meta.permissionLevel]
  const isPublic = routeRole == Role.ANY

  if (isPublic) {
    return true
  }

  const authStore = useAuthStore()

  // This state is only available on page load
  // i.e user has just opened the app, or has just been redirected to '/authorize' page
  if (authStore.loading) {
    // It is okay to allow access to a page in this instance
    // If user is unauthed on a page when loading finishes the following will occur
    // 1. The 'Unauthenticated' layout will load (no sidebar pop in)
    // 2. Whatever page is meant to show will enter a loading state
    // as it attempts to fetch data
    // 3. startup.ts > onStartup() whoAmI call will fail
    // 4. the user is redirected to '/login'
    return true
  }
  if (!authStore.isAuthenticated) {
    return '/login'
  }

  return roleGuard(to)
}
