import type { Notification } from '@/types/notification'
import { NotificationStatus } from '@/types/notification'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: [] as Notification[]
  }),
  getters: {},
  actions: {
    removeNotification(notification: Notification) {
      this.notifications = this.notifications.filter((n) => n.id !== notification.id)
    },
    addNotification(notification: Notification) {
      notification.id = uuidv4()
      notification.timeoutVal = notification.timeoutVal || 5000
      notification.title = notification.title || ''
      notification.subtitle = notification.subtitle || ''
      notification.status = notification.status || NotificationStatus.INFO

      this.notifications?.push(notification)
      setTimeout(() => this.removeNotification(notification), notification.timeoutVal)
    },
    addSUCCESS(subtitle: any) {
      this.addNotification({
        subtitle,
        status: NotificationStatus.SUCCESS
      })
    },
    addINFO(subtitle: any) {
      this.addNotification({
        subtitle,
        status: NotificationStatus.INFO
      })
    },
    addWARNING(subtitle: any) {
      this.addNotification({
        subtitle,
        status: NotificationStatus.WARNING
      })
    },
    addDANGER(subtitle: any) {
      this.addNotification({
        subtitle,
        status: NotificationStatus.DANGER
      })
    }
  }
})
