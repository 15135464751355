<script lang="ts" setup>
import { ref, watch } from 'vue'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/modern/ui/select'
import { cn } from '@/lib/utils'
import useAllOrganizations from '@/composables/api/useAllOrganizations'
import { useAuthStore } from '@/stores/auth'
import { useSuperAdminStore } from '@/stores/superadmin'

const authStore = useAuthStore()
const superAdminStore = useSuperAdminStore()
const { organizations } = useAllOrganizations()

// casting as string because this component can only render for a logged in user
const currentOrganizationId = ref<string>(authStore.organizationId!)

watch(currentOrganizationId, () => {
  superAdminStore.setSyntheticOrganizationId(currentOrganizationId.value)
})
</script>

<template>
  <Select v-model="currentOrganizationId">
    <SelectTrigger
      aria-label="admin organization select"
      :class="
        cn(
          'flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0'
        )
      "
    >
      <SelectValue placeholder="Select an organization" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem
        v-for="organization of organizations"
        :key="organization.organization_id"
        :value="organization.organization_id"
      >
        <div
          class="flex items-center gap-3 [&_svg]:size-4 [&_svg]:shrink-0 [&_svg]:text-foreground"
        >
          {{ organization.name }}
        </div>
      </SelectItem>
    </SelectContent>
  </Select>
</template>
