<script setup lang="ts">
import type { Component } from 'vue'
import { Button } from '@/components/modern/ui/button'
import { useLink, type RouteLocationRaw } from 'vue-router'

/**
 * ## Troubleshooting
 * ```
 * Types of property 'to' are incompatible.
 *   Type 'string' is not assignable to type 'RouteLocationAsRelativeTyped<RouteNamedMap...
 * ```
 * You need to use `as const`, like so:
 * ```
 * {
 *   name: 'Cohort' as const,
 *   params: { cohortId: cohort.id }
 * }
 * ```
 *
 * ## Rationale
 * We use `Exclude<RouteLocationRaw, string>` to enforce that all route locations
 * are provided as typed named objects:
 * ```
 * { name: 'Cohort', params: { cohortId: cohort.id } }
 * ```
 * And prevent route locations being provided as constructed paths:
 * ```
 *  `cohort/${id}/activities`
 * ```
 */
export type NavItemRoute = {
  label: string
  to: Exclude<RouteLocationRaw, string>
  icon: Component
}

const props = defineProps<{
  route: NavItemRoute
}>()
const { isActive } = useLink({ to: props.route.to })
</script>

<template>
  <Button
    variant="ghost"
    size="xs"
    as-child
    class="group w-full items-center justify-start p-1 font-normal hover:bg-border/70"
    :class="{ 'bg-border': isActive }"
  >
    <router-link :to="route.to">
      <component
        :is="route.icon"
        class="mr-1.5 size-4 flex-shrink-0 group-hover:stroke-accent-foreground"
      />
      <span class="group-hover:text-accent-foreground">{{ route.label }}</span>
    </router-link>
  </Button>
</template>
