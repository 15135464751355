import { Environments } from '@/types/utils'

export const IS_PRODUCTION: boolean = import.meta.env.VITE_ENV === Environments.PROD

export const IS_LOCAL: boolean = import.meta.env.VITE_ENV === Environments.LOCAL

export const POSTHOG_KEY: string = import.meta.env.VITE_POSTHOG_KEY

export const POSTHOG_HOST: string = import.meta.env.VITE_POSTHOG_HOST

export const HILBERT_URL: string = import.meta.env.VITE_SC_HILBERT_URL

export const AUTH0_DOMAIN: string = import.meta.env.VITE_AUTH0_DOMAIN

export const AUTH0_CLIENTID: string = import.meta.env.VITE_AUTH0_CLIENTID

export const AUTH0_AUDIENCE: string = import.meta.env.VITE_AUTH0_AUDIENCE

export const SCHEDULED_DOWNTIME: string =
  import.meta.env.VITE_SCHEDULED_DOWNTIME ?? '2122-12-12T12:00:00+00:00'
