// import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { routes } from 'vue-router/auto-routes'

import { globalBeforeEachGuard } from '@/router/guard'
import type { NavigationGuardWithThis } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach(globalBeforeEachGuard as NavigationGuardWithThis<undefined>)

export default router
