import type { App } from 'vue'
import { nextTick } from 'vue'
import * as Sentry from '@sentry/vue'
import posthog from 'posthog-js'
import $posthog from './utils/posthog'
import { HILBERT_URL, IS_LOCAL, IS_PRODUCTION } from './constants'
import type { Router } from 'vue-router'
import type { WhoAmIOutput } from '@/open-api/generated'

export const initialiseTelemetry = (app: App, router: Router) => {
  if (!IS_PRODUCTION) {
    app.config.warnHandler = (msg, instance, trace) => {
      if (msg.includes('Failed to resolve component: a-')) {
        return
        // Asynchronously loaded code does not use our build-time compiler options
        // So we have to manually suppress this error instead of using
        // compilerOptions: isCustomElement
      } else if (
        msg.includes('Avoid app logic that relies on enumerating keys on a component instance')
      ) {
        return
        // Vue Dev Tools causes this error multiple times per pageload
        // It enumerates component instance keys in the process of inspecting the component tree
      } else {
        // a slightly nicer error message and stack trace to boot
        console.warn(`[Vue warn] ${msg}\n%O\n${trace}`, instance)
      }
    }
  }

  if (!IS_LOCAL) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      tunnel: `${HILBERT_URL}/SentryProxy`,
      release: '0.1.0',
      environment: import.meta.env.VITE_ENV,
      integrations: [
        posthog.sentryIntegration({
          organization: 'simconverse',
          projectId: import.meta.env.VITE_SENTRY_DSN
        })
      ],
      // https://docs.sentry.io/platforms/javascript/configuration/integrations/inboundfilters/
      ignoreErrors: [
        // Do not report "not logged in" errors to Sentry
        'You are not authorized to perform this action: You are not logged in',
        // These are thrown when the user is logged out.
        'Request failed with status code 401',
        'Not logged in',
        // Happens when we deploy and someone has their browser open.
        'Load failed',
        'Importing a module script failed.',
        // Chrome on iOS injects a __gCrWeb script that causes this error
        '__gCrWeb.edgeTranslate.detectPageState',
        // Caused by Microsoft Outlook's Safe Links scanner automatically scanning
        // our login links.
        'Non-Error promise rejection captured',
        // Likely caused by some extension trying to change the browser's user agent
        "Identifier 'change_ua' has already been declared"
      ]
    })

    // Posthog
    app.use((app) => {
      app.config.globalProperties.$posthog = $posthog
    })
  }
  router.afterEach((to, _from, failure) => {
    if (!failure) {
      nextTick(() => {
        app.config.globalProperties.$posthog?.capture('$pageview', { path: to.fullPath })
      })
    }
  })
}

const identifyUser = (data?: WhoAmIOutput) => ({
  id: data?.user_id,
  user_id: data?.user_id,
  user_role: data?.user_role,
  organization_id: data?.organization_id
})

export const registerTelemetry = (whoAmI: WhoAmIOutput) => {
  if (!IS_LOCAL) {
    const sentryUserInfo = identifyUser(whoAmI)
    Sentry.setUser(sentryUserInfo)
    // Successful whoAmI call: tag this session as authenticated
    Sentry.setTag('authenticated', true)
    // Posthog uses a slightly different argument format
    const { id, ...posthogUserInfo } = sentryUserInfo
    posthog.identify(id, posthogUserInfo)
    posthog.startSessionRecording()
  }
}

export const registerUnauthedTelemetry = (err: any) => {
  if (!IS_LOCAL) {
    // Unsuccessful whoAmI call: tag this session as unauthenticated
    Sentry.setTag('authenticated', false)
    // Set details for this specific captured exception *only*:
    Sentry.withScope((scope) => {
      // Indicate the exception specifically came from the failed WhoAmI
      // We do this to distinguish it from later exceptions still tagged as unauthenticated
      scope.setTag('source-code', 'src/App.vue > updateUserContext')
      Sentry.captureException(err)
    })
  }
}

export const clearTelemetry = () => {
  if (!IS_LOCAL) {
    Sentry.setUser(null)
    posthog.reset()
  }
}
