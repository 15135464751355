<script setup lang="ts">
import AppBrainIcon from '@/components/AppBrainIcon.vue'

definePage({
  name: '404',
  meta: {
    permissionLevel: 'ANY'
  }
})
</script>

<template>
  <div class="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-white">
    <div class="py-16">
      <div class="flex flex-col text-center">
        <AppBrainIcon class="mb-10 h-64" />
        <p class="text-simconverse-orange text-sm font-semibold uppercase tracking-wide">
          404 error
        </p>
        <h1 class="mt-2 text-2xl font-extrabold tracking-tight text-zinc-900 sm:text-5xl">
          Page not found.
        </h1>
        <p class="mt-2 text-base text-zinc-500">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div class="mt-6 animate-bounce">
          <router-link
            to="/"
            class="text-simconverse-orange hover:text-simconverse-orange text-base font-medium"
          >
            Go back to the dashboard
            <span aria-hidden="true"> &rarr; </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
