import Api from '@/open-api'
import type {
  CancelablePromise,
  GetMyCohortsCohort,
  GetMyCohortsOutput
} from '@/open-api/generated'
import type { useNotificationStore } from '@/stores/notifications'
import { type Ref, ref, onBeforeMount } from 'vue'

const useGetMyCohorts = ({
  notificationStore
}: {
  notificationStore: ReturnType<typeof useNotificationStore>
}): { isLoading: Ref<boolean>; cohorts: Ref<GetMyCohortsCohort[]>; refetch: () => void } => {
  let req: CancelablePromise<GetMyCohortsOutput> | null = null
  const isLoading = ref(false)
  const cohorts: Ref<GetMyCohortsCohort[]> = ref([])

  const fetchCohorts = () => {
    if (req && isLoading.value) {
      req.cancel()
    }
    isLoading.value = true

    req = Api.V2.Cohorts.getMyCohortsEndpointV2()
    req
      .then((res) => {
        cohorts.value = res.cohorts
      })
      .catch((err) => {
        if (err?.name === 'CancelError') {
          return
        }
        notificationStore.addDANGER(err?.body?.message || 'Error getting my cohorts')
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  onBeforeMount(fetchCohorts)

  return { isLoading, cohorts, refetch: fetchCohorts }
}

export default useGetMyCohorts
