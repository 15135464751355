<script setup lang="ts">
import type {
  OrganizationIdAndName,
  UpdateCharacterInput,
  CharacterOutput,
  SyncCharacterToRegionsInput
} from '@/open-api/generated'
import { ref, reactive, computed, onBeforeMount } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import DraggableTemplate from '@/components/utils/DraggableTemplate.vue'
import { useContentStore } from '@/stores/content'
import { PlusIcon } from '@heroicons/vue/24/outline'
import Api from '@/open-api'
import { deepCopy } from '@/lib/utils'

import { EMPTY_STRING_SEARCH_ALL, IS_PRODUCTION } from '@/constants'

definePage({
  name: 'Character Access Controls',
  meta: {
    permissionLevel: 'InstitutionAdmin',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const authStore = useAuthStore()
const notificationStore = useNotificationStore()
const contentStore = useContentStore()
const tableDraggingId = ref('')

// ==================================================
// Organizations
// ==================================================
const currentEditingCharacter = reactive<Partial<CharacterOutput>>({})

onBeforeMount(() => {
  Object.assign(currentEditingCharacter, deepCopy(contentStore.editingCharacter))
})

const cantSave = computed(() => {
  const storeJSON = JSON.stringify(contentStore?.editingCharacter?.allowlist)
  const currentJSON = JSON.stringify(currentEditingCharacter.allowlist)
  return storeJSON === currentJSON
})

const saveLoading = ref(false)

const changeOrganization = ($event: any) => {
  if ($event.added && currentEditingCharacter.allowlist) {
    const addingOrg = $event.added.element
    delete addingOrg.auth0_org_id

    currentEditingCharacter.allowlist.push(addingOrg)
  }
}

const saveOrganizations = () => {
  const { editingCharacter } = contentStore

  if (!editingCharacter) {
    return
  }
  const updateCharacter = contentStore.editingCharacter as UpdateCharacterInput | CharacterOutput

  const update = { ...updateCharacter } as UpdateCharacterInput

  saveLoading.value = true

  const allowListUpdate: string[] | null = authStore.isAtLeastStaffUser
    ? currentEditingCharacter.allowlist?.map((org) => org.organization_id) || []
    : null

  update.allowlist = allowListUpdate
  update.owning_organization_id = currentEditingCharacter.owning_organization_id

  Api.Content.updateCharacterEndpoint(update as UpdateCharacterInput)
    .then((res) => {
      contentStore.setEditingCharacter(res)
      Object.assign(currentEditingCharacter, res)

      notificationStore.addNotification({
        subtitle: 'Character organizations access successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

const deleteOrganization = (index: number) => {
  if (!currentEditingCharacter.allowlist) {
    return
  }
  currentEditingCharacter.allowlist.splice(index, 1)
}

// ==================================================
// Organizations Library
// ==================================================
const organizationShown = ref(false)
const organizationLibraryLoading = ref(false)
let organizationsLibrary = ref<OrganizationIdAndName[]>([])

onBeforeMount(() => {
  setOrganizationsList()
})

const setOrganizationsList = async (search = EMPTY_STRING_SEARCH_ALL) => {
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }
  organizationLibraryLoading.value = true
  try {
    const orgs = await Api.Management.listAllOrganizationsEndpoint(search)
    organizationsLibrary.value = orgs.organizations
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    organizationLibraryLoading.value = false
  }
}

const shownLibraryOrganizations = computed(() => {
  const uniqueValues = new Set()
  const result: any[] = []

  if (!currentEditingCharacter?.allowlist?.length) {
    return organizationsLibrary.value
  }

  const combinedArray = [...organizationsLibrary.value, ...currentEditingCharacter.allowlist]

  combinedArray.forEach((item) => {
    if (!uniqueValues.has(item?.organization_id)) {
      uniqueValues.add(item.organization_id)
      result.push(item)
    } else {
      result.forEach((existingItem, index) => {
        if (existingItem?.organization_id === item.organization_id) {
          result.splice(index, 1)
        }
      })
    }
  })

  return result
})

// ==================================================
// Global content.
// ==================================================
const syncLoading = ref<boolean>(false)

const syncCharacterToRegions = async () => {
  if (!currentEditingCharacter.character_id) {
    return
  }

  const input: SyncCharacterToRegionsInput = {
    character_id: currentEditingCharacter.character_id
  }

  syncLoading.value = true
  try {
    await Api.Content.syncCharacterToRegionsEndpoint(input)
    notificationStore.addSUCCESS('Character successfully synchronized with other regions.')
  } catch (err: any) {
    notificationStore.addDANGER(err?.body?.message)
  } finally {
    syncLoading.value = false
  }
}
</script>

<template>
  <div class="flex h-full flex-col gap-10">
    <div class="flex flex-col gap-5">
      <h2 class="text-xl">Access control</h2>

      <div v-if="authStore.isAtLeastStaffUser" class="flex flex-col gap-5">
        <CustomInput
          v-model="currentEditingCharacter.owning_organization_id"
          class="!mb-0 !w-[300px]"
          input-type="select"
          placeholder="Select an organization"
          label="Owner Organization"
          :options="
            organizationsLibrary.map((org) => {
              return {
                name: org.name,
                value: org.organization_id
              }
            })
          "
        />
        <CustomButton
          button-type="admin-primary"
          :loading="saveLoading"
          :disabled="saveLoading"
          @click="saveOrganizations"
        >
          Save Changes
        </CustomButton>
      </div>

      <h3 class="text-lg">Organizations</h3>
      <p v-if="!currentEditingCharacter?.allowlist?.length" class="text-sc-grey-600">
        You have no organizations added to this section
      </p>
      <DraggableTemplate
        class="w-[400px]"
        unique-id="selected_organizations"
        :library-view="false"
        :other-table-dragging="tableDraggingId"
        :can-delete="true"
        :draggable-items="currentEditingCharacter.allowlist || []"
        @dragging="(draggingId) => (tableDraggingId = draggingId)"
        @on-delete="deleteOrganization"
        @change-draggable-items="changeOrganization"
      />
      <div class="flex gap-3">
        <CustomButton
          button-type="admin-secondary"
          :start-icon="PlusIcon"
          @click="organizationShown = true"
        >
          Add Organization
        </CustomButton>
        <CustomButton
          button-type="admin-primary"
          :loading="saveLoading"
          :disabled="cantSave"
          @click="saveOrganizations"
        >
          Save Changes
        </CustomButton>
      </div>
    </div>
    <div v-if="organizationShown" class="w-[400px]">
      <DraggableTemplate
        title="Organizations"
        unique-id="organizations_library"
        :other-table-dragging="tableDraggingId"
        :loading="organizationLibraryLoading"
        :can-add="false"
        :draggable-items="shownLibraryOrganizations"
        @on-search="(search: string) => setOrganizationsList(search)"
        @on-close="organizationShown = false"
        @dragging="(draggingId) => (tableDraggingId = draggingId)"
      />
    </div>

    <div v-if="authStore.isSuperAdminUser && IS_PRODUCTION" class="flex flex-col gap-5">
      <hr />

      <div class="flex flex-col gap-5">
        <h2 class="text-xl">Global Content</h2>
        <p>
          <strong>Note:</strong> this feature is available in production and for SuperAdmin users
          only.
        </p>

        <CustomButton :loading="syncLoading" @click="syncCharacterToRegions"
          >Synchronize to other regions</CustomButton
        >
      </div>
    </div>
  </div>
</template>
