import { useWindowSize } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useUtilsStore = defineStore(
  'utils',
  () => {
    const { width: windowWidth } = useWindowSize()
    const historyUserId = ref<string | undefined>()
    const myAssignmentId = ref<string | undefined>()
    const hasViewedWalkthrough = ref<boolean>(false)

    const isMobile = computed<boolean>(() => windowWidth.value <= 1024)
    const setHasViewedWalkthrough = (hasViewed: boolean) => (hasViewedWalkthrough.value = hasViewed)
    const setMyAssignmentId = (assignmentId: string | undefined) =>
      (myAssignmentId.value = assignmentId)
    const setHistoryUserId = (userId: string | undefined) => (historyUserId.value = userId)

    return {
      windowWidth,
      historyUserId,
      myAssignmentId,
      hasViewedWalkthrough,
      setHasViewedWalkthrough,
      setMyAssignmentId,
      setHistoryUserId,
      isMobile
    }
  },
  { persist: true }
)
