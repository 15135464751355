import { ref, computed, onBeforeUnmount } from 'vue'

/**
 * An upgraded setInterval that automatically handles clearing itself on component unmount.
 *
 * @example
 * <script>
 * // use named destructuring, e.g.:
 * const {
 *   set: setMyInterval,
 *   clear: clearMyInterval,
 *   isActive: isMyIntervalActive
 * } = useHandledInterval()
 * </script>
 * @returns set:  used exactly like setInterval, comes with automatic free unmount handling.
 * @returns clear:  used like clearInterval, except it remembers its interval ID for you.
 * @returns isActive: React to changes in interval state. Goes true when interval is installed, goes false when cleared.
 * @example
 * <script>
 * setMyInterval(() => {
 *  // do something
 * }, 1000)
 *
 * // optional, it will clear itself on component unmount
 * clearMyInterval()
 * </script>
 *
 * // react to interval being set or cleared
 * <template>
 *   <AppLoading :loading="isMyIntervalActive" />
 * </template>
 */
const useHandledInterval = () => {
  // Create one intervalId ref per invocation of useHandledInterval,
  // but re-use the ref for each subsequent call to setInterval.
  const intervalId = ref<ReturnType<typeof setInterval>>(0)

  const set = (handler: () => void, delay: number): void => {
    // First, clear current interval if it exists
    if (intervalId.value) {
      clearInterval(intervalId.value)
    }
    // Call out to the actual setInterval and save its id
    intervalId.value = setInterval(handler, delay)

    // We explicitly return void to discourage the dev from re-implementing intervalId handling.
    return
  }

  // Clear the loop associated with our setInterval call.
  const clear = (): void => {
    if (intervalId.value) {
      clearInterval(intervalId.value)
      intervalId.value = 0
    }
  }

  // Register cleanup with the appropriate lifecycle hook.
  onBeforeUnmount(clear)

  // React to the interval being installed / cleared
  const isActive = computed<boolean>(() => intervalId.value > 0)

  return { set, clear, isActive }
}

export default useHandledInterval
