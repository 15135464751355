<script setup lang="ts">
import { nextTick } from 'vue'
import { ref, reactive, onBeforeMount, watch, computed } from 'vue'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import CustomButton from '@/components/utils/CustomButton.vue'
import DraggableTemplate from '@/components/utils/DraggableTemplate.vue'
import { useContentStore } from '@/stores/content'
import { useAuthStore } from '@/stores/auth'
import CustomInput from '@/components/utils/CustomInput.vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import type {
  ItemTemplate,
  Rubric,
  RubricOutput,
  RubricSection,
  SectionTemplateOutput
} from '@/open-api/generated'
import type {
  RubricItemPayloadQuestion,
  RubricItemPayloadAction,
  RubricItemPayloadInvestigation
} from '@/types/api'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon
} from '@heroicons/vue/24/outline'
import Api from '@/open-api'
import CustomPopover, { type PopoverOption } from '@/components/utils/CustomPopover.vue'
import { deepCopy } from '@/lib/utils'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import InputNumber from '@/components/input/InputNumber.vue'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants'

definePage({
  name: 'Rubric View',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const route = useRoute('Rubric View')
const isReadOnly = computed(() => {
  return (
    route.params.rubricId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingRubric as RubricOutput)?.owning_organization_id
    )
  )
})

onBeforeRouteLeave((to, from, next) => {
  if (route.params.rubricId === 'new' ? cantSave.value : !cantSave.value) {
    const answer = window.confirm(
      'Warning: You have unsaved changes. \nNavigating away from this page will delete any unsaved changes.'
    )
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

// ==================================================
// Sections
// ==================================================
const sectionLibraryLoading = ref(false)
let sectionLibraryList: SectionTemplateOutput[] = reactive([])

onBeforeMount(() => {
  setSectionsList()
})

const setSectionsList = async (search = EMPTY_STRING_SEARCH_ALL) => {
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }
  sectionLibraryLoading.value = true
  try {
    const sections = await Api.Content.listSectionTemplatesEndpoint(search, 15, 1)
    sectionLibraryList = sections.section_templates
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    sectionLibraryLoading.value = false
  }
}

// ==================================================
// Rubric Details
// ==================================================
const contentStore = useContentStore()
const currentEditingRubric: Partial<Rubric> = reactive({})

onBeforeMount(() => {
  Object.assign(currentEditingRubric, deepCopy(contentStore.editingRubric))
})
const cantSave = computed(() => {
  const { public_label, internal_description, minimum_passing_score, internal_label } =
    currentEditingRubric
  const storeJSON = JSON.stringify(contentStore.editingRubric)
  const currentJSON = JSON.stringify(currentEditingRubric)

  return (
    !public_label?.trim() ||
    !internal_description?.trim() ||
    !minimum_passing_score ||
    minimum_passing_score <= 0 ||
    !internal_label?.trim() ||
    storeJSON === currentJSON
  )
})

watch(
  currentEditingRubric,
  (newVal) => {
    if (route.params.rubricId === 'new') {
      contentStore.setEditingRubric(newVal)
    }
  },
  { deep: true }
)

const tableDraggingId = ref('')

const sectionLibrary = ref(false)

const changeSections = ($event: any) => {
  if ($event.added) {
    const newRubricSection = deepCopy($event.added.element)

    delete newRubricSection.section_template_id

    delete newRubricSection.internal_label

    currentEditingRubric.sections?.push(newRubricSection)
  } else if ($event.moved) {
    const { oldIndex, newIndex } = $event.moved
    if (currentEditingRubric) {
      const element = currentEditingRubric?.sections?.[oldIndex]
      currentEditingRubric?.sections?.splice(oldIndex, 1)
      currentEditingRubric?.sections?.splice(newIndex, 0, element as RubricSection)
    }
  }
}

const deleteRubricSection = (index: number) => {
  ;(currentEditingRubric.sections as RubricSection[]).splice(index, 1)
}

const saveLoading = ref(false)

const saveRubric = () => {
  saveLoading.value = true

  currentEditingRubric.allowlist = authStore.isAtLeastStaffUser
    ? currentEditingRubric.allowlist?.map((listItem: any) => {
        if (typeof listItem === 'string') {
          return listItem
        }
        return listItem?.organization_id
      })
    : null

  Api.Content.updateRubricEndpoint(currentEditingRubric as Rubric)
    .then((res) => {
      contentStore.setEditingRubric(res)
      Object.assign(currentEditingRubric, res)
      notificationStore.addNotification({
        subtitle: 'Rubric successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

// ==================================================
// Rubric Section Modal
// ==================================================

const editingRubricSection: { section: RubricSection; index: number } = reactive({
  section: deepCopy(contentStore.newSection),
  index: 0
})

const itemsErrorArr = computed((): { index: number; error: string }[] => {
  if (!editingRubricSection.section?.items?.length) {
    return []
  }
  return editingRubricSection.section.items.reduce(
    (acc, item, index) => {
      if (
        !item.public_label ||
        (!(item.payload as RubricItemPayloadQuestion).Question?.question &&
          !(item.payload as RubricItemPayloadAction).Action?.public_label &&
          !(item.payload as RubricItemPayloadInvestigation).Investigation?.public_label)
      ) {
        acc.push({ index, error: 'Missing value' })
      }
      return acc
    },
    [] as { index: number; error: string }[]
  )
})

const canSaveRubricSection = computed(() => {
  if (!currentEditingRubric.sections || !contentStore.editingRubric.sections) {
    return false
  }
  const { public_label, items, minimum_score } = editingRubricSection.section

  return (
    !public_label ||
    !items?.length ||
    !minimum_score ||
    minimum_score > items.length ||
    !!itemsErrorArr.value.length
  )
})

const rubricSectionModalIsOpen = ref(false)

const viewSection = (view: { element: RubricSection; index: number }) => {
  Object.assign(editingRubricSection, { section: view.element, index: view.index })
  rubricSectionModalIsOpen.value = true
}

const searchSections = (search: string) => {
  setSectionsList(search)
}

const moveItem = (oldIndex: number, newIndex: number) => {
  const element = editingRubricSection.section.items?.[oldIndex]
  editingRubricSection.section.items?.splice(oldIndex, 1)
  editingRubricSection.section.items?.splice(newIndex, 0, element as ItemTemplate)
}

const deleteItem = (index: number) => {
  editingRubricSection.section.items.splice(index, 1)
}

const saveRubricSection = () => {
  if (!currentEditingRubric?.sections) {
    return
  }
  currentEditingRubric.sections[editingRubricSection.index] = editingRubricSection.section
  rubricSectionModalIsOpen.value = false
}

const popoverOptions = ref<PopoverOption[]>([
  {
    name: 'Transcript item',
    action: () => addItem('Transcript')
  },
  {
    name: 'Action item',
    action: () => addItem('Action')
  },
  {
    name: 'Investigation item',
    action: () => addItem('Investigation')
  }
])

const addItem = (itemType: 'Transcript' | 'Action' | 'Investigation') => {
  const itemToPush: Partial<ItemTemplate> = {
    public_label: '',
    mandatory: false
  }

  if (itemType === 'Transcript') {
    itemToPush.payload = {
      Question: { question: '' }
    }
  } else if (itemType === 'Action') {
    itemToPush.payload = {
      Action: { public_label: '' }
    }
  } else if (itemType === 'Investigation') {
    itemToPush.payload = {
      Investigation: { public_label: '' }
    }
  }

  editingRubricSection.section?.items?.push(itemToPush as ItemTemplate)

  const sectionItems = document.getElementById('section_items')

  if (sectionItems) {
    nextTick(() => sectionItems.scrollTo(0, 1000))
  }
}
</script>

<template>
  <div class="relative flex flex-col">
    <div class="mr-10 flex w-[620px] grow flex-col gap-5">
      <div class="flex flex-col">
        <h2 class="mb-5 text-lg">Details</h2>
        <div class="grid grid-cols-2 gap-x-5">
          <CustomInput
            v-model="currentEditingRubric.public_label"
            class="my-0"
            label="Display Label"
            :read-only="isReadOnly"
          />
          <CustomInput
            v-model="currentEditingRubric.internal_label"
            :read-only="isReadOnly"
            label="Internal Label"
          />
        </div>

        <CustomInput
          v-model="currentEditingRubric.internal_description"
          label="Internal Description"
          :read-only="isReadOnly"
        />
        <div class="grid grid-cols-2 gap-x-5">
          <InputNumber
            v-model="currentEditingRubric.minimum_passing_score"
            label="Minimum Score"
            :read-only="isReadOnly"
            required
          />
        </div>
      </div>
      <div class="flex flex-col gap-5">
        <h2 class="text-lg">Sections</h2>
        <p
          v-if="!currentEditingRubric?.sections?.length && !tableDraggingId"
          class="text-sc-grey-600"
        >
          You have no sections added to this rubric
        </p>

        <DraggableTemplate
          v-else
          class="w-[400px] !pb-0"
          :library-view="false"
          :other-table-dragging="tableDraggingId"
          :draggable-items="currentEditingRubric.sections || []"
          :can-delete="true"
          :read-only="isReadOnly"
          unique-id="rubric_sections"
          @on-delete="deleteRubricSection"
          @change-draggable-items="changeSections"
          @dragging="(draggingId) => (tableDraggingId = draggingId)"
          @on-view="viewSection"
        />
      </div>
    </div>
    <CustomModal :model-value="rubricSectionModalIsOpen">
      <div class="flex flex-col overflow-hidden">
        <div class="relative flex basis-auto flex-col border-b border-sc-grey-300">
          <div class="mb-5 flex items-center justify-between">
            <h3 class="select-none text-lg">Details</h3>
            <div
              v-if="isReadOnly"
              class="h-7 w-7 cursor-pointer p-1"
              @click="rubricSectionModalIsOpen = false"
            >
              <XMarkIcon class="h-5 w-5" />
            </div>
          </div>
          <CustomInput
            v-model="editingRubricSection.section.public_label"
            class="my-0"
            label="Section Label"
            :read-only="isReadOnly"
          />
          <div class="grid grid-cols-2 gap-x-5">
            <InputNumber
              v-model="editingRubricSection.section.minimum_score"
              label="Minimum Score"
              :read-only="isReadOnly"
              required
            />
            <CustomInput
              v-model="editingRubricSection.section.mandatory"
              label="Mandatory"
              :options="[
                { name: 'Yes', value: true },
                { name: 'No', value: false }
              ]"
              input-type="select"
              placeholder="Select an option"
              :read-only="isReadOnly"
            />
          </div>
        </div>
        <div
          id="section_items"
          class="flex grow flex-col divide-y-2 divide-dashed overflow-y-auto overflow-x-hidden"
        >
          <div
            v-for="(item, itemIndex) in editingRubricSection.section?.items"
            :key="`section_item_${itemIndex}`"
            class="hover group relative flex items-center gap-5 px-1"
          >
            <div
              class="absolute -left-1 -right-1 bottom-3 top-3 hidden rounded-mlg bg-sc-grey-100 group-hover:block"
            />
            <div class="z-10 flex grow flex-col">
              <p class="mt-3 text-xs text-sc-grey-600">
                {{
                  `${
                    (item.payload as RubricItemPayloadQuestion)?.Question
                      ? 'TRANSCRIPT'
                      : (item.payload as RubricItemPayloadAction)?.Action
                        ? 'ACTION'
                        : 'INVESTIGATION'
                  } ITEM`
                }}
              </p>
              <div :class="['mt-3 grid grid-cols-2 gap-x-5']">
                <CustomInput
                  v-model="item.public_label"
                  label="Item Label"
                  :read-only="isReadOnly"
                />
                <CustomInput
                  v-model="item.mandatory"
                  label="Mandatory"
                  :options="[
                    { name: 'Yes', value: true },
                    { name: 'No', value: false }
                  ]"
                  input-type="select"
                  placeholder="Select an option"
                  :read-only="isReadOnly"
                />
              </div>
              <CustomInput
                v-if="(item.payload as RubricItemPayloadQuestion)?.Question"
                v-model="(item.payload as RubricItemPayloadQuestion).Question.question"
                input-type="textarea"
                :text-area-grow="true"
                class="my-0"
                label="Marking Instruction"
                :read-only="isReadOnly"
              />
              <CustomInput
                v-else-if="(item.payload as RubricItemPayloadAction)?.Action"
                v-model="(item.payload as RubricItemPayloadAction).Action.public_label"
                input-type="textarea"
                :text-area-grow="true"
                class="my-0"
                label="Action Label"
                :read-only="isReadOnly"
              />
              <CustomInput
                v-else-if="(item.payload as RubricItemPayloadInvestigation)?.Investigation"
                v-model="
                  (item.payload as RubricItemPayloadInvestigation).Investigation.public_label
                "
                input-type="textarea"
                :text-area-grow="true"
                class="my-0"
                label="Investigation Label"
                :read-only="isReadOnly"
              />
              <CustomInput
                v-model="item.failure_hint"
                input-type="textarea"
                :text-area-grow="true"
                :required="false"
                class="my-0"
                label="Failure Hint"
                :read-only="isReadOnly"
              />
            </div>

            <div class="flex flex-col justify-center gap-3">
              <div
                v-if="itemIndex !== 0 && !isReadOnly"
                class="z-10 flex h-6 w-6 basis-auto items-center justify-center rounded-full bg-sc-grey-100 text-sc-grey-600 hover:text-sc-grey-900 group-hover:bg-sc-grey-200"
                @click="() => moveItem(itemIndex, itemIndex - 1)"
              >
                <ArrowUpIcon class="w-3.5" />
              </div>
              <div
                v-if="!isReadOnly"
                class="z-10 flex h-6 w-6 basis-auto items-center justify-center rounded-full bg-sc-grey-100 text-sc-grey-600 hover:text-sc-grey-900 group-hover:bg-sc-grey-200"
                @click="deleteItem(itemIndex)"
              >
                <TrashIcon class="w-3.5" />
              </div>
              <div
                v-if="itemIndex !== editingRubricSection.section?.items.length - 1 && !isReadOnly"
                class="z-10 flex h-6 w-6 basis-auto items-center justify-center rounded-full bg-sc-grey-100 text-sc-grey-600 hover:text-sc-grey-900 group-hover:bg-sc-grey-200"
                @click="() => moveItem(itemIndex, itemIndex + 1)"
              >
                <ArrowDownIcon class="w-3.5" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isReadOnly" class="mt-5 flex basis-auto justify-between">
          <CustomPopover
            class="[&_.popover-position]:-top-[155px] [&_.popover-position]:mt-2"
            button-type="admin-secondary"
            :start-icon="PlusIcon"
            :popover-options="popoverOptions"
            :disabled="saveLoading"
          >
            Add Item
          </CustomPopover>

          <div class="flex gap-3">
            <CustomButton button-type="admin-secondary" @click="rubricSectionModalIsOpen = false">
              Cancel
            </CustomButton>
            <CustomButton
              :disabled="canSaveRubricSection"
              button-type="admin-primary"
              @click="saveRubricSection"
            >
              Done
            </CustomButton>
          </div>
        </div>
      </div>
    </CustomModal>

    <div
      v-if="sectionLibrary"
      class="fixed right-[1.25rem] top-[calc(2.5rem+48px)] z-50 h-[calc(100%-48px-2.5rem-30px)] w-[400px] bg-white"
    >
      <DraggableTemplate
        title="Sections"
        unique-id="section_library"
        :other-table-dragging="tableDraggingId"
        :draggable-items="sectionLibraryList"
        :loading="sectionLibraryLoading"
        :can-add="false"
        @dragging="(draggingId) => (tableDraggingId = draggingId)"
        @on-close="sectionLibrary = false"
        @on-search="searchSections"
      />
    </div>

    <div class="mb-12 mt-4 flex gap-3 bg-white pb-12 pt-4">
      <CustomButton
        v-if="!isReadOnly"
        button-type="admin-secondary"
        :start-icon="PlusIcon"
        class="!mb-0"
        @click="sectionLibrary = true"
      >
        Add section
      </CustomButton>
      <CustomButton
        v-if="route.params.rubricId !== 'new' && !isReadOnly"
        :disabled="cantSave"
        :loading="saveLoading"
        button-type="admin-primary"
        class="!mb-0"
        @click="saveRubric"
      >
        Save changes
      </CustomButton>
    </div>
  </div>
</template>
