<script setup lang="ts">
import { TrashIcon, ClipboardDocumentIcon } from '@heroicons/vue/24/outline'
import type { UserSummary, CohortSummary, StudentInput } from '@/open-api/generated'
import { UserRoleApiEnum, type UserRoleApi } from '@/open-api/generated'
import { ref, computed, reactive, onUnmounted, watch } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { Role } from '@/types/auth'
import { isValidEmail } from '@/utils'
import { useUtilsStore } from '@/stores/utils'
import CustomModal from '@/components/utils/CustomModal.vue'
import CustomList from '@/components/CustomList.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import Api from '@/open-api'
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import InputEmail from '@/components/input/InputEmail.vue'
import InputNumber from '@/components/input/InputNumber.vue'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants'

definePage({
  name: 'Management Users',
  meta: {
    permissionLevel: 'InstitutionAdmin'
  }
})
// ==================================================
// Init
// ==================================================
const authStore = useAuthStore()
const notificationStore = useNotificationStore()
const utilsStore = useUtilsStore()
const selectedUser = ref<UserSummary | undefined>(undefined)
const router = useRouter()

// ==================================================
// Utils
// ==================================================
const mappedRoles = computed(() => {
  // This is kind of ugly but somehow better than what was there before.
  const availableRoles = []

  if (authStore.isAtLeastStudentUser) {
    availableRoles.push({
      name: 'Student',
      value: 'Student'
    })
  }

  if (authStore.isAtLeastEducatorUser) {
    availableRoles.push({
      name: 'Educator',
      value: 'Educator'
    })
  }

  if (authStore.isAtLeastInstitutionAdminUser) {
    availableRoles.push({
      name: 'Institution Admin',
      value: 'InstitutionAdmin'
    })
  }

  if (authStore.isAtLeastStaffUser) {
    availableRoles.push({
      name: 'Staff',
      value: 'Staff'
    })
  }

  if (authStore.isSuperAdminUser) {
    availableRoles.push({
      name: 'Super Admin',
      value: 'SuperAdmin'
    })
  }

  return availableRoles
})

const loginLink = computed(() => {
  return `${window.location.origin}/login/${authStore.organizationId} `
})

const copyLoginLink = () => {
  navigator.clipboard.writeText(loginLink.value)
  notificationStore.addNotification({
    title: 'Success 🎉',
    subtitle: 'Login link copied to clipboard',
    status: NotificationStatus.SUCCESS
  })
}

const copyLink = (link: string, toastMessage: string) => {
  navigator.clipboard.writeText(link)
  notificationStore.addNotification({
    title: 'Success 🎉',
    subtitle: toastMessage,
    status: NotificationStatus.SUCCESS
  })
}

// ==================================================
// Table filters
// ==================================================
const filterModalOpen = ref(false)
const roleFilter = ref(undefined)
const appliedRoleFilter = ref(undefined)

const onFilterOpen = () => {
  roleFilter.value = appliedRoleFilter.value
  filterModalOpen.value = true
}

const applyFilter = () => {
  appliedRoleFilter.value = roleFilter.value
  userListPagination.page = 1
  fetchOrganizationUsers(search.value)
  filterModalOpen.value = false
}

const onCloseFilters = () => {
  roleFilter.value = undefined
  filterModalOpen.value = false
}

// ==================================================
// Table Management
// ==================================================
const userListHeaders = [
  {
    name: 'Email',
    value: 'email'
  },
  {
    name: 'Role',
    value: 'user_role'
  },
  {
    name: 'Actions',
    value: 'action-view'
  }
]
const userListPagination = reactive({ items_per_page: 15, page: 1, total: 1 })
const isLoadingUsers = ref(false)
const users = ref<UserSummary[]>([])

const search = ref(EMPTY_STRING_SEARCH_ALL)

onBeforeMount(() => {
  fetchOrganizationUsers(search.value)
})

onUnmounted(() => {
  search.value = EMPTY_STRING_SEARCH_ALL
})

async function fetchOrganizationUsers(searchString: string) {
  isLoadingUsers.value = true

  search.value = searchString

  Api.Management.getOrganizationUsersEndpoint(
    authStore.organizationId!,
    userListPagination.items_per_page,
    userListPagination.page,
    appliedRoleFilter.value,
    search.value || undefined // '' is falsy so this will be undefined
  )
    .then((res) => {
      users.value = res.users
      userListPagination.total = res.pagination.total_pages || 1
    })
    .catch(() => {})
    .finally(() => {
      isLoadingUsers.value = false
    })
}

const changePage = (page: number) => {
  userListPagination.page = page
  fetchOrganizationUsers(search.value)
}

watch(
  () => authStore.organizationId,
  () => {
    fetchOrganizationUsers(search.value)
  }
)

// ==================================================
// Delete User from org
// ==================================================
const removeUser = async (user: UserSummary | undefined) => {
  if (!user?.user_id || isLoadingUsers.value || authStore.whoAmI?.user_id === user?.auth0_user_id) {
    return
  }

  if (window.confirm('Are you sure you want to remove this user from the organization?')) {
    isLoadingUsers.value = true
    Api.Management.deleteUserEndpoint({
      user_id: user.user_id
    })
      .then(() => {
        // Removed last user on page, update page number
        if (
          users.value.length === 1 &&
          userListPagination.page > 1 &&
          userListPagination.page === userListPagination.total
        ) {
          userListPagination.page = userListPagination.page - 1
        }

        fetchOrganizationUsers(search.value)
        notificationStore.addNotification({
          title: 'Success 🎉',
          subtitle: 'User has been removed',
          status: NotificationStatus.SUCCESS
        })
      })
      .catch(() => {})
      .finally(() => {
        isLoadingUsers.value = false
        modalOpen.value = false
      })
  }
}

// ==================================================
// View user's history
// ==================================================

const viewUsersHistory = () => {
  if (selectedUser.value?.user_id) {
    utilsStore.setHistoryUserId(selectedUser.value.user_id)
    router.push({ name: 'Organization History' })
  }
}

// ==================================================
// Update user role in org
// ==================================================
const updateUserRole = async (id: string) => {
  isLoadingUsers.value = true

  Api.Management.updateUserEndpoint({
    user_id: id,
    user_role: newUserSelectedRole.value
  })
    .then(() => {
      fetchOrganizationUsers(search.value)
      notificationStore.addNotification({
        title: 'Success 🎉',
        subtitle: 'User has been updated',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch(() => {})
    .finally(() => {
      modalOpen.value = false
      isLoadingUsers.value = false
    })
}

// ==================================================
// Create user in org
// ==================================================
const modalOpen = ref(false)
const createNewPasswordlessUserLoading = ref(false)
const newUserEmail = ref('')
const newUserSelectedRole = ref<UserRoleApi>(UserRoleApiEnum.STUDENT)

const isNewEmailValid = computed(() => isValidEmail(newUserEmail.value))

async function createNewPasswordlessUser() {
  if (newUserEmail.value === '' || !isNewEmailValid.value) {
    return
  }

  createNewPasswordlessUserLoading.value = true
  Api.Management.createPasswordlessUserEndpoint({
    email: newUserEmail.value,
    user_role: newUserSelectedRole.value as UserRoleApi,
    organization_id: authStore.organizationId!
  })
    .then(() => {
      fetchOrganizationUsers(search.value)
      modalOpen.value = false
      newUserEmail.value = ''
      newUserSelectedRole.value = 'Student'
      notificationStore.addNotification({
        title: 'Success 🎉',
        subtitle: 'User has been created',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      createNewPasswordlessUserLoading.value = false
    })
}

const saveUser = () => {
  if (selectedUser.value?.user_id) {
    updateUserRole(selectedUser.value.user_id)
  } else {
    createNewPasswordlessUser()
  }
}

// ==================================================
// Bulk Update
// ==================================================
type EmailArray = {
  [key: number]: string
}

type Fail = {
  email: string
  error: string
}

const bulkCreateModal = ref(false)
const createBulkUsersLoading = ref(false)
const searchCohortsLoading = ref(false)
const uploadedEmails = ref<EmailArray[]>([])
const selectedCohort = ref<string | undefined>(undefined)
const cohortList = ref<CohortSummary[]>([])
const csv = ref<{ email: string }[] | null>(null)

const failedCreatedList = ref<Fail[]>([])
const createdList = ref<StudentInput[]>([])
const existedList = ref<StudentInput[]>([])
const createdCohortList = ref<StudentInput[]>([])
const existedCohortList = ref<StudentInput[]>([])
const failedCreatedCohortList = ref(false)
const failedExistedCohortList = ref(false)

const emailCount = computed(() => {
  return uploadedEmails.value.length
})

const uploadProgress = computed(() => {
  return emailCount.value > 0
    ? (
        ((createdList.value.length + existedList.value.length + failedCreatedList.value.length) /
          emailCount.value) *
        100
      ).toFixed()
    : 0
})

watch(
  () => csv.value,
  (val) => {
    if (val) {
      uploadedEmails.value = val.map((item: any) => item.email)
      setCohortList()
    }
  }
)

function handleCsvUpload(event: Event) {
  const input = event.target as HTMLInputElement
  if (!input.files) {
    return
  }
  const file = input.files[0]
  const reader = new FileReader()

  reader.onload = (e) => {
    // Split by newline, trim each line, then filter out empty lines.
    const content = e.target?.result as string
    const lines = content.split(/\r\n|\n/)
    const emails = lines.map((line) => line.trim()).filter((line) => line)
    csv.value = emails.map((email) => {
      return {
        email
      }
    })
  }

  reader.readAsText(file)
}

function onCloseBulkUpload() {
  if (createBulkUsersLoading.value) {
    return
  }

  setTimeout(() => {
    bulkCreateModal.value = false
    uploadedEmails.value = []
    selectedCohort.value = undefined
    failedCreatedList.value = []
    createdList.value = []
    existedList.value = []
    createdCohortList.value = []
    existedCohortList.value = []
    failedCreatedCohortList.value = false
    failedExistedCohortList.value = false
    csv.value = null
    fetchOrganizationUsers(search.value)
  }, 500)
}

async function nextBulkCreate() {
  if (createBulkUsersLoading.value) {
    return
  }

  createBulkUsersLoading.value = true

  // Create users
  for (const key in uploadedEmails.value) {
    const studentEmail = uploadedEmails.value[key] as string
    try {
      const res = await Api.Management.createPasswordlessUserEndpoint({
        email: studentEmail,
        user_role: UserRoleApiEnum.STUDENT,
        organization_id: authStore.organizationId!
      })

      createdList.value.push({ user_id: res.user_id })
    } catch (err: any) {
      try {
        // Find existing user id
        const resExistingUser = await Api.Management.getOrganizationUsersEndpoint(
          authStore.organizationId!,
          1,
          1,
          undefined,
          studentEmail
        )

        if (resExistingUser.users.length === 0) {
          failedCreatedList.value.push({
            email: studentEmail,
            error: 'Cannot find user inside organization.'
          })
        } else {
          resExistingUser.users.forEach((user) => {
            existedList.value.push({ user_id: user.user_id })
          })
        }
      } catch (err2: any) {
        failedCreatedList.value.push({
          email: studentEmail,
          error: err2?.response?.data?.message || 'There has been an error'
        })
      }
    }
  }

  // Assign users to cohort
  if (selectedCohort.value && (createdList.value.length || existedList.value.length)) {
    if (createdList.value.length) {
      Api.Assignment.addStudentsToCohortEndpoint({
        cohort_id: selectedCohort.value,
        students: createdList.value
      })
        .then(() => {
          createdCohortList.value.push(...createdList.value)
        })
        .catch(() => {
          failedCreatedCohortList.value = true
        })
        .finally(() => {
          createBulkUsersLoading.value = false
        })
    } else if (existedList.value.length) {
      Api.Assignment.addStudentsToCohortEndpoint({
        cohort_id: selectedCohort.value,
        students: existedList.value
      })
        .then(() => {
          existedCohortList.value.push(...existedList.value)
        })
        .catch(() => {
          failedExistedCohortList.value = true
        })
        .finally(() => {
          createBulkUsersLoading.value = false
        })
    }
  } else {
    createBulkUsersLoading.value = false
  }
}

async function setCohortList(search = EMPTY_STRING_SEARCH_ALL) {
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }
  createBulkUsersLoading.value = true
  searchCohortsLoading.value = true
  try {
    const cohorts = await Api.Assignment.searchCohortsEndpoint(
      authStore.organizationId!,
      search,
      100,
      1
    )
    cohortList.value = cohorts.cohorts
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    createBulkUsersLoading.value = false
    searchCohortsLoading.value = false
  }
}
</script>

<template>
  <div class="mb-5 flex flex-col gap-y-5 px-5">
    <h1 class="mb-2 text-xl">Login Link</h1>
    <div class="flex w-[400px] items-center gap-5">
      <CustomInput :value="loginLink" class="!my-0" :read-only="true" label="Login link" />
      <ClipboardDocumentIcon
        class="h-5 w-5 cursor-pointer text-sc-grey-600"
        @click="copyLoginLink"
      />
    </div>
  </div>

  <CustomList
    title="Users"
    create-button="Add user"
    view-type="table"
    class="px-5 [&_.list-title]:text-xl"
    :list-headers="userListHeaders"
    :pagination="userListPagination"
    :list-data="users"
    :has-search="true"
    :has-header="true"
    :cell-add="false"
    has-filter
    :has-list-options="false"
    :loading="isLoadingUsers"
    @on-filter="onFilterOpen"
    @on-view="
      (data) => {
        // The user must be at least an InstitutionAdmin to update roles.
        if (authStore.isAtLeastInstitutionAdminUser) {
          selectedUser = users[data.index]

          // Users can only update roles of users with a lesser-or-equal role.
          // That is, users can't update roles upwards and break safety.
          if (authStore.role >= Role[selectedUser.user_role]) {
            newUserEmail = selectedUser.email
            newUserSelectedRole = selectedUser.user_role
            modalOpen = true
          }
        }
      }
    "
    @on-create="
      () => {
        selectedUser = undefined
        newUserEmail = ''
        newUserSelectedRole = UserRoleApiEnum.STUDENT
        modalOpen = true
      }
    "
    @on-search="(search: string) => fetchOrganizationUsers(search)"
    @on-change-page="(page: number) => changePage(page)"
  />

  <!-- Filter Modal -->
  <CustomModal v-model="filterModalOpen" @on-close="onCloseFilters">
    <div class="flex flex-col gap-3">
      <div class="mb-5 flex items-center justify-between">
        <h3 class="text-xl">Filter Users by</h3>
      </div>
      <CustomInput
        v-model="roleFilter"
        class="!my-0"
        input-type="select"
        placeholder="Select a role"
        label="Role"
        :options="[...mappedRoles, { name: 'Any', value: undefined }]"
      />
      <div class="mt-5 flex justify-end gap-3">
        <CustomButton button-type="admin-secondary" @click="() => (filterModalOpen = false)">
          Cancel
        </CustomButton>
        <CustomButton
          :loading="createNewPasswordlessUserLoading || isLoadingUsers"
          :disabled="roleFilter === appliedRoleFilter"
          @click="applyFilter"
        >
          Apply filters
        </CustomButton>
      </div>
    </div>
  </CustomModal>

  <!-- Bulk Upload -->
  <CustomModal v-model="bulkCreateModal" @on-close="onCloseBulkUpload">
    <div class="flex flex-col gap-3">
      <div class="mb-5 flex items-center justify-between">
        <h3 class="text-xl">Bulk Create Users</h3>
      </div>
      <div>
        <div v-if="createBulkUsersLoading">
          <div v-if="!searchCohortsLoading">
            <p>This process can take a few minutes.</p>
            <p class="mb-2">Please do not refresh the page or close this modal.</p>
            <p class="text-sm"><b>Total:</b> {{ emailCount }}</p>
            <p class="text-sm"><b>Created user count:</b> {{ createdList.length }}</p>
            <p class="text-sm"><b>Existing user count:</b> {{ existedList.length }}</p>
            <div class="mt-10 flex flex-col items-center">
              <div class="text-sc-grey-600">{{ uploadProgress }}%</div>
              <AppLoadingSpinner :loading="true" />
            </div>
          </div>
          <div v-else class="flex flex-col items-center">
            <p class="text-sc-grey-600">Processing...</p>
            <AppLoadingSpinner class="mt-1" :loading="true" />
          </div>
        </div>
        <div
          v-else-if="failedCreatedList.length || createdList.length || existedList.length"
          class="flex max-h-[50vh] flex-col gap-3 overflow-scroll"
        >
          <p class="mb-5 text-sm">
            {{ createdList.length }} users have been created.<br />
            {{
              existedList.length
                ? ` ${existedList.length} users already exist in the organization.`
                : ''
            }}<br />
            {{
              failedCreatedList.length
                ? ` ${failedCreatedList.length} users have failed to be created.`
                : ''
            }}
          </p>
          <p class="mb-5 text-sm">
            {{
              failedCreatedCohortList
                ? `${createdCohortList.length} new users were not all added to the cohort successfully`
                : `${createdCohortList.length} new users were added to the cohort successfully.`
            }}
            <br />
            {{
              failedExistedCohortList
                ? `${existedCohortList.length} existing users were not all added to the cohort successfully`
                : `${existedCohortList.length} existing users were added to the cohort successfully.`
            }}
          </p>
          <h3 v-if="failedCreatedList.length" class="text-xl text-red-600">Create Errors:</h3>
          <p v-for="np in failedCreatedList" :key="np.email" class="text-sm">
            {{ np.email }}: {{ np.error }}
          </p>
        </div>
        <div v-else>
          <div v-if="!uploadedEmails.length">
            <div class="mb-5">
              <p class="text-sm">Click here to upload your file.</p>
              <p class="mb-2 text-sm">
                The file needs to be a comma delimited csv, with no header and only emails.
              </p>
            </div>
            <input type="file" @change="handleCsvUpload" />
          </div>

          <div v-if="uploadedEmails.length">
            <InputNumber v-model="emailCount" label="Upload Count" disabled required />
            <CustomInput
              v-model="selectedCohort"
              input-type="select"
              placeholder="Select your cohort"
              label="Cohort (Optional)"
              :required="false"
              :options="
                [{ cohort_id: undefined, name: 'No cohort' }, ...cohortList].map((coh) => {
                  return {
                    name: coh.name,
                    value: coh.cohort_id
                  }
                })
              "
            />
          </div>
        </div>
      </div>
      <div class="mt-5 flex justify-end gap-3">
        <CustomButton
          button-type="admin-secondary"
          :disabled="createBulkUsersLoading"
          @click="() => (bulkCreateModal = false)"
        >
          Close
        </CustomButton>
        <CustomButton
          :loading="createBulkUsersLoading || isLoadingUsers"
          :disabled="
            !uploadedEmails.length ||
            createdList.length > 1 ||
            existedList.length > 1 ||
            failedCreatedList.length > 1
          "
          @click="nextBulkCreate"
        >
          Upload
        </CustomButton>
      </div>
    </div>
  </CustomModal>

  <!-- Update User Modal -->
  <CustomModal v-model="modalOpen">
    <div class="flex flex-col">
      <div class="mb-3 flex items-center justify-between">
        <h3 class="text-xl">
          {{ selectedUser ? 'Update User' : 'Add User' }}
        </h3>
        <a
          v-if="!selectedUser"
          class="order-2 mb-0 cursor-pointer whitespace-nowrap pb-0 text-base text-sc-grey-700 underline decoration-sc-grey-500 md:order-1"
          @click="
            () => {
              modalOpen = false
              bulkCreateModal = true
            }
          "
        >
          Bulk Upload
        </a>
        <CustomButton
          v-if="selectedUser && authStore.whoAmI?.user_id !== selectedUser?.user_id"
          button-type="admin-secondary"
          :start-icon="TrashIcon"
          @click="removeUser(selectedUser)"
        />
      </div>
      <InputEmail
        v-model="newUserEmail"
        :disabled="!!selectedUser"
        label="Email address"
        required
      />
      <CustomInput
        v-model="newUserSelectedRole"
        :class="selectedUser?.user_id && authStore.isSuperAdminUser ? '' : '!my-0'"
        input-type="select"
        placeholder="Select a role"
        label="Role"
        :options="mappedRoles"
      />
      <div
        v-if="selectedUser?.user_id && authStore.isSuperAdminUser"
        class="flex items-center gap-5"
      >
        <CustomInput
          :value="selectedUser?.user_id"
          class="!my-0"
          :read-only="true"
          label="User ID"
        />
        <ClipboardDocumentIcon
          class="h-5 w-5 cursor-pointer text-sc-grey-600"
          @click="copyLink(selectedUser?.user_id, 'User id successfully copied to clipboard')"
        />
      </div>
      <div
        class="mt-5 flex flex-col items-center justify-center gap-3 md:flex-row md:justify-between"
      >
        <a
          v-if="selectedUser"
          class="order-2 mb-0 cursor-pointer whitespace-nowrap pb-0 text-base text-sc-grey-700 underline decoration-sc-grey-500 md:order-1"
          @click="viewUsersHistory"
          >View user's history</a
        >
        <div class="order-1 flex w-full justify-center gap-3 md:order-2 md:justify-end">
          <CustomButton button-type="admin-secondary" @click="() => (modalOpen = false)">
            Cancel
          </CustomButton>
          <CustomButton
            :loading="createNewPasswordlessUserLoading || isLoadingUsers"
            :disabled="
              !isNewEmailValid ||
              !newUserEmail ||
              !newUserSelectedRole ||
              (selectedUser ? newUserSelectedRole === selectedUser.user_role : false)
            "
            @click="saveUser"
          >
            {{ selectedUser ? 'Save changes' : 'Add' }}
          </CustomButton>
        </div>
      </div>
    </div>
  </CustomModal>
</template>
