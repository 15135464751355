<script setup lang="ts">
import { computed, reactive } from 'vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { onBeforeMount } from 'vue'
import Api from '@/open-api'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import { useAssignmentStore } from '@/stores/assignment'
import dayjs from 'dayjs'
import CustomItemList from '@/components/CustomItemList.vue'
import CustomCollapse from '@/components/utils/CustomCollapse.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  XCircleIcon
} from '@heroicons/vue/24/outline'
import { useRouter, useRoute } from 'vue-router'
import type { StudentProgressData, StudentProgressTaskAttempt } from '@/types/assignment'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants'

definePage({
  name: 'Cohort Assignment Task Insights',
  meta: {
    permissionLevel: 'Educator'
  }
})

const notificationStore = useNotificationStore()
const assignmentStore = useAssignmentStore()
const router = useRouter()
const route = useRoute<'Cohort Assignment Task Insights'>()
// ==================================================
// statistics
// ==================================================

type StatisticData = {
  rubric: string
  highest_score: number
  passing_score: number
  attempts: number
  average_score: string
  students_failed: number
  students_passed: number
}

const statistics = reactive({
  loading: false,
  list: undefined as StatisticData | undefined
})

const setStatistics = () => {
  statistics.loading = true
  Api.Assignment.getConversationTaskStatisticsEndpoint(
    assignmentStore.currentCohortTask?.task_id as string
  )
    .then((res) => {
      statistics.list = {
        rubric: res.rubric_name,
        highest_score: res.max_grade,
        passing_score: res.pass_grade,
        attempts: res.student_attempts,
        average_score: `${res.students_average_grade}%`,
        students_failed: res.students_total_failed,
        students_passed: res.students_total_passed
      }
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })

      router.push({ name: 'Cohorts' })
    })
    .finally(() => {
      statistics.loading = false
    })
}

onBeforeMount(() => {
  setStatistics()
})

const statisticsDataArray = computed(() => {
  if (!statistics.list) {
    return []
  }

  const fieldNames = {
    rubric: 'Rubric',
    highest_score: 'Max. Grade',
    passing_score: 'Pass Grade',
    attempts: 'Student Attempts',
    average_score: 'Avg. Attempt Grade',
    students_failed: 'Failed Attempts',
    students_passed: 'Passed Attempts'
  }

  return (Object.keys(statistics.list) as (keyof StatisticData)[]).map((key) => {
    return {
      name: fieldNames[key] || '',
      value: statistics.list?.[key]
    }
  })
})

// ==================================================
// Student progress
// ==================================================

const studentProgress = reactive({
  loading: false,
  list: [] as StudentProgressData[],
  pagination: { items_per_page: 6, page: 1, total: 1 },
  search: EMPTY_STRING_SEARCH_ALL,
  listHeaders: [
    {
      name: 'Status',
      value: 'custom'
    },
    {
      name: 'Student Email',
      value: 'email'
    },
    {
      name: 'Attempts',
      value: 'custom-attempts'
    },
    {
      name: 'Grade',
      value: 'custom-grade'
    }
  ]
})

const setStudentProgressList = (search: string = EMPTY_STRING_SEARCH_ALL) => {
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }
  studentProgress.loading = true

  studentProgress.search = search
  Api.Assignment.listStudentProgressEndpoint(
    assignmentStore.currentCohortTask.task_id,
    studentProgress.pagination.items_per_page,
    studentProgress.pagination.page,
    search
  )
    .then((res) => {
      const { student_progress, pagination } = res

      studentProgress.pagination.page = pagination.current_page
      studentProgress.pagination.total = pagination.total_pages || 1

      studentProgress.list = student_progress.map((sp) => {
        return {
          status:
            sp.score === null || sp.maximum_score === null
              ? 'Pending'
              : sp.satisfactory
                ? 'Passed'
                : 'Failed',
          email: sp.student_email,
          numberOfAttempts: sp.number_of_attempts,
          maxAttempts: sp.max_attempts,
          // It would display as n/a if either score or maximum_score is null
          score: sp.score === null || sp.maximum_score === null ? 'n' : `${sp.score}`,
          maximumScore:
            sp.score === null || sp.maximum_score === null ? 'a' : `${sp.maximum_score}`,
          conversationTaskAttempts: sp.conversation_task_attempts.map((attempt) => {
            return {
              studentEmail: sp.student_email,
              taskAttemptId: attempt.conversation_task_attempt_id,
              conversationId: attempt.conversation_id,
              status:
                attempt.score === null || attempt.maximum_score === null
                  ? 'Pending'
                  : attempt.satisfactory
                    ? 'Passed'
                    : 'Failed',
              date: dayjs(attempt.created_at).format('D MMM, YYYY h:mmA'),
              isOverdue: attempt.is_overdue,
              // It would display as n/a if either score or maximum_score is null
              score:
                attempt.score === null || attempt.maximum_score === null ? 'n' : `${attempt.score}`,
              maximumScore:
                attempt.score === null || attempt.maximum_score === null
                  ? 'a'
                  : `${attempt.maximum_score}`,
              dismissed: attempt.dismissed
            }
          }),
          collapseOpen: false
        }
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })

      router.push({ name: 'Cohorts' })
    })
    .finally(() => {
      studentProgress.loading = false
    })
}

const statusButton = (status: string) => {
  const style: { buttonStyle?: string; iconStyle?: string; icon?: any } = {}
  let color = 'sc-grey'
  style.icon = EllipsisHorizontalCircleIcon

  if (status === 'Passed') {
    color = 'green'
    style.icon = CheckCircleIcon
  } else if (status === 'Failed') {
    color = 'red'
    style.icon = XCircleIcon
  }

  style.buttonStyle = `bg-${color}-100 border-${color}-500`
  style.iconStyle = `text-${color}-500`

  return style
}

const dismissAttemptModal = reactive({
  modalStatus: false,
  loading: false,
  taskAttempt: undefined as StudentProgressTaskAttempt | undefined
})

const dismissAttempt = (attempt: StudentProgressTaskAttempt) => {
  dismissAttemptModal.modalStatus = true
  dismissAttemptModal.taskAttempt = attempt
}

const confirmDismissAttempt = () => {
  if (dismissAttemptModal.taskAttempt) {
    dismissAttemptModal.loading = true
    Api.Assignment.dismissConversationTaskAttemptEndpoint({
      conversation_task_attempt_id: dismissAttemptModal.taskAttempt.taskAttemptId
    })
      .then(async () => {
        setStatistics()
        setStudentProgressList()

        dismissAttemptModal.loading = false
        dismissAttemptModal.modalStatus = false
        dismissAttemptModal.taskAttempt = undefined
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
        dismissAttemptModal.loading = false
      })
  }
}

const viewAttempt = (attempt: StudentProgressTaskAttempt) => {
  assignmentStore.setCurrentTaskAttempt(attempt)
  const { conversationId } = attempt
  router.push({
    name: 'Cohort Assignment Task Attempt Transcript',
    params: { ...route.params, conversationId }
  })
}

const changePage = (page: number) => {
  studentProgress.pagination.page = page
  setStudentProgressList(studentProgress.search)
}

onBeforeMount(() => {
  setStudentProgressList()
})

// since we specify the headers and details separately,
// we should define them both from the same variable
// to keep them in sync
const colSpan = (n: number): string => `col-span-${n}`
const colSpanStatus = colSpan(2)
const colSpanEmail = colSpan(5)
const colSpanGrade = colSpan(2)
const colSpanAttempts = colSpan(3)
</script>

<template>
  <div class="flex w-[80%] flex-col gap-5">
    <template v-if="!statistics.loading">
      <div class="grid grid-cols-4 gap-5">
        <div
          v-for="(stat, statIndex) in statisticsDataArray"
          :key="stat.name"
          :class="['flex flex-col truncate', { 'col-span-2': statIndex === 2 }]"
        >
          <small class="text-sc-grey-700">{{ stat.name }}</small>
          <p class="truncate text-lg">
            {{ stat.value }}
          </p>
        </div>
      </div>
      <div class="h-px w-full bg-sc-grey-300" />
      <CustomItemList
        class="[&_.item]:gap-y-3"
        title="Learner Progress"
        :has-header="true"
        :pagination="studentProgress.pagination"
        :list-headers="studentProgress.listHeaders"
        :list-data="studentProgress.list"
        :loading="studentProgress.loading"
        :search="studentProgress.search"
        @on-search="(search: string) => setStudentProgressList(search)"
        @on-change-page="(page: number) => changePage(page)"
      >
        <template #item="{ data }: { data: StudentProgressData }">
          <CustomCollapse
            :is-open="data.collapseOpen"
            :class="['rounded-lg border [&_.collapse-title]:px-4 [&_.collapse-title]:py-2.5']"
            @change="data.collapseOpen = !data.collapseOpen"
          >
            <template #title>
              <div class="grid grid-cols-12 items-center gap-x-5">
                <CustomButton
                  :custom-style="`border ${
                    statusButton(data.status)?.buttonStyle
                  } text-sc-grey-700 text-xs`"
                  :can-select="false"
                  button-size="xs"
                  :class="colSpanStatus"
                >
                  {{ data.status }}
                </CustomButton>
                <div
                  :class="`${colSpanEmail} overflow-hidden text-ellipsis whitespace-nowrap text-sm`"
                >
                  {{ data.email }}
                </div>
                <div :class="`${colSpanGrade} text-sm`">
                  <span class="text-sc-grey-700">Grade:</span> {{ data.score }}/{{
                    data.maximumScore
                  }}
                </div>
                <div :class="`${colSpanAttempts} text-sm`">
                  {{ data.numberOfAttempts }} attempts
                </div>
              </div>
            </template>
            <div class="border-t bg-sc-grey-50 px-4">
              <div
                v-for="(attempt, attemptIndex) in data.conversationTaskAttempts"
                :key="attemptIndex"
                class="grid grid-cols-12 items-center gap-x-5 py-2.5"
              >
                <CustomButton
                  :custom-style="`border ${
                    statusButton(attempt.status)?.buttonStyle
                  } text-sc-grey-700 text-xs`"
                  :can-select="false"
                  button-size="xs"
                  :class="`${colSpanStatus}`"
                >
                  {{ attempt.status }}
                </CustomButton>
                <div :class="`${colSpanEmail} text-sm text-sc-grey-900`">
                  {{ attempt.date
                  }}<span v-if="attempt.isOverdue">
                    - <span class="text-red-600">Overdue</span></span
                  >
                </div>
                <div :class="`${colSpanGrade} text-sm text-sc-grey-900`">
                  {{ attempt.score }}/{{ attempt.maximumScore }}
                </div>
                <div :class="`${colSpanAttempts} -ml-4 text-sm text-sc-grey-900`">
                  <div class="flew-row flex justify-between">
                    <div
                      class="cursor-pointer text-gray-500 hover:underline"
                      @click="() => viewAttempt(attempt)"
                    >
                      View attempt
                    </div>
                    <div
                      v-if="attempt.dismissed === false"
                      class="cursor-pointer text-gray-500 hover:underline"
                      @click="() => dismissAttempt(attempt)"
                    >
                      Dismiss attempt
                    </div>
                    <div v-else class="text-gray-500">Dismissed</div>
                  </div>
                </div>
              </div>
            </div>
          </CustomCollapse>
        </template>
      </CustomItemList>
    </template>
    <AppLoadingSpinner v-else class="py-20" loading />
    <!--------------------------- Overdue modal --------------------------->
    <CustomDeleteModal
      :modal-status="dismissAttemptModal.modalStatus"
      :loading="dismissAttemptModal.loading"
      delete-button-text="Yes, I'm sure"
      title="Dismiss Attempt"
      message="Are you sure you would like to dismiss this attempt? This action cannot be undone."
      @confirm="confirmDismissAttempt"
      @cancel="dismissAttemptModal.modalStatus = false"
    />
  </div>
</template>
