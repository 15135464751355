<script setup lang="ts">
import { ref, reactive, computed } from 'vue'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import CustomModal from '@/components/utils/CustomModal.vue'
import CustomList from '@/components/CustomList.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import type { OrganizationCredit, OrganizationDebit } from '@/open-api/generated'
import { useAuthStore } from '@/stores/auth'
import Api from '@/open-api'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'

import InputDate from '@/components/input/InputDate.vue'
import InputNumber from '@/components/input/InputNumber.vue'

dayjs.extend(isSameOrBefore)
dayjs.extend(utc)

definePage({
  name: 'Management Billing',
  meta: {
    permissionLevel: 'InstitutionAdmin'
  }
})

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const authStore = useAuthStore()

// ==================================================
// Credits Added
// ==================================================
const loadingCreditAddHistory = ref(false)
const creditsAddedListPagination = reactive({ items_per_page: 15, page: 1, total: 0 })
const creditsAddedHistoryRaw = ref<OrganizationCredit[]>([])
const creditsAddedHistory = computed(() =>
  creditsAddedHistoryRaw.value.filter((el) => (authStore.isSuperAdminUser ? true : !el.is_void))
)

const creditsAddedHeaders = computed(() => [
  {
    name: 'Begins At',
    value: 'begins_at'
  },
  {
    name: 'Expires At',
    value: 'expires_at'
  },
  {
    name: 'Initial Amount',
    value: 'initial_amount'
  },
  {
    name: 'Remaining Amount',
    value: 'remaining_amount'
  },
  ...(authStore.isSuperAdminUser
    ? [
        {
          name: 'Voided',
          value: 'is_void'
        },
        {
          name: 'Void Credits',
          value: 'action-delete'
        }
      ]
    : [])
])

async function fetchOrganizationCredits() {
  loadingCreditAddHistory.value = true
  Api.Billing.listOrganizationCreditsEndpoint(
    authStore.organizationId!,
    creditsAddedListPagination.items_per_page,
    creditsAddedListPagination.page
  )
    .then((res) => {
      creditsAddedHistoryRaw.value = res.credits.map((credit) => ({
        ...credit,
        begins_at: formatDate(credit.begins_at),
        expires_at: formatDate(credit.expires_at)
      }))

      creditsAddedListPagination.total = res.pagination.total_pages
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      loadingCreditAddHistory.value = false
    })
}
fetchOrganizationCredits()

// Given an ISO 8601 timestamp, returns a string in the format `YYYY-MM-DD`.
function formatDate(formatMe: string) {
  return dayjs(formatMe).format('YYYY-MM-DD')
}

// ==================================================
// Credits Usage
// ==================================================
const loadingCreditUsageHistory = ref(false)
const creditsUsedListPagination = reactive({ items_per_page: 15, page: 1, total: 0 })
let creditsUsedHistory = reactive<OrganizationDebit[]>([])
const creditsUsedHeaders = [
  {
    name: 'Consumed By Email',
    value: 'consumed_by_email'
  },
  {
    name: 'Consumed At',
    value: 'consumed_at'
  }
]
async function fetchOrganizationCreditsUsage() {
  loadingCreditUsageHistory.value = true
  Api.Billing.listOrganizationDebitsEndpoint(
    authStore.organizationId!,
    creditsUsedListPagination.items_per_page,
    creditsUsedListPagination.page
  )
    .then((res) => {
      creditsUsedHistory = res.debits.map((debit) => ({
        ...debit,
        consumed_at: formatDate(debit.consumed_at)
      }))
      creditsUsedListPagination.total = res.pagination.total_pages || 1
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      loadingCreditUsageHistory.value = false
    })
}
fetchOrganizationCreditsUsage()

// ==================================================
// Add Credits Modal
// ==================================================
const modalOpen = ref(false)
const addCreditsLoading = ref(false)
const initialAmount = ref(1)
const beginsAt = ref('')
const expiresAt = ref('')

const addCredits = async () => {
  addCreditsLoading.value = true
  Api.Billing.addCreditsEndpoint({
    organization_id: authStore.organizationId!,
    amount: initialAmount.value,
    expires_at: dayjs(expiresAt.value).utc().format(),
    begins_at: dayjs(beginsAt.value).utc().format()
  })
    .then(() => {
      fetchOrganizationCredits()
      modalOpen.value = false
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      addCreditsLoading.value = false
    })
}

const validAddCredits = computed(() => {
  return (
    !initialAmount.value ||
    initialAmount.value <= 0 ||
    !expiresAt.value ||
    dayjs(expiresAt.value).isSameOrBefore(beginsAt.value)
  )
})

const voidCredit = (creditId: string) => {
  Api.Billing.voidCreditsEndpoint({
    credit_id: creditId
  })
    .then(() => fetchOrganizationCredits())
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
}

const openCreditsModal = () => {
  modalOpen.value = true
  beginsAt.value = dayjs().format('YYYY-MM-DD')
}

const closeModal = () => {
  initialAmount.value = 1
  beginsAt.value = ''
  expiresAt.value = ''
}
</script>

<template>
  <div class="flex h-full flex-col overflow-hidden px-5">
    <CustomList
      class="flex-1 overflow-hidden [&_.list-title]:text-xl"
      title="Credits Added"
      :create-button="authStore.isSuperAdminUser ? 'Add Credits' : ''"
      :list-headers="creditsAddedHeaders"
      :pagination="creditsAddedListPagination"
      :list-data="creditsAddedHistory"
      :has-search="false"
      :has-header="true"
      :has-list-options="false"
      :loading="loadingCreditAddHistory"
      @on-create="openCreditsModal"
      @on-delete="(e) => voidCredit(e.id)"
      @on-change-page="
        (page: number) => {
          creditsAddedListPagination.page = page
          fetchOrganizationCredits()
        }
      "
    />

    <CustomList
      class="flex-1 overflow-hidden [&_.list-title]:text-xl"
      title="Credits used"
      :has-header="true"
      :has-search="false"
      :list-headers="creditsUsedHeaders"
      :pagination="creditsUsedListPagination"
      :list-data="creditsUsedHistory"
      :has-list-options="false"
      :loading="loadingCreditUsageHistory"
      @on-change-page="
        (page: number) => {
          creditsUsedListPagination.page = page
          fetchOrganizationCreditsUsage()
        }
      "
    />
  </div>

  <CustomModal v-model="modalOpen" @on-close="closeModal">
    <div class="flex flex-col">
      <h3 class="mb-3 text-xl">Add Credits</h3>
      <InputNumber v-model="initialAmount" label="Initial Amount" required />

      <div class="grid grid-cols-2 gap-3">
        <InputDate v-model="beginsAt" label="Begins At" :required="false" />
        <InputDate v-model="expiresAt" label="Expires At" required />
      </div>
      <div class="mt-5 flex gap-3 self-end">
        <CustomButton button-type="admin-secondary" @click="() => (modalOpen = false)">
          Cancel
        </CustomButton>
        <CustomButton :loading="addCreditsLoading" :disabled="validAddCredits" @click="addCredits">
          Add
        </CustomButton>
      </div>
    </div>
  </CustomModal>
</template>
