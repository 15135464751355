<script setup lang="ts">
import AppBrainIcon from '@/components/AppBrainIcon.vue'

definePage({
  name: 'Unsupported Browser',
  meta: {
    permissionLevel: 'ANY'
  }
})

const browsers = [
  {
    name: 'chrome',
    link: 'https://www.google.com/intl/en_au/chrome/dr/download/?brand=YTUH&gclid=CjwKCAjwyY6pBhA9EiwAMzmfwUW2SmAywCCkk-L92SPTkre0IUDW76Oyklw02VFM3CQ_FPEFkSH_TxoCnwkQAvD_BwE&gclsrc=aw.ds'
  },
  { name: 'firefox', link: 'https://www.mozilla.org/en-US/firefox/new/' },
  { name: 'safari', link: 'https://support.apple.com/en_AU/downloads/safari' },
  { name: 'edge', link: 'https://www.microsoft.com/en-us/edge/download?form=MA13FJ' }
]
</script>

<template>
  <div class="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-white">
    <div class="py-16">
      <div class="flex flex-col items-center gap-5 text-center">
        <AppBrainIcon class="h-64" />
        <h1 class="text-2xl font-medium">Browser not supported.</h1>
        <h2 class="mb-0 text-center text-lg font-normal text-sc-grey-700">
          Your current browser is not supported. Please make sure
          <br />
          your browser is fully updated and you are using one of our
          <br />
          supported browsers listed below.
        </h2>
        <div class="mt-5 flex gap-5">
          <a v-for="browser in browsers" :key="browser.name" :href="browser.link" target="_blank">
            <img
              :src="`/assets/browser-icons/${browser.name}.svg`"
              :alt="`Browser logo for ${browser.name}`"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
