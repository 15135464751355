<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import type { GetAssignmentInstanceStudentTask } from '@/open-api/generated'
import useGetMyAssignmentInstance from '@/composables/api/queries/useGetMyAssignmentInstance'
import useEnterTaskAttempt from '@/composables/api/useEnterTaskAttempt'
import useScrollToTopOnChange from '@/composables/useScrollToTopOnChange'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import { PlayIcon, LoopIcon } from '@radix-icons/vue'
import Activity from '@/components/modern/activities-tasks-view'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { isPrelearning } from '@/utils/task-types'

definePage({
  name: 'Modern - Learner Activity View',
  meta: {
    permissionLevel: 'Student',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Activity')

const router = useRouter()
const route = useRoute('Modern - Learner Activity View')
const assignmentInstanceId = computed(() => route.params.activityId)

const taskId = ref<string | null>(null)
const selectTask = (id: string) => {
  taskId.value = id
}
const deselectTask = () => {
  taskId.value = null
}

const notificationStore = useNotificationStore()
const { assignmentInstance } = useGetMyAssignmentInstance({
  assignmentInstanceId,
  notificationStore
})
const task = computed(
  () => assignmentInstance.value?.tasks.find((task) => task.task_id === taskId.value) ?? null
)
const attempts = computed(() => task.value?.attempts ?? [])

const hasAttempts = computed(() => attempts.value.length > 0)
const hasIncompleteAttempt = computed(() => attempts.value.some((a) => !a.completed_at))

const canStartTask = computed((): boolean => {
  if (!task.value) {
    return false
  } else if (hasIncompleteAttempt.value) {
    return true
  } else {
    return attempts.value.length < task.value.max_attempts
  }
})
const canReviewTask = computed(
  (): boolean => attempts.value.length > 0 && isPrelearning(task.value)
)

const enterTaskAttempt = useEnterTaskAttempt({
  assignmentInstanceId,
  notificationStore
})
const startTaskLoading = ref<boolean>(false)
const startTask = async (task: GetAssignmentInstanceStudentTask) => {
  startTaskLoading.value = true
  const taskAttemptId = await enterTaskAttempt(task)
  if (taskAttemptId) {
    router
      .push({
        name: 'Modern - Attempt Task or Review Task Attempt',
        params: {
          activityId: assignmentInstanceId.value,
          taskId: task.task_id,
          taskAttemptId
        }
      })
      .then(() => selectTask(task.task_id))
  } else {
    startTaskLoading.value = false
  }
}

useScrollToTopOnChange(taskId)
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton v-if="task" name="activity" @click="deselectTask" />
      <BackButton v-else :to="{ name: 'Modern - My Activities' }" name="my activities" />
    </template>
    <template v-if="task" #right>
      <Button
        v-if="canStartTask"
        variant="default"
        size="xs"
        class="w-min"
        :disabled="startTaskLoading"
        @click="startTask(task)"
      >
        <PlayIcon class="mr-2 size-4" />
        <span v-if="hasIncompleteAttempt">Continue Task</span>
        <span v-else-if="hasAttempts">Retry Task</span>
        <span v-else>Start Task</span>
      </Button>
      <Button v-else-if="canReviewTask" variant="outline" size="xs" class="w-min" as-child>
        <RouterLink
          :to="{
            name: 'Modern - Attempt Task or Review Task Attempt',
            params: {
              activityId: assignmentInstanceId,
              taskId: task.task_id,
              taskAttemptId: attempts[0].task_attempt_id
            }
          }"
        >
          <LoopIcon class="mr-2 size-4" />
          <span>Review Task</span>
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <Activity
    v-if="assignmentInstance"
    :activity="assignmentInstance"
    :task="task"
    :attempts="attempts"
    :start-task="startTask"
    @select-task="selectTask"
  />
  <AppLoadingSpinner v-else loading center-screen />
</template>
