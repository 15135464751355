<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { MultiTable } from '@/components/modern/ui/data-table'
import { useColumns, useGroups } from '@/components/modern/tables/cohort-activity-students'
import { Button } from '@/components/modern/ui/button'
import { PlayIcon, DownloadIcon } from '@radix-icons/vue'
import { type AssignmentInstanceStudentProgress } from '@/open-api/generated'
import useCombineQueries from '@/composables/api/useCombineQueries'
import useGetAssignmentInstanceStudentInsight from '@/composables/api/queries/useGetAssignmentInstanceStudentInsight'
import useGetAssignmentInstanceStudentInsights from '@/composables/api/queries/useGetAssignmentInstanceStudentInsights'
import useGetCohortAssignmentInsights from '@/composables/api/queries/useGetCohortAssignmentInsights'
import useGetCohortV2 from '@/composables/api/queries/useGetCohortV2'
import useDismissAllAttempts from '@/composables/api/mutations/useDismissAllAttempts'
// import useExemptStudentFromActivity from '@/composables/api/mutations/useExemptStudentFromActivity'
// import { CommandState } from '@/composables/api/mutations/types'
import { exportInsights, extractInsights } from '@/utils/export'
import { findUserId } from '@/utils/preview-mock-instance'

definePage({
  name: 'Modern Cohorts - Activity Instance',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Activity Learners')

const route = useRoute('Modern Cohorts - Activity Instance')
const cohortId = computed(() => route.params.cohortId)
const assignmentInstanceId = computed(() => route.params.assignmentInstanceId)

const notificationStore = useNotificationStore()

const { track, isLoading, refetch } = useCombineQueries()
const { cohort } = track(useGetCohortV2({ cohortId, notificationStore }))
const { activityInstances } = track(useGetCohortAssignmentInsights({ cohortId, notificationStore }))
const { insights } = track(
  useGetAssignmentInstanceStudentInsights({ assignmentInstanceId, notificationStore })
)
const instance = computed(() =>
  activityInstances.value.find((i) => i.assignment_instance_id === assignmentInstanceId.value)
)

// You can only preview a cohort's assignment instance if it has at least one student in it.
const hasStudents = computed(() => findUserId(insights))
const notifyNeedStudents = () => {
  notificationStore.addWARNING('You must add at least one learner to a cohort to view the preview.')
}
const tasksTotal = computed(() => insights.value?.tasks_total ?? 0)

const exportCSV = () => {
  if (isLoading.value) {
    return
  } else if (insights.value && instance.value && cohort.value) {
    exportInsights(extractInsights(insights.value, instance.value, cohort.value))
  } else {
    notificationStore.addDANGER('CSV export failed')
    refetch()
  }
}

const userId = ref<string | null>(null)
const { tasks, isLoading: taskAttemptsLoading } = useGetAssignmentInstanceStudentInsight({
  assignmentInstanceId,
  userId,
  notificationStore
})
const dismissAllAttempts = useDismissAllAttempts({
  tasks,
  userId,
  notificationStore
})
const requestDismissAllAttempts = ({ user }: AssignmentInstanceStudentProgress) =>
  (userId.value = user.id)
const confirmDismissAllAttempts = () => {
  const { execute, reset } = dismissAllAttempts
  return execute().finally(reset).finally(refetch)
}

const data = computed(() => insights.value?.status.flatMap((status) => status.payload) ?? [])
const columns = useColumns({
  cohortId,
  assignmentInstanceId,
  tasksTotal,
  requestDismissAllAttempts,
  taskAttemptsLoading,
  confirmDismissAllAttempts
})
const groups = useGroups(insights)
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Activities List', params: { cohortId } }"
        name="cohort"
      />
    </template>
    <template v-if="!isLoading" #right>
      <Button v-if="hasStudents" variant="outline" size="xs" as-child>
        <RouterLink
          :to="{
            name: 'Modern Cohorts - Preview Activity Instance',
            params: { cohortId, assignmentInstanceId }
          }"
        >
          <PlayIcon class="mr-2 size-4" />
          Preview Activity
        </RouterLink>
      </Button>
      <Button v-else variant="outline" size="xs" @click="notifyNeedStudents">
        <PlayIcon class="mr-2 size-4" />
        Preview Activity
      </Button>
      <Button variant="default" size="xs" @click="exportCSV">
        <DownloadIcon class="mr-2 size-4" />
        <span>Export</span>
      </Button>
    </template>
  </TopLine>
  <MultiTable :data="data" :columns="columns" :groups="groups" :loading="isLoading" />
</template>
