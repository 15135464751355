export enum Role {
  ANY,
  Student,
  Educator,
  InstitutionAdmin,
  Staff,
  SuperAdmin
}

export type RoleString = keyof typeof Role
