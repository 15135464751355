import type { WhoAmIOutput } from '../models/WhoAmIOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';



export class UserService {

	/**
	 * WhoAmI
	 * Retrieve information about the logged-in user.
	 * @returns WhoAmIOutput Logged-in user retrieved successfully.
	 * @throws ApiError
	 */
	public static whoAmIEndpoint(): CancelablePromise<WhoAmIOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/user/WhoAmI',
			errors: {
				401: `Not logged in.`,
			},
		});
	}

}