export interface Notification {
  id?: string
  timeoutVal?: number
  title?: string
  subtitle?: string
  status?: NotificationStatus
}

export enum NotificationStatus {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  DANGER = 'DANGER'
}
