import type { SubmitConversationLatencyInput } from '../models/SubmitConversationLatencyInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';



export class AnalyticsService {

	/**
	 * SubmitConversationLatency
	 * @param requestBody 
	 * @returns any SubmitConversationLatency.
	 * @throws ApiError
	 */
	public static submitConversationLatencyEndpoint(
requestBody: SubmitConversationLatencyInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/analytics/SubmitConversationLatency',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

}