<script setup lang="ts">
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon
} from '@heroicons/vue/24/outline'
import { toRefs, computed } from 'vue'

type Props = {
  notification: {
    timeoutVal?: number
    title?: string
    subtitle?: string
    status?: NotificationStatus
  }
}

const props = withDefaults(defineProps<Props>(), {
  notification: () => ({
    timeoutVal: 3000,
    title: 'Notification',
    subtitle: 'Something happened!',
    status: NotificationStatus.INFO
  })
})

const toastSpecs = computed(() => {
  if (props.notification.status === NotificationStatus.SUCCESS) {
    return {
      icon: CheckCircleIcon,
      toastStyle: 'border-green-400 text-green-400 bg-green-50',
      title: props.notification.title || 'Success!'
    }
  } else if (props.notification.status === NotificationStatus.WARNING) {
    return {
      icon: ExclamationTriangleIcon,
      toastStyle: 'border-yellow-400 text-yellow-400 bg-yellow-50',
      title: props.notification.title || 'Warning...'
    }
  } else if (props.notification.status === NotificationStatus.DANGER) {
    return {
      icon: XCircleIcon,
      toastStyle: 'border-red-600 text-red-600 bg-red-50',
      title: props.notification.title || 'Something went wrong!'
    }
  } else {
    return {
      icon: InformationCircleIcon,
      toastStyle: 'bg-white',
      title: props.notification.title || 'Info'
    }
  }
})

const { notification } = toRefs(props)

const notificationStore = useNotificationStore()

const removeNotification = () => {
  notificationStore.removeNotification(props.notification)
}
</script>

<template>
  <div
    v-show="true"
    :class="[
      'pointer-events-auto z-[100] flex w-full max-w-sm items-center gap-2 overflow-hidden rounded-mlg border p-4',
      toastSpecs.toastStyle
    ]"
    @click="removeNotification"
  >
    <component :is="toastSpecs.icon" class="h-7 min-w-[1.75rem]" />
    <div class="flex flex-col justify-center">
      <p class="font-medium">
        {{ toastSpecs.title }}
      </p>
      <p class="text-sm text-sc-grey-700">
        {{ notification.subtitle }}
      </p>
    </div>
  </div>
</template>
