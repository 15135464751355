<script setup lang="ts">
import { computed, watch, ref, useTemplateRef } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { validateEmail, getWorkbookFromFile, findEmailsInWorkbook } from '@/utils'
import { UserRoleApiEnum, type SearchOrganizationUser } from '@/open-api/generated'
import useSearchOrganizationUsers from '@/composables/api/queries/useSearchOrganizationUsers'
import useGetCohortStudents from '@/composables/api/queries/useGetCohortStudents'
import useCheckEmailExistsInOrganization from '@/composables/api/queries/useCheckEmailExistsInOrganization'
import useCreateStudentUser from '@/composables/api/mutations/useCreateStudentUser'
import useAddStudentToCohort from '@/composables/api/mutations/useAddStudentToCohort'
import useCohortCandidates, { type Candidate } from '@/composables/useCohortCandidates'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { type Table } from '@tanstack/vue-table'
import { ScrollableTable, DataTable } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/batch-emails'
import { Label } from '@/components/modern/ui/label'
import { Button } from '@/components/modern/ui/button'
import { SearchSelect } from '@/components/modern/ui/search-select'
import { Input } from '@/components/modern/ui/input'
import { Switch } from '@/components/modern/ui/switch'
import { PlusIcon } from '@radix-icons/vue'
import { SUPPORTED_SPREADSHEET_FORMATS } from '@/constants'

definePage({
  name: 'Modern Cohorts - Add Learners to Cohort',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Add Learners to Cohort')

const route = useRoute('Modern Cohorts - Add Learners to Cohort')
const cohortId = computed(() => route.params.cohortId)

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

// Set up api calls
const { students, refetch } = useGetCohortStudents({ cohortId, notificationStore })
const checkEmailExists = useCheckEmailExistsInOrganization({ authStore })
const createUser = useCreateStudentUser({ authStore, notificationStore })
const addStudent = useAddStudentToCohort({ cohortId, notificationStore })

// Set up cohort candidate array
const { candidates, addCandidate, removeCandidate, updateCandidate, replaceCandidates } =
  useCohortCandidates(students)

// Handle spreadsheet uploads
const handleFile = async (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0]
  if (file) {
    const workbook = await getWorkbookFromFile(file)
    const emails = findEmailsInWorkbook(workbook)
    if (emails.length) {
      replaceCandidates(emails)
      await checkCandidatesExist()
    } else {
      notificationStore.addWARNING('No email addresses found in spreadsheet.')
    }
  }
}

// Set up SearchSelect input
const query = ref<string>('')
const { users, isLoading } = useSearchOrganizationUsers({
  query,
  authStore,
  notificationStore
})
const addSelectedUser = async (user: SearchOrganizationUser) => {
  addCandidate(user.email, user.id)
}

// Set up manual email input
const manualEmail = ref<string>('')
// Push to emails array, as intended workflow is "refine uploaded CSV"
const addManualEmail = async () => {
  const email = validateEmail(manualEmail.value.trim())
  if (email) {
    addCandidate(email)
    manualEmail.value = ''
    await checkCandidatesExist()
  } else {
    notificationStore.addWARNING('Not recognized as a valid email address')
  }
}

// Whether to create new users if they're not found
// The `Candidate` type's `id` property has the type `string | false | null;`
// `string` represents a real user_id, e.g. User:dbbb2eaf-ed7b-4c7a-8f2c-5d918879726a
// `false` represents a candidate email that was added via CSV import / manual entry, and has not yet been checked for existence
// `null` represents a candidate email that was checked for existence, and we explicitly found no user with that email
const createIfNotFound = ref(false)

// Set up table
const columns = useColumns({ removeCandidate, createIfNotFound })

const table = useTemplateRef<Table<Candidate>>('table')

const shouldAddCandidate = (c: Candidate): boolean => {
  if (c.inCohort) {
    // Reject candidate; the user is already in the cohort
    return false
  } else if (createIfNotFound.value) {
    // Accept any email; it will become a valid account if it isn't already
    return true
  } else {
    // Reject candidate; we have already checked and found no user with this email
    return c.id !== null
  }
}

// Use DataTable selection state to dim out non-addable candidates
watch(
  [candidates, createIfNotFound],
  () =>
    table.value?.setRowSelection(
      Object.fromEntries(candidates.value.map((c, i) => [i, !shouldAddCandidate(c)]))
    ),
  // Update dimmed rows as individual loading states change
  { immediate: true, deep: true }
)

// Set up page actions / requests
const executing = ref<boolean>(false)

const checkCandidatesExist = async () => {
  if (executing.value) {
    return
  }
  executing.value = true

  const toCheck = candidates.value.filter((c) => c.id === false)
  while (toCheck.length) {
    const candidate = toCheck.shift()!

    try {
      candidate.loading = true

      candidate.id = await checkEmailExists(candidate.email)
      updateCandidate(candidate)
    } catch (err: unknown) {
      // ignore errors as this runs frequently
    } finally {
      candidate.loading = false
    }
  }

  executing.value = false
}

const addCandidatesToCohort = async () => {
  if (executing.value) {
    return
  }
  executing.value = true

  const toAdd = candidates.value.filter(shouldAddCandidate)
  while (toAdd.length) {
    const candidate = toAdd.shift()!

    try {
      candidate.loading = true

      if (candidate.id === false) {
        // We haven't checked if the user exists yet, let's do that
        candidate.id = await checkEmailExists(candidate.email)
      }
      if (candidate.id === null && createIfNotFound.value) {
        // Create a new user
        candidate.id = await createUser(candidate.email)
      }
      if (candidate.id) {
        // We have an id, we know the user exists, add them to the cohort
        candidate.inCohort = await addStudent(candidate.id)
      }
    } catch (err: any) {
      notificationStore.addDANGER(err?.body?.message || 'Error adding user to cohort.')
    } finally {
      candidate.loading = false
    }
  }
  refetch()
  executing.value = false
}

const countLearnersToBeAddedText = computed((): string => {
  const count = candidates.value.filter(shouldAddCandidate).length
  if (count === 0) {
    return 'No learners will be added.'
  } else if (count === 1) {
    return '1 learner will be added.'
  } else {
    return `${count} learners will be added.`
  }
})

const accountCreationWarningText = computed((): string => {
  if (createIfNotFound.value) {
    // Candidates with `id: false` may or may not exist in the organization,
    // we haven't checked yet - we can only offer a warning of potential risk
    if (candidates.value.some((c) => c.id === false)) {
      return 'New user accounts may be created in your organization.'
    }
    // Candidates with `id: null` are known to not exist in the organization,
    // so we can say how many will be created. This is an extra layer of safety:
    // users will see "30 accounts will be created" when they're expecting 0 and
    // realise they uploaded the wrong file.
    const count = candidates.value.filter((c) => c.id === null).length
    if (count) {
      return `New user accounts will be created for ${count} learners.`
    }
  }
  // Return a non-breaking space to preserve the line height of the element
  return '\xa0'
})
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Learners List', params: { cohortId } }"
        name="cohort"
      />
    </template>
  </TopLine>
  <div class="my-4 flex w-full flex-row justify-center px-4 lg:my-6 lg:px-6">
    <div class="w-full max-w-4xl space-y-4">
      <div class="space-y-2">
        <Label for="upload-users">Upload an Excel spreadsheet or CSV file of learner emails</Label>
        <Input
          id="upload-users"
          type="file"
          :accept="SUPPORTED_SPREADSHEET_FORMATS"
          class="hover:cursor-pointer"
          @change="handleFile"
        />
      </div>
      <div class="space-y-2">
        <Label for="search-users">Add learners by searching for a user in your organization </Label>
        <SearchSelect
          id="search-users"
          v-model:query="query"
          value-key="id"
          label-key="email"
          :data="
            users.filter((u) =>
              [UserRoleApiEnum.STUDENT, UserRoleApiEnum.EDUCATOR].includes(u.role)
            )
          "
          placeholder-label="user"
          :loading="isLoading"
          clear-on-select
          @select-element="addSelectedUser"
        />
      </div>
      <div class="space-y-2">
        <Label for="enter-email">Add learners by entering an email address manually </Label>
        <div class="flex w-full flex-row gap-2">
          <Input id="enter-email" v-model="manualEmail" @keyup.enter="addManualEmail" />
          <Button variant="outline" size="icon" class="aspect-square" @click="addManualEmail">
            <PlusIcon class="size-4" />
          </Button>
        </div>
      </div>
      <div class="space-y-2">
        <Label>Learners to be added</Label>
        <ScrollableTable>
          <DataTable ref="table" :data="candidates" :columns="columns" :loading="false" />
        </ScrollableTable>
      </div>
      <div class="flex flex-row items-center gap-3">
        <Switch
          id="create-if-not-found"
          :checked="createIfNotFound"
          @update:checked="createIfNotFound = $event"
        />
        <Label for="create-if-not-found">Create new accounts for new emails?</Label>
      </div>
      <p class="text-sm font-semibold text-sc-orange-600">
        {{ accountCreationWarningText }}
      </p>
      <div class="flex flex-row flex-nowrap items-center justify-start gap-4">
        <Button
          variant="default"
          size="xs"
          :disabled="executing"
          :class="['w-min', { 'animate-pulse': executing }]"
          @click="addCandidatesToCohort"
        >
          <PlusIcon class="mr-2 size-4" />
          <span>Add Learners</span>
        </Button>
        <span v-if="!executing" class="text-sm">{{ countLearnersToBeAddedText }}</span>
      </div>
    </div>
  </div>
</template>
