<script setup lang="ts">
import type {
  ItemTemplate,
  SectionTemplateOutput,
  SectionTemplateUpdate
} from '@/open-api/generated'
import type {
  RubricItemPayloadQuestion,
  RubricItemPayloadAction,
  RubricItemPayloadInvestigation
} from '@/types/api'
import { reactive, onBeforeMount, watch, nextTick, ref, computed } from 'vue'
import { NotificationStatus } from '@/types/notification'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import { useContentStore } from '@/stores/content'
import { PlusIcon, TrashIcon, ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/24/outline'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import CustomPopover, { type PopoverOption } from '@/components/utils/CustomPopover.vue'
import Api from '@/open-api'
import { deepCopy } from '@/lib/utils'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import InputNumber from '@/components/input/InputNumber.vue'

definePage({
  name: 'Section View',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const route = useRoute('Section View')
const contentStore = useContentStore()
const authStore = useAuthStore()
const isReadOnly = computed(() => {
  return (
    route.params.sectionTemplateId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingSection as SectionTemplateOutput)?.owning_organization_id
    )
  )
})

onBeforeRouteLeave((to, from, next) => {
  if (route.params.sectionTemplateId === 'new' || !cantSave.value) {
    const answer = window.confirm(
      'Warning: You have unsaved changes. \nNavigating away from this page will delete any unsaved changes.'
    )
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

// ==================================================
// Section Details
// ==================================================
const saveLoading = ref(false)
const currentEditingSection: Partial<SectionTemplateOutput> = reactive({})

const itemsErrorArr = computed((): { index: number; error: string }[] => {
  if (!currentEditingSection?.items?.length) {
    return []
  }
  return currentEditingSection.items.reduce(
    (acc, item, index) => {
      if (
        !item.public_label.trim() ||
        (!(item.payload as RubricItemPayloadQuestion).Question?.question.trim() &&
          !(item.payload as RubricItemPayloadAction).Action?.public_label.trim() &&
          !(item.payload as RubricItemPayloadInvestigation).Investigation?.public_label.trim())
      ) {
        acc.push({ index, error: 'Missing value' })
      }
      return acc
    },
    [] as { index: number; error: string }[]
  )
})

const cantSave = computed(() => {
  if (route.params.sectionTemplateId === 'new') {
    return
  }
  const { public_label, internal_label, items, minimum_score } = currentEditingSection
  const storeJSON = JSON.stringify(contentStore.editingSection)
  const currentJSON = JSON.stringify(currentEditingSection)

  return (
    !public_label?.trim() ||
    !internal_label?.trim() ||
    !items?.length ||
    !minimum_score ||
    minimum_score <= 0 ||
    minimum_score > items.length ||
    storeJSON === currentJSON ||
    !!itemsErrorArr.value.length
  )
})

onBeforeMount(() => {
  Object.assign(currentEditingSection, deepCopy(contentStore.editingSection))
})

watch(
  currentEditingSection,
  (newVal) => {
    if (route.params.sectionTemplateId === 'new') {
      contentStore.setEditingSection(newVal)
    }
  },
  { deep: true }
)

const saveSection = () => {
  saveLoading.value = true

  const updateSection = {
    ...currentEditingSection,
    allowlist: authStore.isAtLeastStaffUser
      ? currentEditingSection.allowlist?.map((o) => o.organization_id)
      : null
  }

  Api.Content.updateSectionTemplateEndpoint(updateSection as SectionTemplateUpdate)
    .then(() => {
      contentStore.setEditingSection(currentEditingSection)
      notificationStore.addNotification({
        subtitle: 'Section successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

// ==================================================
// Items
// ==================================================
const popoverOptions = ref<PopoverOption[]>([
  {
    name: 'Transcript item',
    action: () => addItem('Transcript')
  },
  {
    name: 'Action item',
    action: () => addItem('Action')
  },
  {
    name: 'Investigation item',
    action: () => addItem('Investigation')
  }
])

const addItem = (itemType: 'Transcript' | 'Action' | 'Investigation') => {
  if (!currentEditingSection?.items) {
    return
  }
  const itemToPush: Partial<ItemTemplate> = {
    public_label: '',
    mandatory: false
  }

  if (itemType === 'Transcript') {
    itemToPush.payload = {
      Question: { question: '' }
    }
  } else if (itemType === 'Action') {
    itemToPush.payload = {
      Action: { public_label: '' }
    }
  } else if (itemType === 'Investigation') {
    itemToPush.payload = {
      Investigation: { public_label: '' }
    }
  }

  currentEditingSection.items.push(itemToPush as ItemTemplate)

  const sectionItems = document.getElementById('section_items')

  if (sectionItems) {
    nextTick(() => sectionItems.scrollTo(0, 1000))
  }
}

const moveItem = (oldIndex: number, newIndex: number) => {
  const element = currentEditingSection.items?.[oldIndex]
  currentEditingSection.items?.splice(oldIndex, 1)
  currentEditingSection.items?.splice(newIndex, 0, element as ItemTemplate)
}

const deleteItem = (index: number) => {
  if (!currentEditingSection?.items) {
    return
  }
  currentEditingSection.items.splice(index, 1)
}
</script>

<template>
  <div :key="currentEditingSection.section_template_id" class="flex w-[620px] flex-col gap-y-5">
    <div class="flex flex-col">
      <h2 class="mb-5 text-lg">Details</h2>
      <div class="grid grid-cols-2 gap-x-5">
        <CustomInput
          v-model="currentEditingSection.public_label"
          class="my-0"
          label="Section Label"
          :read-only="isReadOnly"
        />
        <CustomInput
          v-model="currentEditingSection.internal_label"
          class="my-0"
          label="Internal Label"
          :read-only="isReadOnly"
        />
      </div>
      <div class="grid grid-cols-2 gap-x-5">
        <InputNumber
          v-model="currentEditingSection.minimum_score"
          label="Minimum Score"
          :read-only="isReadOnly"
          required
        />
        <CustomInput
          v-model="currentEditingSection.mandatory"
          label="Mandatory"
          :options="[
            { name: 'Yes', value: true },
            { name: 'No', value: false }
          ]"
          :read-only="isReadOnly"
          input-type="select"
          placeholder="Select an option"
        />
      </div>
    </div>
    <div class="flex flex-col">
      <h2 class="mb-5 text-lg">
        Items
        <span class="text-sc-grey-600">{{ `(${currentEditingSection?.items?.length || 0})` }}</span>
      </h2>

      <div
        v-if="!currentEditingSection?.items?.length"
        class="flex h-[163px] items-center justify-center"
      >
        <p class="text-sc-grey-600">You have no items added to this section</p>
      </div>
      <div
        v-else
        id="section_items"
        class="flex grow flex-col divide-y-2 divide-dashed divide-sc-grey-300 border-b-2 border-dashed border-sc-grey-300"
      >
        <div
          v-for="(item, itemIndex) in currentEditingSection.items"
          :key="`section_item_${itemIndex}`"
          class="hover group relative flex items-center gap-5 px-1"
        >
          <div
            class="absolute -left-1 -right-1 bottom-3 top-3 hidden rounded-mlg bg-sc-grey-100 group-hover:block"
          />
          <div class="z-10 flex grow flex-col">
            <p class="mt-3 text-xs text-sc-grey-600">
              {{
                `${
                  (item.payload as RubricItemPayloadQuestion)?.Question
                    ? 'TRANSCRIPT'
                    : (item.payload as RubricItemPayloadAction)?.Action
                      ? 'ACTION'
                      : 'INVESTIGATION'
                } ITEM`
              }}
            </p>
            <div :class="['mt-3 grid grid-cols-2 gap-x-5']">
              <CustomInput v-model="item.public_label" label="Item Label" :read-only="isReadOnly" />
              <CustomInput
                v-model="item.mandatory"
                label="Mandatory"
                :options="[
                  { name: 'Yes', value: true },
                  { name: 'No', value: false }
                ]"
                input-type="select"
                placeholder="Select an option"
                :read-only="isReadOnly"
              />
            </div>
            <CustomInput
              v-if="(item.payload as RubricItemPayloadQuestion)?.Question"
              v-model="(item.payload as RubricItemPayloadQuestion).Question.question"
              input-type="textarea"
              :text-area-grow="true"
              class="my-0"
              label="Marking Instruction"
              :read-only="isReadOnly"
            />
            <CustomInput
              v-else-if="(item.payload as RubricItemPayloadAction)?.Action"
              v-model="(item.payload as RubricItemPayloadAction).Action.public_label"
              input-type="textarea"
              :text-area-grow="true"
              class="my-0"
              label="Action Label"
              :read-only="isReadOnly"
            />
            <CustomInput
              v-else-if="(item.payload as RubricItemPayloadInvestigation)?.Investigation"
              v-model="(item.payload as RubricItemPayloadInvestigation).Investigation.public_label"
              input-type="textarea"
              :text-area-grow="true"
              class="my-0"
              label="Investigation Label"
              :read-only="isReadOnly"
            />
            <CustomInput
              v-model="item.failure_hint"
              input-type="textarea"
              :text-area-grow="true"
              :required="false"
              class="my-0"
              label="Failure Hint"
              :read-only="isReadOnly"
            />
          </div>
          <div class="flex flex-col justify-center gap-3">
            <div
              v-if="itemIndex !== 0 && !isReadOnly"
              class="z-10 flex h-6 w-6 basis-auto items-center justify-center rounded-full bg-sc-grey-100 text-sc-grey-600 hover:text-sc-grey-900 group-hover:bg-sc-grey-200"
              @click="() => moveItem(itemIndex, itemIndex - 1)"
            >
              <ArrowUpIcon class="w-3.5" />
            </div>
            <div
              v-if="!isReadOnly"
              class="z-10 flex h-6 w-6 basis-auto items-center justify-center rounded-full bg-sc-grey-100 text-sc-grey-600 hover:text-sc-grey-900 group-hover:bg-sc-grey-200"
              @click="deleteItem(itemIndex)"
            >
              <TrashIcon class="w-3.5" />
            </div>
            <div
              v-if="itemIndex !== currentEditingSection.items.length - 1 && !isReadOnly"
              class="z-10 flex h-6 w-6 basis-auto items-center justify-center rounded-full bg-sc-grey-100 text-sc-grey-600 hover:text-sc-grey-900 group-hover:bg-sc-grey-200"
              @click="() => moveItem(itemIndex, itemIndex + 1)"
            >
              <ArrowDownIcon class="w-3.5" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isReadOnly" class="z-50 bg-white pb-3">
      <div class="relative mt-5 flex basis-auto gap-3">
        <CustomPopover
          class="[&_.popover-position]:-top-[155px] [&_.popover-position]:mt-2"
          button-type="admin-secondary"
          :start-icon="PlusIcon"
          :popover-options="popoverOptions"
          :disabled="saveLoading"
        >
          Add Item
        </CustomPopover>

        <CustomButton
          v-if="route.params.sectionTemplateId !== 'new'"
          :disabled="cantSave"
          button-type="admin-primary"
          @click="saveSection"
        >
          Save Section
        </CustomButton>
      </div>
    </div>
  </div>
</template>
