// this is horrible, i cry
export const EMPTY_STRING_SEARCH_ALL = ''

export const LIMIT_SEARCH = 15

export const MAX_SPEAKING_TIME: number = 90 * 1_000
export const TRANSCRIPTION_AUDIO_BITRATE: number = 16_000
export const MINIMUM_RESPONSE_SIZE: number = 280

export const MAX_FILE_SIZE_IN_MB: number = 2
export const MAX_FILE_SIZE: number = MAX_FILE_SIZE_IN_MB * 1024 * 1024

// The amount of time, in milliseconds, to wait between subsequent calls to the
// `GetGradedRubric` endpoint.
export const GET_GRADED_RUBRIC_POLLING_PERIOD = 5_000
