import { ref, readonly } from 'vue'
import { defineStore } from 'pinia'

// TODO: Migrate this to breadcrumb instead of just string
// Last minute scope prune
export const usePageHeadingStore = defineStore('pageHeading', () => {
  const pageHeading = ref('')
  const setPageHeading = (newHeading: string) => (pageHeading.value = newHeading)
  return { pageHeading: readonly(pageHeading), setPageHeading }
})
