import { defineStore } from 'pinia'
import { ref } from 'vue'

type TokenGetterFunction = () => Promise<string | void>
// This store is designed to handle the background refreshing of auth0 & lti oauth tokens
export const useTokenStore = defineStore('token', () => {
  const refreshTimeout = ref<ReturnType<typeof setTimeout> | undefined>()
  const tokenGetterFunction = ref<TokenGetterFunction | undefined>()

  // This token refresh is not mandatory
  // Using it minimises chance of random ~1s delay on API calls
  const setRefreshTimeout = (newTokenGetterFunction: TokenGetterFunction, timeout: number) => {
    setTokenGetterFunction(newTokenGetterFunction)
    refreshTimeout.value && clearTimeout(refreshTimeout.value)
    refreshTimeout.value = setTimeout(() => {
      tokenGetterFunction.value!().then(() => {
        setRefreshTimeout(tokenGetterFunction.value!, timeout)
      })
    }, timeout)
  }

  const setTokenGetterFunction = (newTokenGetterFunction: TokenGetterFunction) =>
    (tokenGetterFunction.value = newTokenGetterFunction)

  return {
    refreshTimeout,
    tokenGetterFunction,
    setRefreshTimeout,
    setTokenGetterFunction
  }
})
