import type { AddStudentsToCohortInput } from '../models/AddStudentsToCohortInput';
import type { AssignmentSummary } from '../models/AssignmentSummary';
import type { Cohort } from '../models/Cohort';
import type { CohortStudentExportFilterApi } from '../models/CohortStudentExportFilterApi';
import type { CreateAssignmentInput } from '../models/CreateAssignmentInput';
import type { CreateAssignmentOutput } from '../models/CreateAssignmentOutput';
import type { CreateCohortInput } from '../models/CreateCohortInput';
import type { CreateCohortOutput } from '../models/CreateCohortOutput';
import type { CreateTaskInput } from '../models/CreateTaskInput';
import type { CreateTaskOutput } from '../models/CreateTaskOutput';
import type { DeleteAssignmentInput } from '../models/DeleteAssignmentInput';
import type { DeleteTaskInput } from '../models/DeleteTaskInput';
import type { DismissConversationTaskAttemptInput } from '../models/DismissConversationTaskAttemptInput';
import type { GetAssignmentOutput } from '../models/GetAssignmentOutput';
import type { GetCohortStudentsForCsvExportOutput } from '../models/GetCohortStudentsForCsvExportOutput';
import type { GetConversationTaskStatisticsOutput } from '../models/GetConversationTaskStatisticsOutput';
import type { ListCohortAssignmentsOutput } from '../models/ListCohortAssignmentsOutput';
import type { ListCohortStudentsOutput } from '../models/ListCohortStudentsOutput';
import type { ListStudentProgressOutput } from '../models/ListStudentProgressOutput';
import type { ListStudentsOutput } from '../models/ListStudentsOutput';
import type { RemoveStudentFromCohortInput } from '../models/RemoveStudentFromCohortInput';
import type { SearchCohortsOutput } from '../models/SearchCohortsOutput';
import type { UpdateAssignmentOutput } from '../models/UpdateAssignmentOutput';
import type { UpdateCohortOutput } from '../models/UpdateCohortOutput';
import type { UpdateTask } from '../models/UpdateTask';
import type { UpdateTaskOutput } from '../models/UpdateTaskOutput';
import type { UpdateTaskPositionsInput } from '../models/UpdateTaskPositionsInput';
import type { UpdateTaskPositionsOutput } from '../models/UpdateTaskPositionsOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';



export class AssignmentService {

	/**
	 * AddStudentsToCohort
	 * Add students to a cohort.
	 * @param requestBody 
	 * @returns any Students added successfully.
	 * @throws ApiError
	 */
	public static addStudentsToCohortEndpoint(
requestBody: AddStudentsToCohortInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment/AddStudentsToCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify cohorts.`,
			},
		});
	}

	/**
	 * CreateAssignment
	 * Creates a new assignment.
	 * @param requestBody 
	 * @returns CreateAssignmentOutput Assignment created successfully.
	 * @throws ApiError
	 */
	public static createAssignmentEndpoint(
requestBody: CreateAssignmentInput,
): CancelablePromise<CreateAssignmentOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment/CreateAssignment',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to create assignments.`,
			},
		});
	}

	/**
	 * CreateCohort
	 * Creates a new cohort.
	 * @param requestBody 
	 * @returns CreateCohortOutput Cohort created successfully.
	 * @throws ApiError
	 */
	public static createCohortEndpoint(
requestBody: CreateCohortInput,
): CancelablePromise<CreateCohortOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment/CreateCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to create cohorts.`,
			},
		});
	}

	/**
	 * CreateTask
	 * Creates a new task.
	 * @param requestBody 
	 * @returns CreateTaskOutput Task created successfully.
	 * @throws ApiError
	 */
	public static createTaskEndpoint(
requestBody: CreateTaskInput,
): CancelablePromise<CreateTaskOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/assignment/CreateTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to create tasks.`,
			},
		});
	}

	/**
	 * DeleteAssignment
	 * Deletes an assignment.
	 * @param requestBody 
	 * @returns void Assignment deleted successfully.
	 * @throws ApiError
	 */
	public static deleteAssignmentEndpoint(
requestBody: DeleteAssignmentInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v2/assignment/DeleteAssignment',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to delete this assignment.`,
			},
		});
	}

	/**
	 * DeleteTask
	 * Deletes a task.
	 * @param requestBody 
	 * @returns void Task deleted successfully.
	 * @throws ApiError
	 */
	public static deleteTaskEndpoint(
requestBody: DeleteTaskInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v2/assignment/DeleteTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to delete this task.`,
			},
		});
	}

	/**
	 * DismissConversationTaskAttempt
	 * Marks a conversation task attempt as having been dismissed.
	 * @param requestBody 
	 * @returns void Conversation task attempt dismissed.
	 * @throws ApiError
	 */
	public static dismissConversationTaskAttemptEndpoint(
requestBody: DismissConversationTaskAttemptInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v2/assignment/DismissConversationTaskAttempt',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to dismiss this attempt.`,
			},
		});
	}

	/**
	 * GetAssignment
	 * Retrieve a assignment by ID.
	 * @param assignmentId The ID of the assignment to retrieve.
	 * @returns GetAssignmentOutput Assignment retrieved successfully.
	 * @throws ApiError
	 */
	public static getAssignmentEndpoint(
assignmentId: string,
): CancelablePromise<GetAssignmentOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment/GetAssignment',
			query: {
				assignment_id: assignmentId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query assignments.`,
			},
		});
	}

	/**
	 * GetCohort
	 * Retrieve a cohort by ID.
	 * @param cohortId The ID of the cohort to retrieve.
	 * @returns Cohort Cohort retrieved successfully.
	 * @throws ApiError
	 */
	public static getCohortEndpoint(
cohortId: string,
): CancelablePromise<Cohort> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment/GetCohort',
			query: {
				cohort_id: cohortId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this cohort.`,
			},
		});
	}

	/**
	 * GetCohortStudentsForCsvExport
	 * Retrieve cohort's students for CSV export.
	 * @param cohortId The ID of the cohort.
	 * @param exportFilter The export filter.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @returns GetCohortStudentsForCsvExportOutput Cohort students retrieved successfully.
	 * @throws ApiError
	 */
	public static getCohortStudentsForCsvExportEndpoint(
cohortId: string,
exportFilter: CohortStudentExportFilterApi,
itemsPerPage: number,
page: number,
): CancelablePromise<GetCohortStudentsForCsvExportOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment/GetCohortStudentsForCsvExport',
			query: {
				cohort_id: cohortId, export_filter: exportFilter, items_per_page: itemsPerPage, page
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this cohort's students.`,
			},
		});
	}

	/**
	 * GetConversationTaskStatistics
	 * Retrieve statistics for the given conversation task id.
	 * @param conversationTaskId The ID of the conversation task.
	 * @returns GetConversationTaskStatisticsOutput Statistics retrieved successfully.
	 * @throws ApiError
	 */
	public static getConversationTaskStatisticsEndpoint(
conversationTaskId: string,
): CancelablePromise<GetConversationTaskStatisticsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment/GetConversationTaskStatistics',
			query: {
				conversation_task_id: conversationTaskId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query statistics.`,
			},
		});
	}

	/**
	 * ListCohortAssignments
	 * List the assignments within a cohort.
	 * @param cohortId The ID of the cohort.
	 * @param query The search query.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @returns ListCohortAssignmentsOutput Assignments retrieved successfully.
	 * @throws ApiError
	 */
	public static listCohortAssignmentsEndpoint(
cohortId: string,
query: string,
itemsPerPage: number,
page: number,
): CancelablePromise<ListCohortAssignmentsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment/ListCohortAssignments',
			query: {
				cohort_id: cohortId, query, items_per_page: itemsPerPage, page
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query assignments.`,
			},
		});
	}

	/**
	 * ListCohortStudents
	 * List cohort students.
	 * @param cohortId The ID of the cohort.
	 * @param query The search query.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @returns ListCohortStudentsOutput Cohort students retrieved successfully.
	 * @throws ApiError
	 */
	public static listCohortStudentsEndpoint(
cohortId: string,
query: string,
itemsPerPage: number,
page: number,
): CancelablePromise<ListCohortStudentsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment/ListCohortStudents',
			query: {
				cohort_id: cohortId, query, items_per_page: itemsPerPage, page
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this cohort's students.`,
			},
		});
	}

	/**
	 * ListStudentProgress
	 * Retrieve student progress for the given conversation task id.
	 * @param conversationTaskId The ID of the conversation task.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @param query The search query.
	 * @returns ListStudentProgressOutput Student progress retrieved successfully.
	 * @throws ApiError
	 */
	public static listStudentProgressEndpoint(
conversationTaskId: string,
itemsPerPage: number,
page: number,
query?: string | null,
): CancelablePromise<ListStudentProgressOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment/ListStudentProgress',
			query: {
				conversation_task_id: conversationTaskId, items_per_page: itemsPerPage, page, query
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query student progress.`,
			},
		});
	}

	/**
	 * ListStudents
	 * Retrieve all students who are part of our organization but have not yet been assigned to a given cohort.
	 * @param cohortId The ID of the cohort, for filtering out students that already assigned to this cohort.
	 * @param organizationId The ID of the organization.
	 * @param query The search query.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @returns ListStudentsOutput Students retrieved successfully.
	 * @throws ApiError
	 */
	public static listStudentsEndpoint(
cohortId: string,
organizationId: string,
query: string,
itemsPerPage: number,
page: number,
): CancelablePromise<ListStudentsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment/ListStudents',
			query: {
				cohort_id: cohortId, organization_id: organizationId, query, items_per_page: itemsPerPage, page
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query students.`,
			},
		});
	}

	/**
	 * RemoveStudentFromCohort
	 * Remove a student from a cohort.
	 * @param requestBody 
	 * @returns void Student removed from cohort successfully.
	 * @throws ApiError
	 */
	public static removeStudentFromCohortEndpoint(
requestBody: RemoveStudentFromCohortInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v2/assignment/RemoveStudentFromCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to remove this student.`,
			},
		});
	}

	/**
	 * SearchCohorts
	 * Search cohorts.
	 * @param organizationId The ID of the organization.
	 * @param query The search query.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @returns SearchCohortsOutput Cohorts retrieved successfully.
	 * @throws ApiError
	 */
	public static searchCohortsEndpoint(
organizationId: string,
query: string,
itemsPerPage: number,
page: number,
): CancelablePromise<SearchCohortsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/assignment/SearchCohorts',
			query: {
				organization_id: organizationId, query, items_per_page: itemsPerPage, page
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query cohorts.`,
			},
		});
	}

	/**
	 * UpdateAssignment
	 * Update an existing assignment.
	 * @param requestBody 
	 * @returns UpdateAssignmentOutput Assignment updated successfully.
	 * @throws ApiError
	 */
	public static updateAssignmentEndpoint(
requestBody: AssignmentSummary,
): CancelablePromise<UpdateAssignmentOutput> {
				return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v2/assignment/UpdateAssignment',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify cohort.`,
			},
		});
	}

	/**
	 * UpdateCohort
	 * Update the properties of a cohort.
	 * @param requestBody 
	 * @returns UpdateCohortOutput Cohort updated successfully.
	 * @throws ApiError
	 */
	public static updateCohortEndpoint(
requestBody: Cohort,
): CancelablePromise<UpdateCohortOutput> {
				return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v2/assignment/UpdateCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify cohort.`,
			},
		});
	}

	/**
	 * UpdateTask
	 * Update an existing task.
	 * @param requestBody 
	 * @returns UpdateTaskOutput Task updated successfully.
	 * @throws ApiError
	 */
	public static updateTaskEndpoint(
requestBody: UpdateTask,
): CancelablePromise<UpdateTaskOutput> {
				return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v2/assignment/UpdateTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify task.`,
			},
		});
	}

	/**
	 * UpdateTaskPositions
	 * Update existing task positions.
	 * @param requestBody 
	 * @returns UpdateTaskPositionsOutput Task positions updated successfully.
	 * @throws ApiError
	 */
	public static updateTaskPositionsEndpoint(
requestBody: UpdateTaskPositionsInput,
): CancelablePromise<UpdateTaskPositionsOutput> {
				return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v2/assignment/UpdateTaskPositions',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify task positions.`,
			},
		});
	}

}