<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { MultiTable } from '@/components/modern/ui/data-table'
import { useColumns, useGroups } from '@/components/modern/tables/cohort-student-activities'
// import { Button } from '@/components/modern/ui/button'
import type { StudentAssignmentInstanceProgress } from '@/open-api/generated'
import useGetStudentActivityProgress from '@/composables/api/queries/useGetStudentActivityProgress'
import useGetAssignmentInstanceStudentInsight from '@/composables/api/queries/useGetAssignmentInstanceStudentInsight'
import useDismissAllAttempts from '@/composables/api/mutations/useDismissAllAttempts'
import createIsoTimes from '@/utils/iso-times'

definePage({
  name: 'Modern Cohorts - Student Progress',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Learner Activities')

const route = useRoute('Modern Cohorts - Student Progress')
const cohortId = computed(() => route.params.cohortId)
const userId = computed(() => route.params.userId)

const notificationStore = useNotificationStore()

const { activities, isLoading, refetch } = useGetStudentActivityProgress({
  cohortId,
  userId,
  notificationStore
})

const assignmentInstanceId = ref<string | null>(null)
const { tasks, isLoading: taskAttemptsLoading } = useGetAssignmentInstanceStudentInsight({
  assignmentInstanceId,
  userId,
  notificationStore
})
const dismissAllAttempts = useDismissAllAttempts({
  tasks,
  userId,
  notificationStore
})
const requestDismissAllAttempts = (a: StudentAssignmentInstanceProgress) =>
  (assignmentInstanceId.value = a.assignment_instance_id)
const confirmDismissAllAttempts = () => {
  const { execute, reset } = dismissAllAttempts
  return execute().finally(reset).finally(refetch)
}

const isoTimes = createIsoTimes()

const columns = useColumns({
  cohortId,
  userId,
  taskAttemptsLoading,
  requestDismissAllAttempts,
  confirmDismissAllAttempts,
  isoTimes
})

const groups = useGroups()
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Learners List', params: { cohortId } }"
        name="cohort"
      />
    </template>
    <template v-if="false" #right>
      <!-- <Button variant="default" size="xs" @click="exportCSV">
        <DownloadIcon class="mr-2 size-4" />
        <span>Export</span>
      </Button> -->
    </template>
  </TopLine>
  <MultiTable :data="activities" :columns="columns" :groups="groups" :loading="isLoading" />
</template>
