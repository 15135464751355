<script setup lang="ts">
import { useAssignmentStore } from '@/stores/assignment'
import { computed, ref } from 'vue'
import { onBeforeMount } from 'vue'
import { type MyReflectionTaskResponse, type MyTask } from '@/open-api/generated'
import type { MyReflectionTaskMeta } from '@/types/api'
import { reactive, nextTick } from 'vue'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import AppBrainIcon from '@/components/AppBrainIcon.vue'
import Api from '@/open-api'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useAuthStore } from '@/stores/auth'
import { useRouter, useRoute } from 'vue-router'

definePage({
  name: 'MyAssignments Reflection Task',
  meta: {
    permissionLevel: 'Student'
  }
})

// ==================================================
// Init
// ==================================================
const router = useRouter()
const route = useRoute('MyAssignments Reflection Task')
const assignmentStore = useAssignmentStore()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()

const assignmentTaskLoading = ref(false)

const isPreview = computed(() => {
  return route.query.preview === 'preview' && authStore.isAtLeastEducatorUser
})

onBeforeMount(() => {
  if (!currentTask.value && !isPreview.value) {
    assignmentTaskLoading.value = true
    Api.MyAssignments.getMyAssignmentEndpoint(route.params.assignmentId as string)
      .then(async (res) => {
        assignmentStore.setCurrentAssignment(res.assignment)

        nextTick(() => {
          if (!currentTask.value) {
            router.push({ name: 'MyAssignments' })
            notificationStore.addNotification({
              subtitle: 'Task not found',
              status: NotificationStatus.DANGER
            })
          }
        })
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        assignmentTaskLoading.value = false
      })
  }
})

// ==================================================
// task logic
// ==================================================

const currentTask = computed((): MyTask | undefined => {
  if (isPreview.value) {
    const task = assignmentStore.previewTasks.find((task) => task.task_id === route.params.taskId)
    return {
      task_id: task?.task_id,
      task_type: task?.task_type,
      name: task?.name,
      mandatory: task?.mandatory,
      description: task?.description,
      meta: task?.meta
    } as MyTask
  }
  if (!assignmentStore?.currentAssignment?.tasks) {
    return undefined
  }
  return assignmentStore.currentAssignment?.tasks.find(
    (task) => task.task_id === route.params.taskId
  )
})

const currentResponses = reactive<MyReflectionTaskResponse[]>(
  (currentTask.value?.meta as MyReflectionTaskMeta).ReflectionTask.questions.map((question) => ({
    reflection_task_question_id: question.reflection_task_question_id,
    response: ''
  }))
)

// ==================================================
// Reflection task
// ==================================================
const reflectionTask = reactive({
  completeLoading: false,
  startLoading: false,
  attemptId: ''
})

const submitResponses = () => {
  if (isPreview.value && currentTask.value?.task_id) {
    router.push({
      name: 'Cohort Assignment Task Details',
      params: {
        cohortId: assignmentStore.editingCohort.cohort_id,
        assignmentId: route.params.assignmentId,
        taskId: currentTask.value?.task_id
      }
    })
  } else {
    reflectionTask.completeLoading = true

    Api.MyAssignments.submitMyReflectionTaskEndpoint({
      task_id: route.params.taskId as string,
      responses: currentResponses
    })
      .then(() => {
        router.push({
          name: 'MyAssignments Details',
          params: { assignmentId: route.params.assignmentId }
        })
        notificationStore.addNotification({
          subtitle: 'Task completed',
          status: NotificationStatus.SUCCESS
        })
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        reflectionTask.completeLoading = false
      })
  }
}
</script>

<template>
  <template v-if="!assignmentTaskLoading">
    <div class="sticky -top-3 z-50 mb-5 flex flex-col gap-5 bg-white pt-3">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <h1 class="text-2xl font-medium">{{ currentTask?.name }}</h1>
        </div>
        <div class="hidden md:flex">
          <CustomButton :loading="reflectionTask.completeLoading" @click="submitResponses">
            Submit responses
          </CustomButton>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-10">
      <div
        v-for="(questionData, questionIndex) in (currentTask?.meta as MyReflectionTaskMeta)
          .ReflectionTask.questions"
        :key="questionIndex"
        class="flex flex-col gap-10"
      >
        <div>
          <span class="text-gray-400">{{
            String(questionData.position + 1).padStart(2, '0')
          }}</span>
          <span class="ml-2 text-gray-800">{{ questionData.question }}</span>
        </div>

        <CustomInput
          v-model="currentResponses[questionIndex].response"
          input-type="textarea"
          label="Response"
        />

        <div
          v-if="
            questionIndex !==
            (currentTask?.meta as MyReflectionTaskMeta).ReflectionTask.questions.length - 1
          "
          class="relative h-px items-center justify-center bg-sc-grey-400"
        >
          <AppBrainIcon
            class="absolute left-1/2 top-1/2 h-[35px] -translate-x-1/2 -translate-y-1/2 bg-white px-2"
          />
        </div>
      </div>

      <div class="mb-5 flex md:hidden">
        <CustomButton
          class="w-full"
          :loading="reflectionTask.completeLoading"
          @click="submitResponses"
        >
          Submit responses
        </CustomButton>
      </div>
    </div>
  </template>
  <AppLoadingSpinner v-else center-screen loading />
</template>
