<script setup lang="ts">
import { ref, reactive } from 'vue'
import CustomList from '@/components/CustomList.vue'
import { VR_SCENES_MASTER } from '@/vr/vr-constants'
import { initVRConversation } from '@/vr/composables/useVrSimulation'
import { useRouter } from 'vue-router'

definePage({
  name: 'Web-mode VR',
  meta: {
    permissionLevel: 'SuperAdmin'
  }
})

const initLoading = ref(false)
const vrPagination = reactive({ items_per_page: 15, page: 1, total: 0 })
const vrList = reactive(VR_SCENES_MASTER)
const router = useRouter()

const onView = (data: { id: string; index: number }) => {
  const navigateToScene = () =>
    router.push({ name: 'Web-mode VR Scene', params: { sceneId: data.id } })
  initVRConversation(data.id, initLoading, navigateToScene)
}
</script>

<template>
  <CustomList
    title="Web-mode VR Simulations"
    :view-type="'Cell'"
    :list-data="vrList"
    :has-header="false"
    :cell-add="false"
    :has-list-options="false"
    :loading="initLoading"
    :has-img="true"
    :can-delete="false"
    :has-search="false"
    :pagination="vrPagination"
    :has-filter="false"
    @on-view="onView"
  />
</template>
