<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import type { Table } from '@tanstack/vue-table'
import { useAuthStore } from '@/stores/auth'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine } from '@/components/modern/page-navigation'
import type { OrganizationAssignment } from '@/open-api/generated'
import {
  DataTable,
  DataTableServerPagination,
  getFilterValue
} from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/organization-voices'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'
import useListVoices from '@/composables/api/queries/useListVoices'
import usePreviewVoice from '@/composables/api/usePreviewVoice'

definePage({
  name: 'Voices List Index',
  meta: {
    permissionLevel: 'SuperAdmin',
    isModern: true,
    requiresAuthoring: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('All Voices')

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const table = useTemplateRef<Table<OrganizationAssignment>>('table')
const query = computed(() => getFilterValue(table.value))
const { voices, isLoading, pagination, showServerPagination } = useListVoices({
  query,
  authStore,
  notificationStore
})

const { previewVoice } = usePreviewVoice()

const organizationVoices = useColumns({ previewVoice })
</script>

<template>
  <TopLine>
    <template #left>
      <span> All Voices </span>
    </template>
    <template #right>
      <Button v-if="authStore.isSuperAdminUser" variant="default" size="xs" as-child>
        <RouterLink :to="{ name: 'New Voice' }">
          <PlusIcon class="mr-2 size-4" />
          Add Voice
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <DataTable ref="table" :data="voices" :columns="organizationVoices" :loading="isLoading" />
  <DataTableServerPagination v-show="showServerPagination" v-bind="pagination" />
</template>
