import type { GetCharacterMessageOutput, GetConversationOutput } from '@/open-api/generated'
import type { VrConversationOutput } from '@/vr/types/vr'
import type { Option } from '@/types/utils'
import { defineStore } from 'pinia'

export const useSimulationStore = defineStore('simulation', {
  state: () => ({
    conversation: null as Option<GetConversationOutput>,
    vrConversation: null as Option<VrConversationOutput[]>,
    // Used on load to get the last message of the character
    initCharacterMessage: null as Option<GetCharacterMessageOutput>
  }),
  actions: {
    setConversation(character: Option<GetConversationOutput>) {
      this.conversation = character
    },
    setInitCharacterMessage(message: Option<GetCharacterMessageOutput>) {
      this.initCharacterMessage = message
    },
    setVrConversation(conversations: Option<VrConversationOutput[]>) {
      this.vrConversation = conversations
    },
    clearStore() {
      this.conversation = null
      this.vrConversation = null
      this.initCharacterMessage = null
    }
  }
})
