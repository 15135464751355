<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import type { GetCohortEducator } from '@/open-api/generated'
import useGetCohortEducators from '@/composables/api/queries/useGetCohortEducators'
import useRemoveEducatorFromCohort from '@/composables/api/mutations/useRemoveEducatorFromCohort'
import useSearchOrganizationUsers from '@/composables/api/queries/useSearchOrganizationUsers'
import useAddEducatorToCohort from '@/composables/api/mutations/useAddEducatorToCohort'
import { UserRoleApiEnum } from '@/open-api/generated'
import { DataTable } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/cohort-educators'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import CohortTabBar from '@/components/modern/CohortTabBar.vue'
import { Button } from '@/components/modern/ui/button'
import { Label } from '@/components/modern/ui/label'
import { SearchSelect } from '@/components/modern/ui/search-select'
import { PlusIcon } from '@radix-icons/vue'
import Separator from '@/components/modern/ui/separator/Separator.vue'

definePage({
  name: 'Modern Cohorts - Cohort Educators List',
  meta: {
    permissionLevel: 'InstitutionAdmin',
    isModern: true
  }
})

const route = useRoute('Modern Cohorts - Cohort Educators List')
const cohortId = computed(() => route.params.cohortId)

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

// Table setup
const removeEducatorId = ref<string | null>(null)
const { educators, isLoading, refetch } = useGetCohortEducators({ cohortId, notificationStore })
const { execute: executeRemove } = useRemoveEducatorFromCohort({
  userId: removeEducatorId,
  cohortId,
  notificationStore
})
const requestRemoveEducator = (educator: GetCohortEducator) =>
  (removeEducatorId.value = educator.id)
const confirmRemoveEducator = async () => executeRemove().finally(refetch)
const cohortEducators = useColumns({ requestRemoveEducator, confirmRemoveEducator })

// Add educators select-search setup
const query = ref<string>('')
const { isLoading: searchLoading, users } = useSearchOrganizationUsers({
  query,
  authStore,
  notificationStore
})
const userId = ref<string>()

const { execute: executeAdd } = useAddEducatorToCohort({ userId, cohortId, notificationStore })
const addEducator = async () => {
  if (userId.value) {
    await executeAdd()
    userId.value = undefined
    refetch()
  }
}
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        v-if="authStore.isAtLeastInstitutionAdminUser"
        :to="{ name: 'Modern Cohorts - Cohort Administration' }"
        name="cohort administration"
      />
      <BackButton v-else :to="{ name: 'Modern Cohorts - My Cohorts List' }" name="all cohorts" />
    </template>
    <template #center>
      <CohortTabBar :active="'educators'" :cohort-id="cohortId" />
    </template>
  </TopLine>
  <DataTable :data="educators" :columns="cohortEducators" :loading="isLoading" />
  <Separator v-if="educators.length" />
  <div class="mt-2 flex w-full flex-row justify-center">
    <div class="mx-4 mb-8 max-w-4xl flex-1 grow space-y-2 lg:mx-6">
      <Label for="search-educators">Search for an educator to add to this cohort</Label>
      <SearchSelect
        id="search-educators"
        v-model:query="query"
        v-model:selected-value="userId"
        :data="
          users.filter((u) =>
            [
              UserRoleApiEnum.EDUCATOR,
              UserRoleApiEnum.INSTITUTION_ADMIN,
              UserRoleApiEnum.STAFF,
              UserRoleApiEnum.SUPER_ADMIN
            ].includes(u.role)
          )
        "
        value-key="id"
        label-key="email"
        placeholder-label="user"
        placeholder-label-plural="users"
        :loading="searchLoading"
      />
      <Button variant="default" size="xs" class="!mt-4" @click="addEducator">
        <PlusIcon class="size-4" />
        <span>Add Educator</span>
      </Button>
    </div>
  </div>
</template>
