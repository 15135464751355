<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useAssignmentStore } from '@/stores/assignment'
import { useSimulationStore } from '@/stores/simulation'
import { ChevronLeftIcon } from '@heroicons/vue/24/solid'
import Api from '@/open-api'
import CustomTabs, { type TabOption } from '@/components/utils/CustomTabs.vue'
import { ref, onBeforeMount } from 'vue'
import { useNotificationStore } from '@/stores/notifications'

definePage({
  name: 'Cohort Assignment Task Attempt Wrapper',
  meta: {
    permissionLevel: 'Educator'
  }
})

const router = useRouter()
const route = useRoute<
  | 'Cohort Assignment Task Attempt Wrapper'
  | 'Cohort Assignment Task Attempt Transcript'
  | 'Cohort Assignment Task Attempt Rubric'
>()
const { cohortId, assignmentId, conversationId } = route.params

const assignmentStore = useAssignmentStore()
const taskId = assignmentStore.currentCohortTask.task_id
const studentEmail = assignmentStore.currentTaskAttempt.studentEmail
const attemptDate = assignmentStore.currentTaskAttempt.date

const simulationStore = useSimulationStore()
const notificationStore = useNotificationStore()

const conversationLoading = ref<boolean>(false)
const loadConversation = async () => {
  conversationLoading.value = true
  try {
    const response = await Api.Conversation.getConversationEndpoint(conversationId)
    simulationStore.setConversation(response)
  } catch (err: any) {
    notificationStore.addDANGER(err?.response?.data || err?.message)
  } finally {
    conversationLoading.value = false
  }
}
onBeforeMount(loadConversation)

const backToTaskInsights = () => {
  router.push({
    name: 'Cohort Assignment Task Insights',
    params: { cohortId, assignmentId, taskId }
  })
}

const tabOptions: TabOption[] = [
  {
    displayName: 'Transcript',
    routeName: 'Cohort Assignment Task Attempt Transcript'
  },
  {
    displayName: 'Rubric',
    routeName: 'Cohort Assignment Task Attempt Rubric'
  }
]
</script>

<template>
  <div class="flex flex-col">
    <div class="sticky -top-3 z-50 flex flex-col gap-5 bg-white pb-5 pt-3">
      <div
        class="flex cursor-pointer items-center gap-2 px-5 text-sc-grey-700"
        @click="backToTaskInsights"
      >
        <ChevronLeftIcon class="h-4 w-4" />
        <h4 class="font-normal text-sc-grey-700">Back to Task Insights</h4>
      </div>
      <div class="flex justify-between px-5">
        <div class="flex flex-row items-center gap-1">
          <h1 class="mr-5 whitespace-nowrap text-xl font-medium">
            {{ studentEmail }}
            <span class="text-sc-grey-600"> - </span>
            {{ attemptDate }}
          </h1>
        </div>
      </div>

      <CustomTabs :tab-options="tabOptions" />
    </div>
    <AppLoadingSpinner v-if="conversationLoading" :loading="conversationLoading" />
    <router-view v-else />
  </div>
</template>
