<script setup lang="ts">
import { useUtilsStore } from '@/stores/utils'
import { useAuthStore } from '@/stores/auth'
import { onMounted } from 'vue'
import UAParser from 'ua-parser-js'
import { useRoute, useRouter } from 'vue-router'
import DesktopLayout from '@/layouts/DesktopLayout.vue'
import MobileLayout from '@/layouts/MobileLayout.vue'
import UnauthenticatedLayout from '@/layouts/UnauthenticatedLayout.vue'
import AuthLoadingLayout from '@/layouts/AuthLoadingLayout.vue'
import { onStartup } from '@/startup'

const utilsStore = useUtilsStore()
const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()

const supportedBrowser = () => {
  const supportedBrowsers = [
    'Chrome',
    'Mobile Safari',
    'Safari',
    'Edge',
    'Firefox',
    'Chromium',
    'Mozilla',
    'Android Browser',
    'Oculus Browser'
  ]
  const currentBrowserName = new UAParser(navigator.userAgent).getBrowser().name

  return supportedBrowsers.includes(currentBrowserName || '')
}

onMounted(() => {
  onStartup()
  if (!supportedBrowser() && route.name !== 'Unsupported Browser') {
    router.push({ name: 'Unsupported Browser' })
  }
})
</script>

<template>
  <div
    class="flex h-dvh w-screen flex-col overflow-hidden md:flex-row print:h-auto print:w-[210mm] print:max-w-[210mm] print:overflow-visible"
    :style="`--org-color: ${authStore.orgColor}`"
  >
    <AuthLoadingLayout v-if="authStore.loading" />
    <UnauthenticatedLayout v-else-if="!authStore.isAuthenticated" />
    <DesktopLayout v-else-if="!utilsStore.isMobile && authStore.isAtLeastEducatorUser" />
    <MobileLayout v-else />
  </div>
</template>
