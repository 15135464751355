import type { CreateAudioMessageInput } from '../models/CreateAudioMessageInput';
import type { EndConversationInput } from '../models/EndConversationInput';
import type { EndConversationOutput } from '../models/EndConversationOutput';
import type { GetCharacterMessageOutput } from '../models/GetCharacterMessageOutput';
import type { GetConversationOutput } from '../models/GetConversationOutput';
import type { GetGradedRubricOutput } from '../models/GetGradedRubricOutput';
import type { GetStudentMessageOutput } from '../models/GetStudentMessageOutput';
import type { GetTranscriptOutput } from '../models/GetTranscriptOutput';
import type { ListConversationsOutput } from '../models/ListConversationsOutput';
import type { ListOrganizationConversationsOutput } from '../models/ListOrganizationConversationsOutput';
import type { PerformActionInput } from '../models/PerformActionInput';
import type { PerformActionOutput } from '../models/PerformActionOutput';
import type { PerformInvestigationInput } from '../models/PerformInvestigationInput';
import type { PerformInvestigationOutput } from '../models/PerformInvestigationOutput';
import type { SendAudioMessageInput } from '../models/SendAudioMessageInput';
import type { SendAudioMessageOutput } from '../models/SendAudioMessageOutput';
import type { StartConversationInput } from '../models/StartConversationInput';
import type { StartConversationOutput } from '../models/StartConversationOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';



export class ConversationService {

	/**
	 * CreateAudioMessage
	 * Create an audio message in a conversation.
	 * @param requestBody 
	 * @returns binary Message created successfully.
	 * @throws ApiError
	 */
	public static createAudioMessageEndpoint(
requestBody: CreateAudioMessageInput,
): CancelablePromise<Blob> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/conversation/CreateAudioMessage',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to use conversations.`,
			},
		});
	}

	/**
	 * EndConversation
	 * End a conversation.
	 * @param requestBody 
	 * @returns EndConversationOutput Conversation ended successfully.
	 * @throws ApiError
	 */
	public static endConversationEndpoint(
requestBody: EndConversationInput,
): CancelablePromise<EndConversationOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/conversation/EndConversation',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to use conversations.`,
			},
		});
	}

	/**
	 * GetCharacterMessage
	 * Retrieve the first unseen character message.
	 * @param conversationId The ID of the conversation.
	 * @param transcriptEntryId The ID of the last seen character answer message. If this is not
 * provided, returns the first character answer message.
	 * @returns GetCharacterMessageOutput Message retrieved successfully.
	 * @throws ApiError
	 */
	public static getCharacterMessageEndpoint(
conversationId: string,
transcriptEntryId?: string | null,
): CancelablePromise<GetCharacterMessageOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/conversation/GetCharacterMessage',
			query: {
				conversation_id: conversationId, transcript_entry_id: transcriptEntryId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this conversation.`,
			},
		});
	}

	/**
	 * GetConversation
	 * Retrieve a conversation by ID.
	 * @param conversationId The ID of the conversation.
	 * @returns GetConversationOutput Conversation retrieved successfully.
	 * @throws ApiError
	 */
	public static getConversationEndpoint(
conversationId: string,
): CancelablePromise<GetConversationOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/conversation/GetConversation',
			query: {
				conversation_id: conversationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this conversation.`,
			},
		});
	}

	/**
	 * GetGradedRubric
	 * Retrieve a conversation's graded rubric, if available.
	 * @param conversationId The ID of the conversation.
	 * @returns GetGradedRubricOutput Graded rubric retrieved successfully.
	 * @throws ApiError
	 */
	public static getGradedRubricEndpoint(
conversationId: string,
): CancelablePromise<GetGradedRubricOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/conversation/GetGradedRubric',
			query: {
				conversation_id: conversationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this conversation.`,
			},
		});
	}

	/**
	 * GetStudentMessage
	 * Retrieve the first unseen student message.
	 * @param conversationId The ID of the conversation.
	 * @param transcriptEntryId The ID of the last character message.
	 * @returns GetStudentMessageOutput Message retrieved successfully.
	 * @throws ApiError
	 */
	public static getStudentMessageEndpoint(
conversationId: string,
transcriptEntryId?: string | null,
): CancelablePromise<GetStudentMessageOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/conversation/GetStudentMessage',
			query: {
				conversation_id: conversationId, transcript_entry_id: transcriptEntryId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this conversation.`,
			},
		});
	}

	/**
	 * GetTranscript
	 * Retrieve a character by ID.
	 * @param conversationId The ID of the conversation to get the transcript from.
	 * @returns GetTranscriptOutput Transcript retrieved successfully.
	 * @throws ApiError
	 */
	public static getTranscriptEndpoint(
conversationId: string,
): CancelablePromise<GetTranscriptOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/conversation/GetTranscript',
			query: {
				conversation_id: conversationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this conversation.`,
			},
		});
	}

	/**
	 * ListConversations
	 * Retrieve the list of conversations for a user.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @param userId The ID of the user. If this is not provided, we use the ID of the logged
 * in user.
	 * @param characterNameFilter Filter by the full name of the character (if provided).
	 * @returns ListConversationsOutput Conversations retrieved successfully.
	 * @throws ApiError
	 */
	public static listConversationsEndpoint(
itemsPerPage: number,
page: number,
userId?: string | null,
characterNameFilter?: string | null,
): CancelablePromise<ListConversationsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/conversation/ListConversations',
			query: {
				user_id: userId, character_name_filter: characterNameFilter, items_per_page: itemsPerPage, page
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this user's conversations.`,
			},
		});
	}

	/**
	 * ListOrganizationConversations
	 * Retrieve the list of conversations for all users in an organization.
	 * @param organizationId The ID of the organization.
	 * @param query The search query, can be any of the following:
 * - The email of the user.
 * - The full name of the character.
 * - The name of the cohort.
 * - The name of the assignment.
 * - The name of the task.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @param userIdFilter Filter by the id of the user (if provided).
	 * @returns ListOrganizationConversationsOutput Conversations retrieved successfully.
	 * @throws ApiError
	 */
	public static listOrganizationConversationsEndpoint(
organizationId: string,
query: string,
itemsPerPage: number,
page: number,
userIdFilter?: string | null,
): CancelablePromise<ListOrganizationConversationsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/conversation/ListOrganizationConversations',
			query: {
				organization_id: organizationId, query, user_id_filter: userIdFilter, items_per_page: itemsPerPage, page
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this organization's conversations.`,
			},
		});
	}

	/**
	 * PerformAction
	 * Perform an action in a conversation.
	 * @param requestBody 
	 * @returns PerformActionOutput Action performed successfully.
	 * @throws ApiError
	 */
	public static performActionEndpoint(
requestBody: PerformActionInput,
): CancelablePromise<PerformActionOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/conversation/PerformAction',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to use conversations.`,
			},
		});
	}

	/**
	 * PerformInvestigation
	 * Perform an investigation in a conversation.
	 * @param requestBody 
	 * @returns PerformInvestigationOutput Investigation performed successfully.
	 * @throws ApiError
	 */
	public static performInvestigationEndpoint(
requestBody: PerformInvestigationInput,
): CancelablePromise<PerformInvestigationOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/conversation/PerformInvestigation',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to use conversations.`,
			},
		});
	}

	/**
	 * SendAudioMessage
	 * Send an audio message to a conversation.
	 * @param requestBody 
	 * @returns SendAudioMessageOutput Message sent successfully.
	 * @throws ApiError
	 */
	public static sendAudioMessageEndpoint(
requestBody: SendAudioMessageInput,
): CancelablePromise<SendAudioMessageOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/conversation/SendAudioMessage',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to use conversations.`,
			},
		});
	}

	/**
	 * StartConversation
	 * Start a new conversation.
	 * @param requestBody 
	 * @returns StartConversationOutput Conversation started successfully.
	 * @throws ApiError
	 */
	public static startConversationEndpoint(
requestBody: StartConversationInput,
): CancelablePromise<StartConversationOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/conversation/StartConversation',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to use conversations.`,
			},
		});
	}

}