<script setup lang="ts">
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/modern/ui/dropdown-menu'
import { ExitIcon, ExternalLinkIcon } from '@radix-icons/vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { useAuthStore } from '@/stores/auth'
import { watch, ref, computed } from 'vue'
import RoleSelect from '@/components/modern/app-navigation-sidebar/RoleSelect.vue'
import OrganizationSelect from '@/components/modern/app-navigation-sidebar/OrganizationSelect.vue'
import Button from '@/components/modern/ui/button/Button.vue'

const { logout } = useAuth0()
const authStore = useAuthStore()

const allowEmailNotifications = ref<'true' | 'false'>(
  authStore.notificationIncompleteAssignments ? 'true' : 'false'
)

watch(allowEmailNotifications, (newSetting) => {
  authStore.setNotificationIncompleteAssignments(newSetting === 'true')
})

const openInNewTab = (url: string) => {
  window.open(url, '_blank')
}

const privacyPolicyLink = 'https://www.simconverse.com/documents/privacy'

// TODO: Enable this to be set for BMJ
const documentationLink = computed<string>(() =>
  authStore.isAtLeastEducatorUser
    ? 'https://simconverse.notion.site/Customer-Documentation-687574ba22704cd38b0744132634450f'
    : 'https://simconverse.notion.site/Customer-Documentation-331de8a87bb8441e85a9094b399ed622'
)

const changelogLink = 'https://simconverse.notion.site/Changelog-6f77b987e3e048a5a56074d5563c06fd'

// TODO: Enable this to be set for BMJ
// TODO: Add instructions / a template into mailto
// const supportMailTo = computed<string>(()=>authStore.isAtLeastEducatorUser
// ? 'mailto:support@simconverse.com'
// : 'mailto:student-support@simconverse.com'
// )
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger class="flex items-center">
      <Button
        variant="outline"
        size="icon"
        class="flex items-center justify-center rounded-full text-center text-base"
      >
        <span>?</span>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="start" side="top">
      <DropdownMenuItem class="hover:cursor-pointer" @select="openInNewTab(documentationLink)">
        <ExternalLinkIcon class="mr-2 size-4" />
        Support
      </DropdownMenuItem>
      <DropdownMenuItem class="hover:cursor-pointer" @select="openInNewTab(privacyPolicyLink)">
        <ExternalLinkIcon class="mr-2 size-4" />
        Privacy Policy
      </DropdownMenuItem>
      <DropdownMenuItem class="hover:cursor-pointer" @select="openInNewTab(changelogLink)">
        <ExternalLinkIcon class="mr-2 size-4" />
        Changelog
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuLabel>Email Notifications</DropdownMenuLabel>
      <DropdownMenuRadioGroup v-model="allowEmailNotifications">
        <DropdownMenuRadioItem value="true">Allow</DropdownMenuRadioItem>
        <DropdownMenuRadioItem value="false">Deny</DropdownMenuRadioItem>
      </DropdownMenuRadioGroup>
      <template v-if="authStore.isTrueSuperAdmin">
        <DropdownMenuSeparator />
        <RoleSelect class="m-1" />
        <OrganizationSelect class="m-1" />
      </template>
      <DropdownMenuSeparator />
      <DropdownMenuItem class="font-medium hover:cursor-pointer" @click="logout">
        <ExitIcon class="mr-2 size-4" />
        Log out
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
