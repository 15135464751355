<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue'
import { useAssignmentStore, type AssignmentData } from '@/stores/assignment'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import Api from '@/open-api'
import CustomList, { type ListData } from '@/components/CustomList.vue'
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
import CustomButton from '@/components/utils/CustomButton.vue'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants'

definePage({
  name: 'Cohort Assignments',
  meta: {
    permissionLevel: 'Educator'
  }
})

// ==================================================
// Init
// ==================================================

const assignmentStore = useAssignmentStore()
const notificationStore = useNotificationStore()
const route = useRoute('Cohort Assignments')
const router = useRouter()

// ==================================================
// Assignments
// ==================================================

const assignments = reactive({
  loading: false,
  pagination: { items_per_page: 15, page: 1, total: 0 },
  search: EMPTY_STRING_SEARCH_ALL,
  tableHeaders: [
    {
      name: 'Assignment Title',
      value: 'name'
    },
    {
      name: 'Visibility',
      value: 'custom-visibility'
    },
    {
      name: 'Due Date',
      value: 'custom-due-date'
    },
    {
      name: 'Actions',
      value: 'action-view'
    }
  ]
})

// Load cohort assignments
const setAssignmentsList = async (search: string) => {
  assignments.loading = true
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }
  assignments.search = search
  Api.Assignment.listCohortAssignmentsEndpoint(
    route.params.cohortId as string,
    search,
    assignments.pagination.items_per_page,
    assignments.pagination.page
  )
    .then((res) => {
      router.push({
        query: {
          ...route.query,
          search: assignments.search
        }
      })

      assignments.pagination.total = res.pagination.total_pages || 1

      assignmentStore.updateEditingCohort({
        assignments: res.assignments.map<AssignmentData>((assignment) => {
          return {
            ...assignment,
            tasks: []
          }
        })
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      assignments.loading = false
    })
}

onBeforeMount(() => {
  let querySearch = EMPTY_STRING_SEARCH_ALL

  if (route.query.search) {
    assignments.search = route.query.search as string
    querySearch = route.query.search as string
  }

  setAssignmentsList(querySearch)
})

const viewRouteDetails = (assignment_id: string) => {
  return {
    name: 'Cohort Assignment Details',
    params: { assignmentId: assignment_id, cohortId: route.params.cohortId as string }
  }
}

const formatDate = (val: string) => {
  return dayjs(val).format('D MMM YYYY, hh:mma')
}

const changePage = (page: number) => {
  assignments.pagination.page = page
  setAssignmentsList(assignments.search)
}
</script>

<template>
  <div class="flex flex-col overflow-hidden px-5">
    <CustomList
      title="assignments"
      class="[&_.list-title]:hidden"
      create-button="Create Assignment"
      :pagination="assignments.pagination"
      :has-list-options="false"
      :loading="assignments.loading"
      :list-headers="assignments.tableHeaders"
      :list-data="assignmentStore.editingCohort.assignments || []"
      :has-header="true"
      :search="assignments.search"
      :generate-view-link="(data: ListData) => viewRouteDetails(data.assignment_id as string)"
      @on-create="
        () =>
          router.push({
            name: 'Cohort Assignment Details',
            params: { assignmentId: 'new', cohortId: route.params.cohortId as string }
          })
      "
      @on-change-page="(page: number) => changePage(page)"
      @on-search="(search: string) => setAssignmentsList(search)"
    >
      <template #custom-visibility="{ data }: { data: any }">
        <CustomButton
          :custom-style="`border ${
            data.visible
              ? 'bg-sc-orange-100 border-sc-orange-500 text-sc-grey-700'
              : 'bg-sc-grey-50 border border-sc-grey-300 text-sc-grey-700'
          }`"
          button-size="sm"
          :icon-size="`h-5 w-5 ${data.visible ? 'text-sc-orange-500' : 'text-sc-grey-600'}`"
          :start-icon="data.visible ? EyeIcon : EyeSlashIcon"
          :can-select="false"
        >
          {{ data.visible ? 'Visible to student' : 'Hidden from students' }}
        </CustomButton>
      </template>
      <template #custom-due-date="{ data }: { data: any }">
        <small class="font-medium text-sc-grey-700"
          >Due: <span class="text-sc-grey-900">{{ formatDate(data.due_date) }}</span></small
        >
      </template>
    </CustomList>
  </div>
</template>
