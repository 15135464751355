window.addEventListener(
  'error',
  function (e) {
    if (e.target && e.target.tagName === 'SCRIPT' && e.target.src.includes('index-')) {
      const errorMessage = document.getElementById('error-message')
      if (errorMessage) {
        errorMessage.style.display = 'flex'
      }
    }
  },
  true
)
