<script setup lang="ts">
import { watch, reactive, onBeforeMount, ref, computed } from 'vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import InputFile from '@/components/input/InputFile.vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import { useContentStore } from '@/stores/content'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { UserIcon, PlayIcon, StopIcon } from '@heroicons/vue/24/outline'
import { useAuthStore } from '@/stores/auth'
import useUploadImageEndpoint from '@/composables/api/useUploadImageEndpoint'
import type { CharacterOutput, UpdateCharacterInput, VoiceSummaryApi } from '@/open-api/generated'
import Api from '@/open-api'
import { nextTick } from 'vue'
import { deepCopy } from '@/lib/utils'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import { SUPPORTED_IMAGE_MIMETYPES } from '@/constants'
import useListVoices from '@/composables/api/queries/useListVoices'
import { AudioPlayer, State } from '@/utils/audio'

definePage({
  name: 'Character Config',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const contentStore = useContentStore()
const route = useRoute('Character Config')
const authStore = useAuthStore()
const isReadOnly = computed(() => {
  return (
    route.params.characterId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingCharacter as CharacterOutput)?.owning_organization_id
    )
  )
})

// ==================================================
// Image Modal
// ==================================================
const {
  src: modalImage,
  mime: modalMime,
  loading: modalFileLoading,
  uploadFile,
  deleteFile
} = useUploadImageEndpoint()

const imgModalIsOpen = ref(false)

watch(imgModalIsOpen, (newVal) => {
  if (newVal) {
    modalImage.value = currentEditingCharacter.avatar_url || ''
  }
})
onBeforeRouteLeave((to, from, next) => {
  if (route.params.characterId !== 'new' && !canSave.value) {
    const answer = window.confirm(
      'Warning: You have unsaved changes. \nNavigating away from this page will delete any unsaved changes.'
    )
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

const editAvatar = () => {
  if (!isReadOnly.value) {
    imgModalIsOpen.value = true
  }
}

const setImage = () => {
  currentEditingCharacter.avatar_url = modalImage.value
  imgModalIsOpen.value = false
}

// ==================================================
// Character form
// ==================================================
const saveLoading = ref(false)
const currentEditingCharacter = reactive<Partial<UpdateCharacterInput & { voice_name: string }>>({})

onBeforeMount(() => {
  nextTick(() => {
    Object.assign(currentEditingCharacter, contentStore.editingCharacter)
  })
})

watch(
  currentEditingCharacter,
  (newVal) => {
    if (route.params.characterId === 'new') {
      contentStore.setEditingCharacter(newVal)
    }
  },
  { deep: true }
)

const saveCharacterConfig = () => {
  const { editingCharacter } = contentStore

  if (!editingCharacter) {
    return
  }

  saveLoading.value = true

  const updateCharacter = deepCopy(currentEditingCharacter) as
    | UpdateCharacterInput
    | CharacterOutput

  const update = { ...updateCharacter } as UpdateCharacterInput

  update.allowlist = authStore.isAtLeastStaffUser
    ? updateCharacter.allowlist?.map((listItem) => {
        if (typeof listItem === 'string') {
          return listItem
        }
        return listItem?.organization_id
      })
    : null

  Api.Content.updateCharacterEndpoint(update as UpdateCharacterInput)
    .then((res) => {
      saveLoading.value = false
      Object.assign(currentEditingCharacter, res)
      contentStore.setEditingCharacter(res)
      notificationStore.addNotification({
        subtitle: 'Character Config successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      saveLoading.value = false

      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
}

const canSave = computed(() => {
  const {
    given_name,
    family_name,
    avatar_url,
    clinician_role,
    public_description,
    internal_label,
    voice_id
  } = currentEditingCharacter

  const storeEditingJSON = JSON.stringify(contentStore.editingCharacter)
  const componentEditingJSON = JSON.stringify(currentEditingCharacter)

  return (
    !given_name?.trim() ||
    !clinician_role ||
    !avatar_url ||
    !family_name?.trim() ||
    !internal_label?.trim() ||
    !voice_id ||
    !public_description?.trim() ||
    storeEditingJSON === componentEditingJSON
  )
})

const voiceSearch = ref(currentEditingCharacter.voice_name || '')

watch(
  currentEditingCharacter,
  (character) => {
    if (character.voice_name) {
      voiceSearch.value = character.voice_name
    }
  },
  { once: true }
)

const { voices } = useListVoices({ query: voiceSearch, authStore, notificationStore })

const voiceOptions = computed(() =>
  voices.value.map((voice: VoiceSummaryApi) => ({ name: voice.name, value: voice.voice_id }))
)

const audioPlayer = new AudioPlayer()
watch(
  () => currentEditingCharacter.voice_id,
  () => audioPlayer.reset()
)
</script>

<template>
  <div class="flex flex-col gap-y-5">
    <div class="flex w-[620px] flex-col">
      <div
        :class="[
          'relative mb-5 h-[120px] w-[120px] overflow-hidden rounded-full',
          { 'cursor-pointer': !isReadOnly }
        ]"
        @click="editAvatar"
      >
        <div
          v-if="!isReadOnly"
          class="absolute bottom-0 flex h-[24px] w-full items-center justify-center bg-sc-grey-800 opacity-60"
        />
        <p
          v-if="!isReadOnly"
          class="absolute bottom-0 flex w-full items-center justify-center py-1 text-xs text-white"
        >
          Edit
        </p>

        <img
          v-if="currentEditingCharacter.avatar_url"
          class="h-full w-full object-cover"
          :src="currentEditingCharacter.avatar_url"
          :alt="`${currentEditingCharacter.given_name} ${currentEditingCharacter.family_name}`"
        />
        <div
          v-else
          class="flex h-full w-full items-center justify-center bg-sc-grey-300 text-white"
        >
          <UserIcon class="h-14 w-14" />
        </div>

        <CustomModal :model-value="imgModalIsOpen">
          <div class="flex flex-col gap-5">
            <h3 class="text-xl">Character Avatar</h3>

            <InputFile
              label="Upload Image"
              required
              :upload-file="uploadFile"
              :accepts-formats="SUPPORTED_IMAGE_MIMETYPES"
              :src="modalImage"
              :mime="modalMime"
              :loading="modalFileLoading"
              :delete-file="deleteFile"
            />
            <div class="flex gap-3 self-end">
              <CustomButton button-type="admin-secondary" @click="imgModalIsOpen = false">
                Cancel
              </CustomButton>
              <CustomButton :disabled="!modalImage" @click="setImage"> Save </CustomButton>
            </div>
          </div>
        </CustomModal>
      </div>
      <div class="flex gap-3">
        <CustomInput
          v-model="currentEditingCharacter.given_name"
          field="given_name"
          label="First Name"
          :read-only="isReadOnly"
        />
        <CustomInput
          v-model="currentEditingCharacter.family_name"
          :read-only="isReadOnly"
          label="Last Name"
        />
      </div>
      <div class="flex gap-3">
        <CustomInput
          v-model="currentEditingCharacter.internal_label"
          :read-only="isReadOnly"
          label="Internal Label"
        />
        <CustomInput
          v-model="currentEditingCharacter.clinician_role"
          :read-only="isReadOnly"
          label="Clinician Role"
        />
      </div>
      <CustomInput
        v-model="currentEditingCharacter.public_description"
        input-type="textarea"
        label="Description"
        :read-only="isReadOnly"
      />
      <div class="flex items-start gap-3">
        <div class="w-1/2">
          <CustomInput
            v-model="currentEditingCharacter.voice_id"
            :options="voiceOptions"
            placeholder="Select one..."
            input-type="select-search"
            label="Voice"
            :read-only="isReadOnly"
            :clear-on-select="false"
            @search="(search) => (voiceSearch = search)"
          />
        </div>
        <CustomButton
          v-if="currentEditingCharacter.voice_id && audioPlayer.state.value === State.Streaming"
          button-type="admin-secondary"
          :start-icon="StopIcon"
          class="mt-1"
          @click="audioPlayer.reset()"
        />
        <CustomButton
          v-else-if="currentEditingCharacter.voice_id"
          button-type="admin-secondary"
          :start-icon="PlayIcon"
          class="mt-1"
          @click="
            audioPlayer.play({
              request: Api.Content.playVoiceEndpoint(currentEditingCharacter.voice_id)
            })
          "
        />
      </div>
      <CustomButton
        v-if="route.params.characterId !== 'new' && !isReadOnly"
        button-type="admin-primary"
        :loading="saveLoading"
        :disabled="canSave"
        class="mb-3 mt-5"
        @click="saveCharacterConfig"
      >
        Save Config
      </CustomButton>
    </div>
  </div>
</template>
